import React, { useEffect, useState, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";
import { TAMRE1RE2RE3Context } from "../../Context/TAMRE1RE2RE3Context";
import moment from "moment";

const TrackRequisitions = () => {
    const { isAccess } = useContext(RefreshTokenContext);
    const [searchRequest, setSearchRequest] = useState("");
    console.log(searchRequest);
    const privileges = secureLocalStorage.getItem("privileges");
    const { requisitionID } = useContext(TypemasterDropdownContext);

    const location = useLocation();
    const navigate = useNavigate();

    const {
        rawTamData,
        getTamData,
        tamPageNo,
        setTamPageNo,
        tamEntries,
        tamPageCount,
        setTamEntries,
        tamCount,
        handleTamClick,
        isTamData,
        isTamLoading,
    } = useContext(TAMRE1RE2RE3Context);

    const handleEntries = (event) => {
        setTamEntries(event.target.value);
    };

    const onChangeHandler = (event) => {
        setSearchRequest(event.target.value);
    };
    const onSearchHandler = (method, event) => {
        event.preventDefault();
        if (method === "search") {
            // getTamData(tamPageNo, searchRequest);
            setTamPageNo(1);
            getTamData(1, searchRequest, 'closed');
        } else {
            // getTamData(tamPageNo);
            // setSearchRequest("");
            setTamPageNo(1);
            getTamData(1, "", 'closed');
            setSearchRequest("");
        }
    };
    useEffect(() => {
        if (isAccess) {
            let access = secureLocalStorage.getItem("access");
            getTamData(tamPageNo, searchRequest, 'closed', access);
        } else {
            let access = secureLocalStorage.getItem("access");
            getTamData(tamPageNo, searchRequest, 'closed', access);
        }
    }, [tamEntries, isAccess, tamPageNo]);

    return (
        <>
            <WelcomeMessage />
            <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                    <div className="nk-block-head-content d-flex">
                        <h3 className="nk-block-title page-title">Track Requisition</h3>
                        {isTamLoading ? (
                            <div class="spinner-border text-primary ms-2" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    {location.state?.showBackButton && (
                        <div class="nk-block-head-content">
                            <a
                                id="back-button"
                                className="btn btn-light d-none d-sm-inline-flex"
                                onClick={() => navigate(-1)}
                            >
                                <em class="icon ni ni-arrow-left"></em>
                                <span>Back</span>
                            </a>
                            <a
                                id="back-button"
                                className="btn btn-icon btn-light d-inline-flex d-sm-none"
                                onClick={() => navigate(-1)}
                            >
                                <em class="icon ni ni-arrow-left"></em>
                            </a>
                        </div>
                    )}
                </div>
            </div>
            <div className="nk-block">
                <div className="card">
                    <div className="card-inner-group">
                        <div className="card-inner border-bottom-0">
                            <div className="form-control-wrap">
                                <form>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            value={searchRequest}
                                            className="form-control"
                                            placeholder="Search using Requisition ID (e.g., 126), job title, department, priority."
                                            onChange={onChangeHandler}
                                            id="text-form-input-search"
                                        />
                                        <div className="input-group-append">
                                            <button
                                                type="submit"
                                                className="btn btn-outline-primary btn-dim"
                                                id="button-button-search"
                                                onClick={(e) => onSearchHandler("search", e)}
                                            >
                                                Search
                                            </button>
                                            <button
                                                className="btn btn-outline-success btn-dim"
                                                id="button-button-view"
                                                onClick={(e) => onSearchHandler("view", e)}
                                            >
                                                Clear Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card-inner p-0">
                            <div className="table-responsive">
                                <table className="table">
                                    <div className="nk-tb-list">
                                        <div className="nk-tb-item nk-tb-head">
                                            <div className="nk-tb-col">
                                                <span>Approved on</span>
                                            </div>
                                            {/* <div className="nk-tb-col">
                                                <span>Pending Since (Days)</span>
                                                <br />
                                                <span>for shortlisting</span>
                                            </div> */}
                                            <div className="nk-tb-col">
                                                <span>Requisition ID</span>
                                            </div>
                                            <div className="nk-tb-col">
                                                <span>Job Title</span>
                                            </div>
                                            <div className="nk-tb-col">
                                                <span>Department</span>
                                            </div>
                                            
                                            <div className="nk-tb-col">
                                                <span>Priority</span>
                                                <br />
                                                <span>(Set by TAM)</span>
                                            </div>

                                            <div className="nk-tb-col">
                                                <span>Approved by</span>
                                            </div>

                                        </div>

                                        {rawTamData.length !== 0 ? (
                                            rawTamData.map((post, index) => (
                                                <div className="nk-tb-item" key={index}>
                                                    <div className="nk-tb-col">
                                                        <span className="tb-lead">
                                                            {post?.approval_on
                                                                ? moment(post?.approval_on).format(
                                                                    "MMM DD, YYYY"
                                                                )
                                                                : "-"}
                                                        </span>
                                                    </div>
                                                    {/* <div className="nk-tb-col">
                                                        <span className="tb-sub">
                                                            {post?.tam_assign_pending_since}
                                                        </span>
                                                    </div> */}
                                                    <div className="nk-tb-col">
                                                        <span className="tb-lead">
                                                            <span className="title">
                                                                {privileges.includes(
                                                                    "shortlisted_candidate_details"
                                                                ) ? (
                                                                    <NavLink
                                                                        to="/schedulecandidateview"
                                                                        id="button-button-schedulecandidateview"
                                                                        state={{
                                                                            editpost: post,
                                                                            status: "track",
                                                                            from: 'sme_approved'
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            {(requisitionID &&
                                                                                requisitionID[0]?.name +
                                                                                post.id) ||
                                                                                "-"}
                                                                        </span>
                                                                    </NavLink>
                                                                ) : (
                                                                    <>
                                                                        {" "}
                                                                        {(requisitionID &&
                                                                            requisitionID[0]?.name +
                                                                            post.id) ||
                                                                            "-"}
                                                                    </>
                                                                )}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="nk-tb-col">
                                                        <span className="tb-product">
                                                            <span className="title">
                                                                {privileges.includes("job_opening_retrieve") ? (
                                                                    <NavLink
                                                                        to={"/jobopeningdetails"}
                                                                        state={{ editpost: post }}
                                                                    >
                                                                        {post?.job_opening?.job_title || "-"}
                                                                    </NavLink>
                                                                ) : (
                                                                    <> {post?.job_opening?.job_title || "-"}</>
                                                                )}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="nk-tb-col">
                                                        <span className="tb-sub">
                                                            <NavLink
                                                                to={"/departmentrequisitions"}
                                                                state={{ editpost: post?.job_opening?.department?.id }}
                                                            >
                                                                {post?.job_opening?.department
                                                                    ?.department_display_name || "-"}
                                                            </NavLink>
                                                        </span>
                                                    </div>
                                                    
                                                    <div className="nk-tb-col">
                                                        <span className="tb-sub">
                                                            {post?.priority || "-"}
                                                        </span>
                                                    </div>
                                                    
                                                    <div className="nk-tb-col">
                                                        <span className="tb-sub">
                                                        {post?.approval_by?.first_name && post?.approval_by?.last_name
                                                            ? post.approval_by.first_name[0].toUpperCase() +
                                                            post.approval_by.first_name.slice(1) +
                                                            " " +
                                                            post.approval_by.last_name[0].toUpperCase() +
                                                            post.approval_by.last_name.slice(1)
                                                            : "-"}
                                                        </span>
                                                    </div>

                                                </div>
                                            ))
                                        ) : isTamData ? (
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="tb-product">
                                                        <span className="title">Good job! You are all caught up.</span>
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="tb-product">
                                                        <span className="title">Loading...</span>
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </table>
                            </div>
                        </div>
                        <div className="card-inner">
                            <div className="nk-block-between-md g-3">
                                <div className="g">
                                    <ReactPaginate
                                        key={tamPageCount}
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        pageCount={tamPageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={(data) =>
                                            handleTamClick(data, searchRequest, 'closed')
                                        }
                                        containerClassName={
                                            "pagination justify-content-center justify-content-md-start"
                                        }
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        forcePage={tamPageNo - 1}
                                    />
                                </div>
                                <div className="g">
                                    <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                                        <div>
                                            Total entries:
                                            <span className="fw-bold">
                                                {tamCount === null ? 0 : tamCount}
                                            </span>
                                        </div>
                                        <div>No of entries per page:</div>
                                        <div>
                                            <select
                                                className="form-select js-select2"
                                                data-search="on"
                                                data-dropdown="xs center"
                                                value={tamEntries}
                                                onChange={handleEntries}
                                                id="select-form-enteries"
                                            >
                                                <option value="5">5</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TrackRequisitions;
