import React, { useContext, useEffect, useState } from "react";
import { ScheduleInterviewContext } from "../../Context/ScheduleInterviewContext";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import moment from "moment";

const ScheduleInterviewDetails = () => {
  const location = useLocation();
  console.log(location);
  const navigate = useNavigate();
  const [searchDetails, setSearchDetails] = useState("");
  console.log(searchDetails);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    interviewDetails,
    getInterviewDetails,
    interviewEntries,
    interviewPageCount,
    setInterviewEntries,
    interviewCount,
    handleInterviewClick,
    isInterviewData,
    isLoading,
  } = useContext(ScheduleInterviewContext);

  const handleEntries = (event) => {
    setInterviewEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchDetails(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getInterviewDetails(1, location.state?.CandidateData?.id, searchDetails, location.state?.requisition_id);
    } else {
      getInterviewDetails(1, location.state?.CandidateData?.id, "", location.state?.requisition_id);
      setSearchDetails("");
    }
  };

  useEffect(() => {
    // if(isAccess){
    //   let access = secureLocalStorage.getItem("access")
    //   console.log(access)
    getInterviewDetails(
      1,
      location.state?.CandidateData?.id,
      searchDetails,
      location.state?.requisition_id
    );
    // } else {
    // let access = secureLocalStorage.getItem("access")
    // console.log(access)
    // getInterviewDetails(1, location.state?.CandidateData?.id, searchDetails);
    // }
  }, [interviewEntries, location]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title">Scheduled Interview Details</h3>
            {isLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="nk-block-head-content">
            <a
              id="back-button"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="back-button"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>

      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchDetails}
                      className="form-control"
                      placeholder="Search by Interview Type, Interview mode"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Interview Mode</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Interview Type</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Interviewer Name</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Interview Date-Time</span>
                      </div>

                      {privileges.includes("schedule_interview_update") ? (
                        <div className="nk-tb-col">
                          <span>Action</span>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    {interviewDetails.length !== 0 ? (
                      interviewDetails.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {post?.interview_mode || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {post?.interview_type || "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.interviewer_name.map((api) => (
                                <div className="profile-ud-value">
                                  {api?.first_name} {" "}
                                  {api?.last_name}
                                </div>
                              )) || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post?.interview_datetime
                                ? moment(post?.interview_datetime).format(
                                  "MMM DD, YYYY h:mm a"
                                )
                                : "-"}
                            </span>
                          </div>

                          {privileges.includes("schedule_interview_update") ? (
                            <div className="nk-tb-col">
                              <NavLink
                                to={"/scheduleinterviewedit"}
                                state={{ editpost: post }}
                                className="btn btn-sm btn-primary"
                                id="button-button-editrequisition"
                              >
                                <em className="icon ni ni-edit"></em>
                              </NavLink>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isInterviewData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={interviewPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={interviewPageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) =>
                      handleInterviewClick(
                        data,
                        location.state?.CandidateData?.id,
                        searchDetails,
                        location.state?.requisition_id
                      )
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {interviewCount === null ? 0 : interviewCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={interviewEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleInterviewDetails;
