import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { TAMRE1RE2RE3Context } from "../../Context/TAMRE1RE2RE3Context";
import ReactPaginate from "react-paginate";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

const RejectedCandidate = () => {
    const { isAccess } = useContext(RefreshTokenContext);
    const [searchRequest, setSearchRequest] = useState("");
    console.log(searchRequest);
    const userID = secureLocalStorage.getItem("userID");
    const privileges = secureLocalStorage.getItem("privileges");
    const {
        rawTamData,
        getTamData,
        setRawTamData,
        tamEntries,
        tamPageCount,
        tamPageNo,
        setTamPageNo,
        setTamEntries,
        tamCount,
        handleTamClick,
        isTamData,
        isTamLoading,
        setIsTamLoading
    } = useContext(TAMRE1RE2RE3Context);
    const [submitMessage, setSubmitMessage] = useState(false);


    const { requisitionID } = useContext(TypemasterDropdownContext);
    console.log(requisitionID);

    const handleEntries = (event) => {
        setTamEntries(event.target.value);
    };

    const onChangeHandler = (event) => {
        setSearchRequest(event.target.value);
    };

    const onSearchHandler = (method, event) => {
        event.preventDefault();
        if (method === "search") {
            // getTamData(tamPageNo, searchRequest);
            setTamPageNo(1);
            getTamData(1, searchRequest, 'closed');
        } else {
            // getTamData(tamPageNo);
            // setSearchRequest("");
            setTamPageNo(1);
            getTamData(1, '', 'closed');
            setSearchRequest("");
        }
    };

    useEffect(() => {
        if (isAccess) {
            let access = secureLocalStorage.getItem("access");
            getTamData(tamPageNo, searchRequest, 'closed', access);
        } else {
            let access = secureLocalStorage.getItem("access");
            getTamData(tamPageNo, searchRequest, 'closed', access);
        }
    }, [tamEntries, isAccess, tamPageNo]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSubmitMessage(null);
        }, 5000);
        return () => clearTimeout(timeoutId);
    }, [submitMessage]);

    return (
        <>
            <WelcomeMessage />
            <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                    <div className="nk-block-head-content d-flex">
                        <h3 className="nk-block-title page-title">Rejected Candidates</h3>
                        {isTamLoading ? (
                            <div class="spinner-border text-primary ms-2" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
            <div className="nk-block">
                <div className="card">
                    <div className="card-inner-group">
                        <div className="card-inner border-bottom-0">
                            <div className="form-control-wrap">
                                <form>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            value={searchRequest}
                                            className="form-control"
                                            placeholder="Search using Requisition ID (e.g., 126), job title, department, vacancy, status"
                                            onChange={onChangeHandler}
                                            id="text-form-input-search"
                                        />
                                        <div className="input-group-append">
                                            <button
                                                type="submit"
                                                className="btn btn-outline-primary btn-dim"
                                                id="button-button-search"
                                                onClick={(e) => onSearchHandler("search", e)}
                                            >
                                                Search
                                            </button>
                                            <button
                                                className="btn btn-outline-success btn-dim"
                                                id="button-button-view"
                                                onClick={(e) => onSearchHandler("view", e)}
                                            >
                                                Clear Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card-inner p-0">
                            <div className="table-responsive">
                                <table className="table">
                                    <div className="nk-tb-list">
                                        <div className="nk-tb-item nk-tb-head">
                                            <div className="nk-tb-col">
                                                <span>Approved on</span>
                                            </div>
                                            <div className="nk-tb-col">
                                                <span>Requisition ID</span>
                                            </div>
                                            <div className="nk-tb-col">
                                                <span>Job Title</span>
                                            </div>
                                            <div className="nk-tb-col">
                                                <span>Department</span>
                                            </div>

                                            <div className="nk-tb-col">
                                                <span>Vacancy</span>
                                            </div>
                                            <div className="nk-tb-col">
                                                <span>Status</span>
                                            </div>
                                        </div>

                                        {rawTamData.length !== 0 ? (
                                            rawTamData.map((post, index) => (
                                                <div className="nk-tb-item" key={index}>
                                                    <div className="nk-tb-col">
                                                        <span className="tb-lead">
                                                        {post?.approval_on
                                                            ? moment(post?.approval_on).format(
                                                            "MMM DD, YYYY"
                                                            )
                                                            : "-"}
                                                        </span>
                                                    </div>
                                                    <div className="nk-tb-col">
                                                        <span className="tb-lead">
                                                            <span className="title">
                                                                {privileges.includes("job_opening_retrieve") ? (
                                                                    <NavLink
                                                                        to={"/viewrejectedcandidate"}
                                                                        state={{ editpost: post }}
                                                                    >
                                                                        {" "}
                                                                        {requisitionID &&
                                                                            requisitionID[0]?.name + post.id || "-"}
                                                                    </NavLink>
                                                                ) : (
                                                                    <> {requisitionID &&
                                                                        requisitionID[0]?.name + post.id || "-"}</>
                                                                )}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="nk-tb-col">
                                                        <span className="tb-product">
                                                            <span className="title">
                                                                {privileges.includes("job_opening_retrieve") ? (
                                                                    <NavLink
                                                                        to={"/jobopeningdetails"}
                                                                        state={{ editpost: post }}
                                                                    >
                                                                        {" "}
                                                                        {post?.job_opening?.job_title || "-"}
                                                                    </NavLink>
                                                                ) : (
                                                                    <> {post?.job_opening?.job_title || "-"}</>
                                                                )}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="nk-tb-col">
                                                        <span className="tb-sub">
                                                            <NavLink
                                                                to={"/departmentrequisitions"}
                                                                state={{ editpost: post?.job_opening?.department?.id }}
                                                            >
                                                                {post?.job_opening?.department
                                                                    ?.department_display_name || "-"}
                                                            </NavLink>
                                                        </span>
                                                    </div>
                                                   
                                                    <div className="nk-tb-col">
                                                        <span className="tb-sub">
                                                        {post?.no_of_vacancy || "-"}
                                                        </span>
                                                    </div>

                                                    <div className="nk-tb-col">
                                                        <span className="tb-sub">
                                                        {post?.requisition_acceptance !== null ? (
                                                            post?.requisition_acceptance === 0 ? (
                                                            <span className="badge bg-warning">
                                                                Pending
                                                            </span>
                                                            ) : post?.requisition_acceptance === 1 ? (
                                                            <span className="badge bg-success">
                                                                Accepted
                                                            </span>
                                                            ) : post?.requisition_acceptance === 2 ? (
                                                            <span className="badge bg-danger">
                                                                Rejected
                                                            </span>
                                                            ) : post?.requisition_acceptance === 3 ? (
                                                            <span className="badge bg-secondary">
                                                                Closed
                                                            </span>
                                                            ) : (
                                                            "N/A"
                                                            )
                                                        ) : (
                                                            "N/A"
                                                        )}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))
                                        ) : isTamData ? (
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="tb-product">
                                                        <span className="title">Good job! You are all caught up.</span>
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="nk-tb-item">
                                                <div className="nk-tb-col">
                                                    <span className="tb-product">
                                                        <span className="title">Loading...</span>
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </table>
                            </div>
                        </div>
                        <div className="card-inner">
                            <div className="nk-block-between-md g-3">
                                <div className="g">
                                    <ReactPaginate
                                        // key={tamPageCount}
                                        previousLabel={"previous"}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        pageCount={tamPageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={(data) => handleTamClick(data, searchRequest, 'closed')}
                                        containerClassName={
                                            "pagination justify-content-center justify-content-md-start"
                                        }
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        forcePage={tamPageNo - 1}
                                    />
                                </div>
                                <div className="g">
                                    <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                                        <div>
                                            Total entries:
                                            <span className="fw-bold">
                                                {tamCount === null ? 0 : tamCount}
                                            </span>
                                        </div>
                                        <div>No of entries per page:</div>
                                        <div>
                                            <select
                                                className="form-select js-select2"
                                                data-search="on"
                                                data-dropdown="xs center"
                                                value={tamEntries}
                                                onChange={handleEntries}
                                                id="select-form-enteries"
                                            >
                                                <option value="5">5</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {submitMessage && (
                    <div className="example-alerts position-fixed bottom-0 end-0 p-3">
                        <div className="gy-4">
                            <div className="example-alert">
                                <div
                                    className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                                >
                                    <em
                                        className={`icon ni ni-${submitMessage.icon}-circle`}
                                    ></em>
                                    <strong>{submitMessage.message}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default RejectedCandidate;
