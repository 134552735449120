import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RERequisitionDetails = () => {
  const navigate = useNavigate();
  let location = useLocation();
  console.log(location.state.editpost);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between justify-content-end">
          <div class="nk-block-head-content">
            <a
              id="button-backbutton"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-backbutton"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-aside-wrap">
              <div className="card-content">
                {/* <!-- .nav-tabs --> */}

                <div className="card-inner">
                  <div className="nk-block">
                    <div className="nk-block-head">
                      <h5 className="title">
                        {location.state?.editpost?.job_opening?.job_title ||
                          "-"}
                      </h5>
                    </div>
                    {/* <!-- .nk-block-head --> */}
                    <div className="profile-ud-list">
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Job Type</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.requisition?.job_type ||
                              "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Department</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.department
                              ?.department_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Team</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.team
                              ?.team_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Position</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.position
                              ?.position_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Seniority</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.seniority
                              ?.seniority_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Job Location</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.requisition
                              ?.job_location || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Priority</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.requisition?.priority ||
                              "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            No of Vacancy
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.requisition
                              ?.no_of_vacancy && location.state?.editpost?.requisition
                                ?.no_of_vacancy}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Required Experience
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.requisition
                              ?.required_experience && location.state?.editpost?.requisition
                                ?.required_experience + " years"}
                          </span>
                        </div>
                      </div>

                      {/* <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Minimum Salary
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.requisition
                              ?.min_salary && location.state?.editpost?.requisition
                                ?.min_salary}
                          </span>
                        </div>
                      </div> */}
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Maximum Salary
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.requisition?.max_salary ? location.state?.editpost?.requisition?.max_salary + " LPA" : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Deadline</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.requisition?.deadline ? location.state?.editpost?.requisition?.deadline + " days" : '-'}
                          </span>
                        </div>
                      </div>
                      {/* <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Depute Status
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.requisition
                              ?.depute_status || "-"}
                          </span>
                        </div>
                        </div> */}
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Workflow</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.workflow
                              ?.workflow_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Reason to raise requisition
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.requisition
                              ?.reason_to_raise_requisition || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Status</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.requisition
                              ?.requisition_acceptance !== null ? (
                              location.state.editpost.requisition
                                ?.requisition_acceptance === 0 ? (
                                <span className="badge bg-warning">
                                  Pending
                                </span>
                              ) : location.state.editpost.requisition
                                ?.requisition_acceptance === 1 ? (
                                <span className="badge bg-success">
                                  Accepted
                                </span>
                              ) : location.state.editpost.requisition
                                ?.requisition_acceptance === 2 ? (
                                <span className="badge bg-danger">
                                  Rejected
                                </span>
                              ) : location.state.editpost.requisition
                                ?.requisition_acceptance === 3 ? (
                                <span className="badge bg-secondary">
                                  Closed
                                </span>
                              ) : (
                                "N/A"
                              )
                            ) : (
                              "N/A"
                            )}
                          </span>
                        </div>
                      </div>
                      {location.state?.editpost?.requisition?.requisition_acceptance !== null && location.state?.editpost?.requisition?.requisition_acceptance !== 0 &&
                        (
                          <>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  {location.state?.editpost?.requisition?.requisition_acceptance === 2 ? "Rejected by" : "Approved by"}
                                </span>
                                <span className="profile-ud-value">
                                  {location.state?.editpost?.approval_by?.first_name[0].toUpperCase() +
                                    location.state?.editpost?.approval_by?.first_name.slice(1) +
                                    " " +
                                    location.state?.editpost?.approval_by?.last_name[0].toUpperCase() +
                                    location.state?.editpost?.approval_by?.last_name.slice(1)}
                                </span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  {location.state?.editpost?.requisition?.requisition_acceptance === 2 ? "Rejected on" : "Approved on"}
                                </span>
                                <span className="profile-ud-value">
                                  {location.state?.editpost?.approval_on
                                    ? moment(location.state?.editpost?.approval_on).format(
                                      "MMM DD, YYYY h:mm a"
                                    )
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </>
                        )
                      }
                     
                    </div>
                    {/* <!-- .profile-ud-list --> */}
                  </div>

                  {/* <!-- .nk-block --> */}
                  <div className="nk-divider divider md"></div>
                  <div className="nk-block">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                      <h5 className="title">Job Description</h5>
                    </div>
                    <div className="bq-note">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                location.state?.editpost?.job_opening
                                  ?.job_description || "-",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                      <h5 className="title">Job Requirement</h5>
                    </div>
                    {/* <!-- .nk-block-head --> */}
                    <div className="bq-note">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                location.state?.editpost?.job_opening
                                  ?.job_requirement || "-",
                            }}
                          />
                        </div>
                      </div>
                      {/* <!-- .bq-note-item --> */}
                    </div>
                    {/* <!-- .bq-note --> */}
                  </div>
                  <div className="nk-block">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                      <h5 className="title">Comments</h5>
                    </div>
                    {/* <!-- .nk-block-head --> */}
                    <div className="bq-note">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p>
                            {location.state?.editpost?.requisition?.comments ||
                              "-"}{" "}
                          </p>
                        </div>
                      </div>
                      {/* <!-- .bq-note-item --> */}
                    </div>
                    {/* <!-- .bq-note --> */}
                  </div>
                </div>
                {/* <!-- .card-inner --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RERequisitionDetails;
