import React, { useEffect, useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";
import ReactPaginate from "react-paginate";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import moment from "moment";

const RequestAcceptance = () => {
  const { isAccess, RefreshToken } = useContext(RefreshTokenContext);
  const [searchRequest, setSearchRequest] = useState("");
  console.log(searchRequest);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");
  const navigate = useNavigate();

  const {
    rawRequisitionData,
    getRequestedRequisitionData,
    requisitionEntries,
    requisitionPageCount,
    setRequisitionEntries,
    requisitionCount,
    handleRequisitionPageClick,
    isRequisitionData,
    isRequisitionLoading,
  } = useContext(RequestedJobOpeningContext);

  const { requisitionID } = useContext(TypemasterDropdownContext);

  const handleEntries = (event) => {
    setRequisitionEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchRequest(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getRequestedRequisitionData(1, searchRequest);
    } else {
      getRequestedRequisitionData(1);
      setSearchRequest("");
    }
  };

  const AcceptRejectAlert = (is_accepted, post) => {
    Swal.fire({
      text: `Are you sure you want to ${is_accepted === 1 ? "accept" : "reject"
        } this requisition?`,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        handleAcceptReject(is_accepted, post);
      }
    });
  };

  const handleAcceptReject = (is_accepted, post) => {
    const access = secureLocalStorage.getItem("access");
    let date = new Date();
    console.log(date);
    console.log(post);
    var user = JSON.stringify({
      requisition_acceptance: is_accepted,
      requisition_acceptance_by_id: userID,
      requisition_acceptance_on: date,
      updated_by_id: userID,
    });

    var config = {
      method: "patch",
      url:
        VARIABLES.url +
        `/api/requestrequisition/${post.id}/?type=requisition_approval`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };

    console.log(user);

    setSubmitLoading(true)
    axios(config)
      .then((response) => {
        console.log(response)
        console.log(JSON.stringify(response.data));
        getRequestedRequisitionData(1, "", access);
        setSubmitLoading(false)
        Swal.fire({
          title: `Requisition ${is_accepted === 1 ? "accepted" : "rejected"}`,
          text: `${response.data?.message}`,
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        console.log(error);
        setSubmitLoading(false)
        if (error?.response?.status === 403) {
          RefreshToken();
        } else {
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: `Failed to ${is_accepted === 1 ? "accept" : "reject"
              } the requisition`,
          });
        }
      });
  };

  const onTATSubmit = (data) => {
    console.log(data)
    var user = JSON.stringify({
      requisition_id: data?.id,
      stage: "Requisition Approval",
      created_by_id: userID
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/requisition-activity-log/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          RefreshToken();
        }
      });
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getRequestedRequisitionData(1, searchRequest, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getRequestedRequisitionData(1, searchRequest, access);
    }
  }, [requisitionEntries, isAccess]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Requisition Approval</h3>
            {isRequisitionLoading || submitLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("all_requisition_retrieve") ? (
            <div className="nk-block-head-content">
              <NavLink
                to={"/allrequisition"}
                state={{ showBackButton: true }}
                className="toggle btn btn-icon btn-primary d-md-none"
                id="button-button-viewallrequisition"
              >
                <em className="icon ni ni-plus"></em>
              </NavLink>

              <NavLink
                to={"/allrequisition"}
                state={{ showBackButton: true }}
                className="toggle btn btn-primary d-none d-md-inline-flex"
                id="button-button-viewallrequisition"
              >
                <em className="icon ni ni-eye"></em>
                <span>View all requisition</span>
              </NavLink>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchRequest}
                      className="form-control"
                      placeholder="Search using Requisition ID (e.g., 126), raised on (e.g., Jan 01, 2024), job title, department, and reason"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Raised on</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Requisition ID</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Department</span>
                      </div>
                      {/* <div className="nk-tb-col">
                        <span>Job Type</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Vacancy</span>
                      </div> */}
                      <div className="nk-tb-col">
                        <span>Reason to raise requisition</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Pending since (days)</span>
                      </div>
                      {/* <div className="nk-tb-col">
                        <span>Pending Since (Days)</span>
                        <br />
                        <span>for assigning to RE</span>
                      </div> */}
                      <div className="nk-tb-col">
                        <span>Accept/Reject</span>
                      </div>
                    </div>

                    {rawRequisitionData.length !== 0 ? (
                      rawRequisitionData.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post?.created_at
                                ? moment(post?.created_at).format(
                                  "MMM DD, YYYY"
                                )
                                : "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {(requisitionID &&
                                  requisitionID[0]?.name + post?.id) ||
                                  "-"}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {privileges.includes("job_opening_retrieve") ? (
                                  <NavLink
                                    to={"/jobopeningdetails"}
                                    state={{
                                      editpost: post,
                                      status: "acceptance"
                                    }}
                                    onClick={() => onTATSubmit(post)}
                                  >
                                    {post?.job_opening?.job_title || "-"}
                                  </NavLink>
                                ) : (
                                  <> {post?.job_opening?.job_title || "-"}</>
                                )}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {/* {privileges.includes("job_opening_retrieve") ? ( */}
                              <NavLink
                                to={"/departmentrequisitions"}
                                state={{ editpost: post?.job_opening?.department?.id }}
                              >
                                {post?.job_opening?.department
                                  ?.department_display_name || "-"}
                              </NavLink>
                              {/* ) : (
                                <>
                                  {post?.job_opening?.department
                                    ?.department_display_name || "-"}
                                </>
                              )} */}
                            </span>
                          </div>
                          {/* <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post?.job_type || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.no_of_vacancy || "-"}
                            </span>
                          </div> */}
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.reason_to_raise_requisition || '-'}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post?.approval_pending_since}
                            </span>
                          </div>
                          {/* <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.pending_since}
                            </span>
                          </div> */}
                          {privileges.includes(
                            "requisition_approval_update"
                          ) ? (
                            <div className="nk-tb-col">
                              <div className="d-flex">
                                <button
                                  onClick={(e) => {
                                    AcceptRejectAlert(1, post);
                                    onTATSubmit(post);
                                  }}
                                  className="btn btn-white btn-dim btn-outline-success"
                                  id="button-button-acceptresume"
                                >
                                  <em className="icon ni ni-check"></em>
                                </button>

                                <button
                                  onClick={(e) => {
                                    AcceptRejectAlert(2, post);
                                    onTATSubmit(post);
                                  }}
                                  className="btn btn-white btn-dim btn-outline-danger"
                                  id="button-button-rejectresume"
                                >
                                  <em className="icon ni ni-cross"></em>
                                </button>
                              </div>
                            </div>
                          ) : (
                            " "
                          )}
                        </div>
                      ))
                    ) : isRequisitionData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={requisitionPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={requisitionPageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) =>
                      handleRequisitionPageClick(data, searchRequest)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {requisitionCount === null ? 0 : requisitionCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={requisitionEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RequestAcceptance;
