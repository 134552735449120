import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../Constants";
import axios from "axios";
import { SnackbarContext } from "./SnackbarContext";
import { RefreshTokenContext } from "./RefreshTokenContext";

// const VARIABLES = {
//   url: "https://rms-api-test.techwalnut.co.in",
// };

export const NotificationsContext = createContext(null);

function NotificationsContextProvider({ children }) {
  const userID = secureLocalStorage.getItem("userID");

  const { RefreshToken } = useContext(RefreshTokenContext);

  const [notifications, setNotifications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [unReadNotifications, setUnReadNotifications] = useState([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [retryCount, setRetryCount] = useState(0);
  const eventSourceRef = useRef(null);
  const [noPages, setNoPages] = useState(1);
  const [entries, setEntries] = useState(5);
  const [totalEntries, setTotalEntries] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [areNotificationsLoading, setAreNotificationsLoading] = useState(false);
  const [noNotificationsData, setNoNotificationsData] = useState(false);
  const { setSubmitMessage } = useContext(SnackbarContext);

  // Notification mapper Data
  const [isNotificationMapperData, setIsNotificationMapperData] = useState(false);
  const [rawNotificationMapper, setRawNotificationMapper] = useState([]);
  const [notificationMapperEntries, setNotificationMapperEntries] = useState(5);
  const [notificationMapperCount, setNotificationMapperCount] = useState(null);
  const [notificationMapperPageCount, setNotificationMapperPageCount] = useState(0);
  const [isNotificationMapperLoading, setIsNotificationMapperLoading] = useState(true);

  // WhatsApp Notification Data
  const [isWhatsAppNotificationData, setIsWhatsAppNotificationData] = useState(false);
  const [rawWhatsAppNotification, setRawWhatsAppNotification] = useState([]);
  const [whatsAppNotificationEntries, setWhatsAppNotificationEntries] = useState(5);
  const [whatsAppNotificationCount, setwhatsAppNotificationCount] = useState(null);
  const [whatsAppNotificationPageCount, setWhatsAppNotificationPageCount] = useState(0);
  const [isWhatsAppNotificationLoading, setIsWhatsAppNotificationLoading] = useState(true);

  // Email Notification Data
  const [isEmailNotificationData, setIsEmailNotificationData] = useState(false);
  const [rawEmailNotification, setRawEmailNotification] = useState([]);
  const [emailNotificationEntries, setEmailNotificationEntries] = useState(5);
  const [emailNotificationCount, setEmailNotificationCount] = useState(null);
  const [emailNotificationPageCount, setEmailNotificationPageCount] = useState(0);
  const [isEmailNotificationLoading, setIsEmailNotificationLoading] = useState(true);

  const getNotifications = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/notifications/api/?is_read=false&user_id=${userID}`,
      headers: {},
    };
    axios
      .request(config)
      .then((response) => {
        setNotifications(response.data.results.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllNotifications = (page_no = currentPage, entrie = entries) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        `${VARIABLES.url}` +
        `/notifications/api/?page_no=${page_no}&entries=${entrie}&user_id=${userID}`,
      headers: {},
    };
    setAreNotificationsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setNoPages(response.data.num_pages);
        setCurrentPage(response.data.current_page);
        setTotalEntries(response.data.count);
        setAllNotifications(response.data.results.data);
        setNoNotificationsData(
          response.data.results.data.length !== 0 ? false : true
        );
        setAreNotificationsLoading(false);
      })
      .catch((error) => {
        setNoNotificationsData(true);
        setAreNotificationsLoading(false);
        console.log(error);
      });
  };

  const handleNotificationStatus = (id) => {
    console.log("first")
    setNotifications(
      notifications.map((notification) => {
        if (notification.id === id) return { ...notification, is_read: true };
        return notification;
      })
    );
    if (!readNotifications.includes(id)) {
      setReadNotifications([...readNotifications, id]);
      console.log("second")
    }
  };

  const updateNotificationStatus = () => {
    console.log("updateNotificationStatus")
    console.log("readNotifications: ", readNotifications)
    console.log("unReadNotifications: ", unReadNotifications)
    if (unReadNotifications.length !== 0 || readNotifications.length !== 0) {
      let data = "";
      data = JSON.stringify({
        mark_as_read: readNotifications,
        mark_as_unread: unReadNotifications,
      });
      if (data !== "") {
        let config = {
          method: "patch",
          maxBodyLength: Infinity,
          url: `${VARIABLES.url}/notifications/api/`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            getNotifications();
            getAllNotifications();
            setUnReadNotifications([]);
            setReadNotifications([]);
            setSubmitMessage({
              type: "success",
              icon: "check",
              message: "Notifications updated successfully!",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      setSubmitMessage({
        type: "danger",
        icon: "cross",
        message: "Nothing to update!",
      });
    }
  };

  const createConnection = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    const sse = new EventSource(
      `${VARIABLES.url}/notifications/?user_id=${userID}`,
      {
        withCredentials: false,
      }
    );

    setRetryCount(0);

    sse.onmessage = (e) => {
      try {
        const data = JSON.parse(e.data);
        setNotifications(
          data.map((e) => {
            return {
              ...e,
              is_read: false,
            };
          })
        );
        getAllNotifications();
      } catch (error) {
        console.error("Error parsing SSE data:", error);
      }
    };
    sse.onerror = () => {
      console.error("Connection error");
      sse.close();
      setRetryCount((prevCount) => prevCount + 1);
      setTimeout(createConnection, 5000);
    };
    eventSourceRef.current = sse;
  };

  const getNotificationMapperData = async (
    page,
    searchNotificationMapper,
    access
  ) => {
    setIsNotificationMapperLoading(true);
    let url;
    if (searchNotificationMapper) {
      url =
        VARIABLES.url +
        `/api/notification-mapper/?entries=${notificationMapperEntries}&page=${page}&event=${searchNotificationMapper}`;
    } else {
      url =
        VARIABLES.url +
        `/api/notification-mapper/?entries=${notificationMapperEntries}&page=${page}`;
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsNotificationMapperLoading(false);
      if (response.length !== 0) {
        setIsNotificationMapperData(false);
        setRawNotificationMapper(res.data.data);
      } else {
        setIsNotificationMapperData(true);
        setRawNotificationMapper(res.data.data);
      }
      const total = res.data.count;
      setNotificationMapperCount(total);
      setNotificationMapperPageCount(
        Math.ceil(total / notificationMapperEntries)
      );
    } catch (error) {
      console.log(error);
      setIsNotificationMapperLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsNotificationMapperData(false);
      } else {
        setIsNotificationMapperData(true);
      }
    }
  };

  const handleNotificationMapperClick = async (
    data,
    searchNotificationMapper
  ) => {
    let access = secureLocalStorage.getItem("access");
    let currentPage = data.selected + 1;
    const commentsFormServer = await getNotificationMapperData(
      currentPage,
      searchNotificationMapper,
      access
    );
  };

  const getWhatsAppNotificationData = async (
    page,
    searchWhatsAppNotification,
    access
  ) => {
    setIsWhatsAppNotificationLoading(true);
    let url;
    if (searchWhatsAppNotification) {
      url =
        VARIABLES.url +
        `/api/whatsapp-notification-config/?entries=${whatsAppNotificationEntries}&page=${page}&event=${searchWhatsAppNotification}`;
    } else {
      url =
        VARIABLES.url +
        `/api/whatsapp-notification-config/?entries=${whatsAppNotificationEntries}&page=${page}`;
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsWhatsAppNotificationLoading(false);
      if (response.length !== 0) {
        setIsWhatsAppNotificationData(false);
        setRawWhatsAppNotification(res.data.data);
      } else {
        setIsWhatsAppNotificationData(true);
        setRawWhatsAppNotification(res.data.data);
      }
      const total = res.data.count;
      setwhatsAppNotificationCount(total);
      setWhatsAppNotificationPageCount(
        Math.ceil(total / whatsAppNotificationEntries)
      );
    } catch (error) {
      console.log(error);
      setIsWhatsAppNotificationLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsWhatsAppNotificationData(false);
      } else {
        setIsWhatsAppNotificationData(true);
      }
    }
  };

  const handleWhatsAppNotificationClick = async (
    data,
    searchWhatsAppNotification
  ) => {
    let access = secureLocalStorage.getItem("access");
    let currentPage = data.selected + 1;
    const commentsFormServer = await getWhatsAppNotificationData(
      currentPage,
      searchWhatsAppNotification,
      access
    );
  };

  const getEmailNotificationData = async (
    page,
    searchEmailNotification,
    access
  ) => {
    setIsEmailNotificationLoading(true);
    let url;
    if (searchEmailNotification) {
      url =
        VARIABLES.url +
        `/api/email-notification-config/?entries=${emailNotificationEntries}&page=${page}&event=${searchEmailNotification}`;
    } else {
      url =
        VARIABLES.url +
        `/api/email-notification-config/?entries=${emailNotificationEntries}&page=${page}`;
    }

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsEmailNotificationLoading(false);
      if (response.length !== 0) {
        setIsEmailNotificationData(false);
        setRawEmailNotification(res.data.data);
      } else {
        setIsEmailNotificationData(true);
        setRawEmailNotification(res.data.data);
      }
      const total = res.data.count;
      setEmailNotificationCount(total);
      setEmailNotificationPageCount(
        Math.ceil(total / emailNotificationEntries)
      );
    } catch (error) {
      console.log(error);
      setIsEmailNotificationLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsEmailNotificationData(false);
      } else {
        setIsEmailNotificationData(true);
      }
    }
  };

  const handleEmailNotificationClick = async (
    data,
    searchEmailNotification
  ) => {
    let access = secureLocalStorage.getItem("access");
    let currentPage = data.selected + 1;
    const commentsFormServer = await getEmailNotificationData(
      currentPage,
      searchEmailNotification,
      access
    );
  };


  // useEffect(() => {
  //   setUnreadNotificationsCount(notifications.length);
  // }, [notifications]);

  // useEffect(() => {
  //   console.log(retryCount);
  //   if (retryCount !== 0) {
  //     // enqueueSnackbar("No connection!", {
  //     //   anchorOrigin: {
  //     //     vertical: "bottom",
  //     //     horizontal: "center",
  //     //   },
  //     //   variant: "error",
  //     //   persist: true,
  //     //   preventDuplicate: true,
  //     // });
  //   } else {
  //     // closeSnackbar();
  //   }
  // }, [retryCount]);

  useEffect(() => {
    createConnection();
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    updateNotificationStatus()
  }, [readNotifications]);

  const value = {
    notifications,
    allNotifications,
    unreadNotificationsCount,
    setNotifications,
    areNotificationsLoading,
    noPages,
    currentPage,
    entries,
    setEntries,
    setCurrentPage,
    handleNotificationStatus,
    updateNotificationStatus,
    getAllNotifications,
    totalEntries,
    readNotifications,
    unReadNotifications,
    setReadNotifications,
    setUnReadNotifications,
    setAllNotifications,
    noNotificationsData,

    rawNotificationMapper,
    setRawNotificationMapper,
    getNotificationMapperData,
    notificationMapperEntries,
    notificationMapperPageCount,
    setNotificationMapperEntries,
    notificationMapperCount,
    handleNotificationMapperClick,
    isNotificationMapperData,
    isNotificationMapperLoading,
    setIsNotificationMapperLoading,

    rawWhatsAppNotification,
    setRawWhatsAppNotification,
    getWhatsAppNotificationData,
    whatsAppNotificationEntries,
    whatsAppNotificationPageCount,
    setWhatsAppNotificationEntries,
    whatsAppNotificationCount,
    handleWhatsAppNotificationClick,
    isWhatsAppNotificationData,
    isWhatsAppNotificationLoading,
    setIsWhatsAppNotificationLoading,

    rawEmailNotification,
    setRawEmailNotification,
    getEmailNotificationData,
    emailNotificationEntries,
    emailNotificationPageCount,
    setEmailNotificationEntries,
    emailNotificationCount,
    handleEmailNotificationClick,
    isEmailNotificationData,
    isEmailNotificationLoading,
    setIsEmailNotificationLoading,

    getNotifications,
    setUnreadNotificationsCount,
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
}

export default NotificationsContextProvider;
