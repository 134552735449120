import React, { useEffect, useContext } from "react";
import moment from "moment/moment";
import { ResumeDownloadContext } from "../../Context/ResumeDownloadContext";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import secureLocalStorage from "react-secure-storage";

const ResumeDownload = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const {
    allResume,
    getResumes,
    resumeEntries,
    resumePageCount,
    setResumeEntries,
    resumeCount,
    handleResumeClick,
    isResumeData,
    isResumeLoading,
  } = useContext(ResumeDownloadContext);

  const navigate = useNavigate();

  const handleEntries = (event) => {
    setResumeEntries(event.target.value);
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getResumes(access, 1);
    } else {
      let access = secureLocalStorage.getItem("access");
      getResumes(access, 1);
    }
  }, [isAccess]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Resume Download</h3>
            {isResumeLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner px-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Uploaded Date</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Download</span>
                      </div>
                    </div>

                    {allResume.length > 0 ? (
                      allResume.map((resume, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="uploadedDate">
                                {moment(resume.created_at).format(
                                  "MMM DD, YYYY h:mm a"
                                )}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              <span className="download">
                                <a
                                  id="resume_zip_file_link"
                                  href={`${resume.zip_file}`}
                                >
                                  Link
                                </a>
                              </span>
                            </span>
                          </div>
                        </div>
                      ))
                    ) : isResumeData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading.....</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>

            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={resumePageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={resumePageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) => handleResumeClick(data)}
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {resumeCount === null ? 0 : resumeCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={resumeEntries}
                        onChange={handleEntries}
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResumeDownload;
