import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import Swal from "sweetalert2";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import DatePicker from "rsuite/DatePicker";
import "rsuite/DatePicker/styles/index.css";
import { format } from "date-fns";

const AddRequisition = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [filteredTeamData, setFilteredTeamData] = useState([]);
  console.log(filteredTeamData);
  const [jobOpeningFound, setJobOpeningFound] = useState(false);

  const [filteredPositionData, setFilteredPositionData] = useState([]);
  console.log(filteredPositionData);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    resetField,
    setValue
  } = useForm();
  let navigate = useNavigate();

  const { getRequisitionByUserData } = useContext(RequestedJobOpeningContext);

  const {
    isDropdownDataLoading,
    jobTypeDropdownData,
    requisitionReasonDropdownData,
    jobLocationDropdownData,
    priorityDropdownData,
    deadlineDropdownData
  } = useContext(TypemasterDropdownContext);

  const {
    isGenricDropdownDataLoading,
    departmentDropdownData,
    teamDropdownData,
    positionDropdownData,
    seniorityDropdownData,
    jobOpeningDropdownData,
    getGenericDropdownData,
  } = useContext(GenericDropdownContext);

  console.log(teamDropdownData);
  console.log("positionDropdownData: ", positionDropdownData);
  console.log("seniorityDropdownData: ", seniorityDropdownData);
  console.log("RequisitionReasons :", requisitionReasonDropdownData);
  console.log("jobLocationDropdownData: ", jobLocationDropdownData);

  const disablePastDates = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  const [departmentEntry, setDepartmentEntry] = useState(null);
  console.log(departmentEntry);
  const [teamEntry, setTeamEntry] = useState(null);
  const [positionEntry, setPositionEntry] = useState(null);
  console.log(positionEntry);
  const [seniorityEntry, setSeniorityEntry] = useState(null);
  console.log(seniorityEntry);

  const [isPositionLoading, setIsPositionLoading] = useState(false);

  const requiredExperienceRange = watch("required_experience_range");

  // const validateMaxSalary = (value) => {
  //   if (value) {
  //     return (
  //       parseFloat(value) > minSalary ||
  //       "Max Salary must be greater than Min Salary"
  //     );
  //   }
  // };

  const validateMaxSalary = (value) => {
    console.log(parseFloat(value))
    if (value) {
      return (
        parseFloat(value) <= 30 ||
        "Max Salary should not exceed 30 lakhs"
      );
    }
  };

  const handleExperienceRange = (value) => {
    console.log(parseFloat(value))
    if (value) {
      if (requiredExperienceRange) {
        return (
          parseFloat(value) < requiredExperienceRange ||
          "Please enter valid range"
        );
      }
    }
  };

  // const handlePriority = (e) => {
  //   console.log(e);
  //   if (e.value) {
  //     let priorityValue = null;
  //     if (e.value === "Replacement") {
  //       priorityValue = { value: "High", label: "High" };
  //     } else if (e.value === "Expansion") {
  //       priorityValue = { value: "Medium", label: "Medium" };
  //     } else if (e.value === "Temporary") {
  //       priorityValue = { value: "Low", label: "Low" };
  //     }

  //     setValue("priority", priorityValue);
  //   }
  // };


  const [jobOpeningID, setJobOpeningID] = useState(null);
  console.log(jobOpeningID);

  const handleJobOpeningID = (data) => {
    console.log("data: ", data);
    console.log("jobOpeningDropdownData: ", jobOpeningDropdownData);
    const getJobOpeningID = jobOpeningDropdownData.find(
      (info) =>
        info.seniority_pk === data?.value &&
        info.position_pk === positionEntry.value
    );
    setJobOpeningID(getJobOpeningID);
    if (data && getJobOpeningID === undefined) {
      Swal.fire({
        title: `Please contact admin`,
        html: `Job opening for ${data?.label || "-"} ${positionEntry.label || "-"
          } is not created yet!<br>Do you want to continue with this job opening?`,
        showDenyButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          // resetField("seniority");
          // CreatePosition()
          setJobOpeningFound(true);
        } else {
          resetField("seniority");
        }
      });
    }
  };
  console.log(jobOpeningID);

  const teamOptionCondition = () => {
    return departmentEntry
      ? "No Team Under the selected department"
      : "Please Select Department first";
  };

  const positionOptionCondition = () => {
    return teamEntry
      ? "No Position Under the selected team"
      : "Please Select Team first";
  };

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    const experience = data.required_experience_range
      ? `${data.required_experience}-${data.required_experience_range}`
      : data.required_experience;

    let priorityValue = null;
    if (data.requisition_reason.value === "Replacement") {
      priorityValue = "High"
    } else if (data.requisition_reason.value === "Expansion") {
      priorityValue = "Medium"
    } else if (data.requisition_reason.value === "Temporary") {
      priorityValue = "Low"
    }

    var job_posting = JSON.stringify({
      requested_user_id: userID,
      job_opening_id: jobOpeningID?.id,
      job_type: data.job_type.value,
      reason_to_raise_requisition: data.requisition_reason.value,
      job_location: data.job_location.value,
      required_experience: experience,
      no_of_vacancy: data.no_of_vacancy,
      // min_salary: data.min_salary,
      max_salary: data.max_salary ? data.max_salary : null,
      // deadline: data.deadline
      //   ? format(new Date(data.deadline), "yyyy-MM-dd'T'HH:mm")
      //   : null,
      deadline: data?.deadline?.value,
      priority: priorityValue,
      comments: data.comments,
      pointers: data.jd_pointers,
      keywords: data.keywords,
      created_by_id: userID,
      requested_user_id: userID,
      ...(jobOpeningFound && {
        position_id: positionEntry?.value,
        seniority_id: seniorityEntry?.value,
      }),
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/requestrequisition/",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: job_posting,
    };

    console.log(job_posting);

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        onTATSubmit(response.data)
        Swal.fire({
          title: 'Success',
          text: `${response.data?.message}`,
          icon: "success",
          confirmButtonText: "Okay",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
            reset();
          }
        });
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          Swal.fire({
            title: 'Oops...',
            text: `${error.response?.data || "Failed"}`,
            icon: "error",
            confirmButtonText: "Okay",
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("failed")
            }
          });
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to request requisition",
          });
        }
      });
  };

  const onTATSubmit = (data) => {
    var user = JSON.stringify({
      requisition_id: data?.data?.id,
      stage: "Requisition Raised",
      created_by_id: userID
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/requisition-activity-log/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          RefreshToken();
        }
      });
  };

  useEffect(() => {
    console.log(departmentEntry?.label);
    const rawfilteredTeamData = teamDropdownData.filter(
      (info) => info?.department_display_name == departmentEntry?.label
    );
    setFilteredTeamData(rawfilteredTeamData);
    resetField("team");
    resetField("position");
    resetField("seniority");
  }, [departmentEntry]);

  useEffect(() => {
    const rawfilteredPositionData = positionDropdownData.filter(
      (info) => info?.team_display_name == teamEntry?.label
    );
    setFilteredPositionData(rawfilteredPositionData);
    resetField("position");
    resetField("seniority");
  }, [teamEntry]);

  useEffect(() => {
    resetField("seniority");
  }, [positionEntry]);

  useEffect(() => {
    getRequisitionByUserData();
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title">Request Requisition</h4>
            {isPositionLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Department <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={departmentDropdownData?.map((info) => ({
                            value: info?.id,
                            label: info?.department_display_name,
                          }))}
                          isLoading={isGenricDropdownDataLoading}
                          isClearable={true}
                          onChange={(e) => {
                            setDepartmentEntry(e);
                            field.onChange(e);
                          }}
                          id="select-forms-department"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="department"
                      control={control}
                    />
                    {errors.department && (
                      <span className="fs-8 text-danger">
                        Department name is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Team <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={filteredTeamData?.map((info) => ({
                            value: info.team_display_name,
                            label: info.team_display_name,
                          }))}
                          isClearable={true}
                          noOptionsMessage={teamOptionCondition}
                          onChange={(e) => {
                            setTeamEntry(e);
                            field.onChange(e);
                          }}
                          id="select-forms-team"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="team"
                      control={control}
                    />
                    {errors.team && (
                      <span className="fs-8 text-danger">
                        Team name is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Position <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={filteredPositionData?.map((info) => ({
                            value: info.id,
                            label: info.position_display_name,
                          }))}
                          isClearable={true}
                          noOptionsMessage={positionOptionCondition}
                          onChange={(e) => {
                            setPositionEntry(e);
                            field.onChange(e);
                          }}
                          id="select-forms-position"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="position"
                      control={control}
                    />
                    {errors.position && (
                      <span className="fs-8 text-danger">
                        Position is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Seniority <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={seniorityDropdownData?.map((info) => ({
                            value: info.id,
                            label: info.seniority_display_name,
                          }))}
                          onChange={(e) => {
                            setSeniorityEntry(e);
                            handleJobOpeningID(e);
                            field.onChange(e);
                          }}
                          isLoading={isGenricDropdownDataLoading}
                          isClearable={true}
                          id="select-forms-seniority"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="seniority"
                      control={control}
                    />
                    {errors.seniority && (
                      <span className="fs-8 text-danger">
                        Seniority is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Job Type <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={jobTypeDropdownData?.map((info) => ({
                            value: info.name,
                            label: info.name,
                          }))}
                          isClearable={true}
                          isLoading={isDropdownDataLoading}
                          id="select-forms-job_type"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="job_type"
                      control={control}
                    />
                    {errors.job_type && (
                      <span className="fs-8 text-danger">
                        Job type is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Job Location <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={jobLocationDropdownData?.map((info) => ({
                            value: info.name,
                            label: info.name,
                          }))}
                          isClearable={true}
                          isLoading={isGenricDropdownDataLoading}
                          id="select-forms-job_location"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="job_location"
                      control={control}
                    />
                    {errors.job_location && (
                      <span className="fs-8 text-danger">
                        Job Location is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Required Experience (in Years)
                      <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        id="number-forms-required_experience"
                        placeholder="Enter required experience *"
                        onWheel={(e) => e.target.blur()}
                        {...register("required_experience", {
                          required: true,
                          validate: handleExperienceRange
                        })}
                      />
                      <span className="ms-1 me-1 align-self-center">to</span>
                      <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        id="number-forms-required_experience_range"
                        placeholder="Enter required experience range"
                        onWheel={(e) => e.target.blur()}
                        {...register("required_experience_range")}
                      />
                    </div>
                    {errors.required_experience &&
                      errors.required_experience.type === "required" && (
                        <span className="fs-8 text-danger">
                          Please enter required experience
                        </span>
                      )}
                    {errors.required_experience &&
                      errors.required_experience.type === "validate" && (
                        <span className="fs-8 text-danger">
                          Please enter valid range
                        </span>
                      )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      No of Vacancy <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="number-forms-no_of_vacancy"
                      placeholder="Enter no of vacancy"
                      onWheel={(e) => e.target.blur()}
                      {...register("no_of_vacancy", {
                        required: true,
                        pattern: { value: /^\d+$/ },
                      })}
                    />
                    {errors.no_of_vacancy &&
                      errors.no_of_vacancy.type === "required" && (
                        <span className="fs-8 text-danger">
                          Please enter total no of vacancies
                        </span>
                      )}
                    {errors.no_of_vacancy &&
                      errors.no_of_vacancy.type === "pattern" && (
                        <span className="fs-8 text-danger">
                          No of Vacancy cannot be negative or in decimal
                        </span>
                      )}
                  </div>
                </div>

                {/* <div className="col-lg-6">
                  <div className="form-control-wrap">
                    <label className="form-label">
                      Minimum Salary <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="number-forms-min_salary"
                        placeholder="Enter minimum salary"
                        step="0.01"
                        onWheel={(e) => e.target.blur()}
                        {...register("min_salary", {
                          required: true,
                          // pattern: {
                          //   value: /^\d{1,2}$/,
                          //   message:
                          //     "Salary should be a number with up to 2 digits",
                          // },
                        })}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          lakhs per annum
                        </span>
                      </div>
                    </div>
                    {errors.min_salary &&
                      errors.min_salary.type === "required" && (
                        <span className="fs-8 text-danger">
                          Min Salary is required
                        </span>
                      )}
                    {errors.min_salary &&
                      errors.min_salary.type === "pattern" && (
                        <span className="fs-8 text-danger">
                          {errors.min_salary.message}
                        </span>
                      )}
                  </div>
                </div> */}

                <div className="col-lg-6">
                  <div className="form-control-wrap mb-0">
                    <label className="form-label">
                      Maximum Salary
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="number-forms-max_salary"
                        placeholder="Enter maximum salary"
                        step="0.01"
                        onWheel={(e) => e.target.blur()}
                        {...register("max_salary", {
                          // required: true,
                          validate: validateMaxSalary,
                          // pattern: {
                          //   value: /^\d{1,2}$/,
                          //   message:
                          //     "Salary should be a number with up to 2 digits",
                          // },
                        })}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          lakhs per annum
                        </span>
                      </div>
                    </div>
                    {errors.max_salary &&
                      errors.max_salary.type === "required" && (
                        <span className="fs-8 text-danger">
                          Max Salary is required
                        </span>
                      )}
                    {errors.max_salary &&
                      errors.max_salary.type === "validate" && (
                        <span className="fs-8 text-danger">
                          {errors.max_salary.message}
                        </span>
                      )}
                    {errors.max_salary &&
                      errors.max_salary.type === "pattern" && (
                        <span className="fs-8 text-danger">
                          {errors.max_salary.message}
                        </span>
                      )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Reasons to Raise a Requisition
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={requisitionReasonDropdownData?.map(
                            (info) => ({
                              value: info.name,
                              label: info.name,
                            })
                          )}
                          // onChange={(e) => {
                          //   handlePriority(e);
                          //   field.onChange(e);
                          // }}
                          isLoading={isGenricDropdownDataLoading}
                          isClearable={true}
                          id="select-forms-requisition_reason"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="requisition_reason"
                      control={control}
                    />
                    {errors.requisition_reason && (
                      <span className="fs-8 text-danger">
                        Requisition reason is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">Deadline (in days)</label>
                    {/* <Controller
                      name="deadline"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          format="dd.MM.yyyy hh:mm aa"
                          style={{ width: "500px", minWidth: "100%" }}
                          disabledDate={disablePastDates}
                        />
                      )}
                    /> */}
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={deadlineDropdownData?.map((info) => ({
                            value: info.name,
                            label: info.name,
                          }))}
                          isClearable={true}
                          isLoading={isDropdownDataLoading}
                          id="select-forms-deadline"
                        />
                      )}
                      defaultValue=""
                      // rules={{ required: true }}
                      name="deadline"
                      control={control}
                    />
                  </div>
                </div>

                {/* <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Priority <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={priorityDropdownData?.map((info) => ({
                            value: info.name,
                            label: info.name,
                          }))}
                          isClearable={true}
                          isLoading={isDropdownDataLoading}
                          id="select-forms-priority"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="priority"
                      control={control}
                    />
                    {errors.priority && (
                      <span className="fs-8 text-danger">
                        Priority is required
                      </span>
                    )}
                  </div>
                </div> */}

                {/* <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">JD Pointers <span className="text-danger"> *</span></label>
                    <textarea
                      type="datetime-local"
                      className="form-control"
                      id="textarea-forms-jd_pointers"
                      placeholder="Enter JD pointers"
                      {...register("jd_pointers", {
                        required: true
                      })}
                    />
                    {errors.jd_pointers && (
                      <span className="fs-8 text-danger">
                        JD Pointers is required
                      </span>
                    )}
                  </div>
                </div> */}

                {/* <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Keywords <span className="text-danger"> *</span>
                    </label>
                    <textarea
                      type="datetime-local"
                      className="form-control"
                      id="textarea-forms-keywords"
                      placeholder="Enter keywords"
                      {...register("keywords", {
                        required: true
                      })}
                    />
                    {errors.keywords && (
                      <span className="fs-8 text-danger">
                        Keywords is required
                      </span>
                    )}
                  </div>
                </div> */}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">Comments</label>
                    <textarea
                      type="datetime-local"
                      className="form-control"
                      id="textarea-forms-comments"
                      placeholder="Enter comments"
                      {...register("comments")}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createjobopening"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AddRequisition;
