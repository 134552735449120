import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { VARIABLES } from "../../../Constants";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import secureLocalStorage from "react-secure-storage";
import { BackgroundVerificationContext } from "../../../Context/BackgroundVerificationContext";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import Message from "../Message/Message";
// import { bgVerificationDataThree } from '../../WorkflowStepperForm/StepperFormJson'

const BackgroundVerificationReview = (props) => {
  const location = useLocation();
  const candidate_id = location?.state?.candidate_id;
  const requisition_id = location?.state?.requisition_id;
  const job_opening_id = location?.state?.job_opening_id;

  console.log(props.formId); //data of the tab in which this form is present

  // Secure Local Storage
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const privileges = secureLocalStorage.getItem("privileges");

  const navigate = useNavigate();
  // const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  // console.log(candidate_job_id);
  // const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");

  const { setSubmitMessage } = useContext(SnackbarContext);
  const [submitLoading, setSubmitLoading] = useState(false); //loading state while submitting data

  // stores the background verification in "bgVerificationDataOne", "bgVerificationDataTwo","bgVerificationDataThree"
  const {
    isLoading,
    bgVerificationDataOne,
    bgVerificationDataTwo,
    bgVerificationDataThree,
  } = useContext(BackgroundVerificationContext);

  // Stores then background verification data according to the type wise
  const bgVerificationData =
    props.bgv_type === "bgv_1" && bgVerificationDataOne?.length > 0
      ? bgVerificationDataOne[0]
      : props.bgv_type === "bgv_2" && bgVerificationDataTwo?.length > 0
        ? bgVerificationDataTwo[0]
        : props.bgv_type === "bgv_3" && bgVerificationDataThree?.length > 0
          ? bgVerificationDataThree[0]
          : null;
  console.log(bgVerificationData);

  // "interviewActionDropdownData" stores the Accept/Reject/Draft Dropdown
  const { interviewActionDropdownData } = useContext(TypemasterDropdownContext);

  // To call workflow session after the POST/ PATCH request
  const { getWorkflowSession, workflowSession } = useContext(WorkflowContext);

  // const isFormFilled = workflowSession.find(
  //   (api) => api.tab_id == props.formId?.id
  // );
  const getPrivilegeForBgvType = (bgvType) => {
    switch (bgvType) {
      case "bgv_1":
        return "Background Verification one Review Create";
      case "bgv_2":
        return "Background Verification two Review Create";
      case "bgv_3":
        return "Background Verification three Review Create";
      default:
        return "";
    }
  };

  const isFieldsDisabled =
    workflowSession.find((api) => api.tab_id === props.formId?.id) ||
    !privileges.includes(getPrivilegeForBgvType(props.bgv_type));

  //message after submitting data

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm();

  // Stepper (PREV/NEXT)
  const { level, sequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

  //Submission of form
  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    let workflow_session = {
      tab_id: props.formId?.id,
      workflow_id: props.formId?.WF,
      user_id: userID,
    };
    let user = new FormData();
    user.append("candidate_job_id", candidate_id);
    user.append("requisition_id", requisition_id);
    user.append("feedback", data.feedback);
    user.append("bgv_type", props.bgv_type);
    user.append(
      "candidate_accept_reject",
      data?.is_selected === "Draft"
        ? 0
        : data?.is_selected === "Accept"
          ? 1
          : data?.is_selected === "Reject"
            ? 2
            : null
    );
    user.append("updated_by_id", userID);
    user.append("workflow_session", JSON.stringify(workflow_session));

    const url =
      VARIABLES.url + `/api/backgroundverification/${bgVerificationData?.id}/`;
    console.log(url);

    var config = {
      method: "patch",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${access}`,
      },
      url: url,
      data: user,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data) {
          getWorkflowSession(candidate_id, requisition_id);
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: data?.is_selected === "Draft" ? "Candidate drafted successfully" : data?.is_selected === "Reject" ? "Candidate rejected successfully" : "Background verification reviewed successfully",
          });
          sequenceList.length - 2 > level
            ? nextLevel()
            : navigate(-1, {
              state: {
                editpost: {
                  requisition: {
                    id: location?.state?.requisition_id,
                  },
                  job_opening: {
                    id: location?.state?.job_opening_id,
                  },
                },
              },
            });
          reset();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Review failed",
        });
      });
  };

  // Pre-populting data
  useEffect(() => {
    const editedData = bgVerificationData;
    console.log(editedData);
    if (editedData) {
      var defaultValues = {};
      defaultValues.feedback = editedData.feedback;
      defaultValues.is_selected =
        editedData?.candidate_accept_reject === 0
          ? "Draft"
          : editedData?.candidate_accept_reject === 1
            ? "Accept"
            : editedData?.candidate_accept_reject === 2
              ? "Reject"
              : null;
      reset(defaultValues);
    }
  }, [bgVerificationData]);

  const [workflowId, setWorkflowId] = useState(null);
  console.log(workflowSession);

  useEffect(() => {
    const filter_workflow_session = workflowSession.find(
      (api) => api.tab_id == props.formId?.id
    );
    console.log(filter_workflow_session);
    setWorkflowId(filter_workflow_session);
  }, [workflowSession]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Background Verification Review </h4>
            {isLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {props.formId?.is_iterable && props.formId?.is_iterable == true && (
            <AddStepperTab formId={props.formId} />
          )}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        {bgVerificationData?.candidate_accept_reject === 2 ?
          <Message icon={require("../../../../Resources/images/rejected.png")}
            message={"The candidate has been disqualified at this stage of the recruitment process."} />
          :
          <div className="card">
            <div className="card-aside-wrap">
              <div className="card-content">
                <div className="card-inner">
                  <div className="nk-block">
                    <div className="profile-ud-list">
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label me-4">
                            Verification Agency
                          </span>
                          <span className="profile-ud-value">
                            {bgVerificationData?.verification_agency || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label me-4">
                            Date of Verification
                          </span>
                          <span className="profile-ud-value">
                            {bgVerificationData?.date_of_verification
                              ? moment(
                                bgVerificationData?.date_of_verification
                              ).format("MMM DD, YYYY h:mm a")
                              : "-"}
                          </span>
                        </div>
                      </div>

                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label me-4">Comments</span>
                          <span className="profile-ud-value">
                            {bgVerificationData?.comments || "-"}
                          </span>
                        </div>
                      </div>

                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label me-4">
                            View Document
                          </span>
                          <span class="profile-ud-value">
                            {bgVerificationData?.result ? (
                              <a
                                className="btn btn-primary"
                                id="button-button-viewresume"
                                href={bgVerificationData?.result}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <em
                                  className="icon ni ni-file-pdf"
                                  style={{ color: "white" }}
                                ></em>
                              </a>
                            ) : (
                              "-"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-inner">
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="row g-4">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label className="form-label">
                          Feedback
                          <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          id="textarea-form-feedback"
                          placeholder="Enter Feedback"
                          {...register("feedback", { required: true })}
                          disabled={isFieldsDisabled || bgVerificationData?.candidate_accept_reject !== null && bgVerificationData?.candidate_accept_reject !== 0}
                        />
                        {errors.feedback && (
                          <span className="fs-8 text-danger">
                            Feedback is required
                          </span>
                        )}
                      </div>

                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <ul className="row g-3">
                          {interviewActionDropdownData.map((action, index) => (
                            <li
                              className="col-lg-2 col-md-2 col-sm-2"
                              key={index}
                            >
                              <div className="custom-control custom-radio me-3">
                                <input
                                  type="radio"
                                  id={`checkbox-form-is-selected-bgv-${action.value.toLowerCase() + props.bgv_type
                                    }`}
                                  name="is_selected"
                                  className="custom-control-input"
                                  value={action.value}
                                  {...register("is_selected", { required: true })}
                                  disabled={isFieldsDisabled || bgVerificationData?.candidate_accept_reject !== null && bgVerificationData?.candidate_accept_reject !== 0}
                                />
                                <label
                                  className="custom-control-label"
                                  for={`checkbox-form-is-selected-bgv-${action.value.toLowerCase() + props.bgv_type
                                    }`}
                                >
                                  {action.name}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                        {errors.is_selected && (
                          <span className="fs-8 text-danger">
                            Please select any one
                          </span>
                        )}
                      </div>

                      <div className="col-12 d-flex">
                        <div className="form-group">
                          {level !== 0 && (
                            <button
                              type="button"
                              className="btn btn-lg btn-primary me-3"
                              id="button-button-prevlevel"
                              onClick={() => prevLevel()}
                            >
                              Prev
                            </button>
                          )}
                        </div>
                        <div className="form-group">
                          {submitLoading ? (
                            <button
                              id="button-loading"
                              className="btn text-dark"
                              disabled
                            >
                              Loading...
                            </button>
                          ) : (
                            <>
                              {sequenceList.length - 2 > level ? (
                                workflowId?.is_complete === 1 ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    id="button-button-bgv"
                                    onClick={() => nextLevel()}
                                  >
                                    Next
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-primary"
                                    id="next-button-bgv"
                                  >
                                    Next
                                  </button>
                                )
                              ) : (
                                workflowId?.is_complete === 1 ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    id="submit-button-bgv"
                                    onClick={() => navigate(-1, {
                                      state: {
                                        editpost: {
                                          requisition: {
                                            id: location?.state?.requisition_id,
                                          },
                                          job_opening: {
                                            id: location?.state?.job_opening_id,
                                          },
                                        },
                                      },
                                    })}
                                  >
                                    Submit
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-primary"
                                    id="submit-button-bgv"
                                  >
                                    Submit
                                  </button>
                                )
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div >
        }
      </div >
      <Snackbar />
    </>
  );
};

export default BackgroundVerificationReview;
