import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { createContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const ScheduleCandidateInterviewContext = createContext(null);

function ScheduleCandidateInterviewProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");
  // candidate selected for interview are stored in "rawAcceptedCandidateDetails"
  const [isCandidateData, setIsCandidateData] = useState(false);
  const [rawAcceptedCandidateDetails, setRawAcceptedCandidateDetails] = useState([]);
  console.log(rawAcceptedCandidateDetails);
  const [candidateEntries, setCandidateEntries] = useState(5);
  const [candidateCount, setCandidateCount] = useState(null);
  const [candidatePageCount, setCandidatePageCount] = useState(0);
  const [isCandidateLoading, setIsCandidateLoading] = useState(true);

  // interview schedule slots are stored in "rawInterviewSlots"
  const [rawInterviewSlots, setRawInterviewSlots] = useState([]);
  console.log(rawInterviewSlots);

  const [tamInterviewSlots, setTamInterviewSlots] = useState([]);
  const [isInterviewSlotsLoading, setIsTamInterviewSlotsLoading] = useState(true);

  const [interviewerName, setInterviewerName] = useState([]);
  console.log("interviewerName: ", interviewerName)

  const [candidateName, setCandidateName] = useState([]);
  console.log("candidateName: ", candidateName)

  const [requisitionName, setRequisitionName] = useState([]);
  console.log("requisitionName: ", requisitionName)

  const getAcceptedCandidateDetails = async (
    page,
    reqId,
    searchCandidate,
    sortingDate,
    access,
    shortlisted_type
  ) => {
    setIsCandidateLoading(true);
    console.log(searchCandidate);
    let url;
    if (searchCandidate) {
      if (sortingDate == "latest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${reqId}&shortlisted_type=${shortlisted_type}&entries=${candidateEntries}&page=${page}&event=${searchCandidate}&order_by=-created_at`;
      } else if (sortingDate == "oldest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${reqId}&shortlisted_type=${shortlisted_type}&entries=${candidateEntries}&page=${page}&event=${searchCandidate}&order_by=created_at`;
      } else {
        url =
          VARIABLES.url +
          `/api/candidateresume/?shortlisted_type=${shortlisted_type}&requisition_id=${reqId}&entries=${candidateEntries}&page=${page}&event=${searchCandidate}`;
      }
    } else {
      if (sortingDate == "latest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${reqId}&shortlisted_type=${shortlisted_type}&entries=${candidateEntries}&page=${page}&order_by=-created_at`;
      } else if (sortingDate == "oldest") {
        url =
          VARIABLES.url +
          `/api/candidateresume/?requisition_id=${reqId}&shortlisted_type=${shortlisted_type}&entries=${candidateEntries}&page=${page}&order_by=created_at`;
      } else {
        url =
          VARIABLES.url +
          `/api/candidateresume/?shortlisted_type=${shortlisted_type}&requisition_id=${reqId}&entries=${candidateEntries}&page=${page}`;
      }
    }
    console.log(url);

    var config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsCandidateLoading(false);
      if (response.length !== 0) {
        setIsCandidateData(false);
        setRawAcceptedCandidateDetails(res.data.data);
      } else {
        setIsCandidateData(true);
        setRawAcceptedCandidateDetails(res.data.data);
      }
      const total = res.data.count;
      setCandidateCount(total);
      setCandidatePageCount(Math.ceil(total / candidateEntries));
    } catch (error) {
      console.log(error);
      setIsCandidateLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsCandidateData(false);
      } else {
        setIsCandidateData(true);
      }
    }
  };

  const handleAcceptCandidateClick = async (data, reqId, searchCandidate, sort, access, from) => {
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getAcceptedCandidateDetails(
      currentPage,
      reqId,
      searchCandidate,
      sort,
      access,
      from
    );
  };

  const getInterviewSlots = async (access) => {
    let url;
    if (interviewerName.length > 0) {
      const interviewer_name = interviewerName.map((api) => api.value);
      url =
        VARIABLES.url +
        `/api/interview-slots/?interviewer_id=${userID + "," + interviewer_name}&is_booked=false`;
    } else {
      url =
        VARIABLES.url +
        `/api/interview-slots/?interviewer_id=${userID}&is_booked=false`;
    }
    console.log(url);

    var config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    setIsTamInterviewSlotsLoading(true)
    try {
      const res = await axios(config);
      console.log(res.data.data);
      setRawInterviewSlots(res.data.data);
      setIsTamInterviewSlotsLoading(false)
    } catch (error) {
      console.log(error);
      setIsTamInterviewSlotsLoading(false)
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsCandidateData(false);
      }
    }
  };

  const getTamInterviewSlots = async (access) => {
    const requisition_name = requisitionName?.map((api) => api.value);
    const candidate_name = candidateName?.map((api) => api.value);
    const interviewer_name = interviewerName?.map((api) => api.value);
    let url;
    if (requisitionName?.length > 0 && interviewerName?.length === 0 && candidateName?.length === 0) {
      // If requisitionName is present, and others are empty
      url =
        VARIABLES.url +
        `/api/interview-slots/?requisition_id=${requisition_name}`;
    } else if (interviewerName?.length > 0 && candidateName?.length === 0) {
      // Case 1: interviewerName is not empty, but candidateName is empty
      url =
        VARIABLES.url +
        `/api/interview-slots/?requisition_id=${requisition_name}&interviewer_id=${interviewer_name}`;
    } else if (interviewerName?.length > 0 && candidateName?.length > 0) {
      // Case 2: interviewerName is not empty, and candidateName is not empty
      url =
        VARIABLES.url +
        `/api/interview-slots/?requisition_id=${requisition_name}&interviewer_id=${interviewer_name}&candidate_id=${candidate_name}`;
    } else {
      // Default case: interviewerName is empty, only use userID
      url =
        VARIABLES.url +
        `/api/interview-slots/`;
    }
    console.log(url);

    var config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      console.log(res.data.data);
      setTamInterviewSlots(res.data.data);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsCandidateData(false);
      }
    }
  };

  return (
    <div>
      <ScheduleCandidateInterviewContext.Provider
        value={{
          rawAcceptedCandidateDetails,
          getAcceptedCandidateDetails,
          candidateEntries,
          candidatePageCount,
          setCandidateEntries,
          candidateCount,
          handleAcceptCandidateClick,
          isCandidateData,
          isCandidateLoading,

          rawInterviewSlots,
          getInterviewSlots,
          setInterviewerName,
          interviewerName,
          requisitionName,
          setRequisitionName,
          candidateName,
          setCandidateName,

          getTamInterviewSlots,
          tamInterviewSlots,
          isInterviewSlotsLoading
        }}
      >
        {children}
      </ScheduleCandidateInterviewContext.Provider>
    </div>
  );
}

export default ScheduleCandidateInterviewProvider;
