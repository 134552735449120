import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";
import { StepperCandidate } from "../../../Context/StepperCandidate";
import { useLocation } from "react-router-dom";
// import { telephonicData } from "../../WorkflowStepperForm/StepperFormJson";

const TelephonicRound = ({ formId }) => {
  console.log(formId); //data of the tab in which this form is present
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const privileges = secureLocalStorage.getItem("privileges");

  const location = useLocation();

  const candidate_id = location.state?.candidate_id;
  const requisition_id = location.state?.requisition_id;

  const {
    level,
    sequenceList,
    nextLevel,
    prevLevel,
    submitLoading,
    setSubmitLoading,
  } = useContext(SequenceContext);

  const { workflowSession, getWorkflowSession } = useContext(WorkflowContext);

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  const { isTelLoading,
    telephonicData,
    getTelephonicData } =
    useContext(StepperCandidate);

  console.log("telephonicData: ", telephonicData);
  console.log(telephonicData.length);

  const questions_array = [
    { id: 1, question: "Six Days Working", answer: "" },
    { id: 2, question: "Saturday & Sunday Rotational", answer: "" },
    { id: 3, question: "Location Suitability", answer: "" },
    { id: 4, question: "Notice Period", answer: "" },
    { id: 5, question: "Flexible Notice Period", answer: "" },
    { id: 6, question: "Relevant vs Required Experience", answer: "" },
    { id: 7, question: "Relevant Experience Score", answer: "" },
    { id: 8, question: "Technical Profile vs Skillsets", answer: "" },
    { id: 9, question: "Relevant Academic Validation Score", answer: "" },
    { id: 10, question: "Skillset to Profile Appropriation Score", answer: "" },
    { id: 11, question: "Stability Score", answer: "" },
    { id: 12, question: "Candidate CTC vs Budget CTC", answer: "" },
    { id: 13, question: "Reasons to change the job", answer: "" },
  ];

  const options = [
    { label: "Immediate", value: 0 },
    { label: 30, value: 30 },
    { label: 45, value: 45 },
    { label: 90, value: 90 },
  ];

  const [answers, setAnswers] = useState(questions_array);
  console.log(answers);

  const [noticePeriod, setNoticePeriod] = useState(null);
  console.log("noticePeriod: ", noticePeriod);

  const [experience, setExperience] = useState(null);
  console.log("experience: ", experience);
  const [budget, setBudget] = useState(null);
  const [reason, setReason] = useState(null);

  const [checkIsAnswered, setCheckIsAnswered] = useState(false)
  console.log(checkIsAnswered)

  let updatedData = answers;
  console.log("answers: ", answers);


  const [isEditable, setIsEditable] = useState(
    !(telephonicData?.interview_question_score?.length > 0)
  );

  const isFieldsDisabled =
    workflowSession.find((api) => api.tab_id === formId?.id) ||
    !privileges.includes("Telephonic Interview Create");

  const isDisabled =
    !isEditable ||
    isFieldsDisabled; // Combine with isFieldsDisabled logic


  const [formSubmition, setFormSubmition] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
  } = useForm();

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleFormSubmit = (data) => {
    if (formSubmition) {
      handleSubmit(onSubmit)(data);
    } else {
      console.log("next level")
      nextLevel();
    }
  };

  const onSubmit = (data) => {
    console.log("submit telephonic");
    console.log(telephonicData);
    console.log(data);
    if (checkIsAnswered) {
      return;
    } else {
      let hr_telephonic = JSON.stringify({
        requisition_id: requisition_id,
        candidate_job_id: candidate_id,
        candidate_interview_id: telephonicData?.interview_details?.id,
        interview_details: answers,
        created_by_id: userID,
        workflow_session: {
          tab_id: formId?.id,
          workflow_id: formId?.WF,
          user: userID,
        },
      });

      console.log(hr_telephonic);

      var config = {
        method: "post",
        url: VARIABLES.url + `/api/hr-telephonic/`,
        headers: {
          "Content-Type": "application/json",
        },
        data: hr_telephonic,
      };

      setSubmitLoading(true);
      axios(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data) {
            setSubmitLoading(false);
            setSubmitMessage({
              type: "success",
              icon: "check",
              message: "Success",
            });
            nextLevel();
            getWorkflowSession(candidate_id, requisition_id);
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: error?.response?.data?.message || "Failed",
          });
        });
    }
  };

  const handleSelectAnswer = (answer, id) => {
    setFormSubmition(true);
    if (id === 4) {
      const updatedAnswers = answers.map((q) => {
        const value = answer.value;
        if (q.id === id) {
          return { ...q, answer: value, anwser_status: true };
        }
        return q;
      });
      setAnswers(updatedAnswers);
    } else if (id === 13 || id === 12) {
      const value = answer.target.value;
      const updatedAnswers = answers.map((q) => {
        if (q.id === id) {
          return { ...q, answer: value, anwser_status: true };
        }
        return q;
      });
      setAnswers(updatedAnswers);
      {
        id === 13 && setReason(answer.target.value);
      }
      {
        id === 6 && setExperience(answer.target.value);
      }
      {
        id === 12 && setBudget(answer.target.value);
      }
    } else if (id === 6) {
      const updatedAnswers = answers.map((q) => {
        if (q.id === id) {
          return {
            ...q,
            answer: answer.target.value,
            anwser_status: true,
          };
        }
        return q;
      });
      setAnswers(updatedAnswers);
    } else {
      const updatedAnswers = answers.map((q) => {
        if (q.id === id) {
          return { ...q, answer, anwser_status: true };
        }
        return q;
      });
      setAnswers(updatedAnswers);
    }
  };

  const handleOldData = () => {
    if (telephonicData?.interview_question_score) {
      console.log(telephonicData?.interview_question_score);
      telephonicData?.interview_question_score?.map((info, index) => {
        updatedData = [...updatedData];
        if (index <= 12) {
          console.log(index);
          updatedData[index] = {
            ...updatedData[index],
            answer: info?.answer,
            anwser_status: true,
          };
          setNoticePeriod(telephonicData?.interview_question_score[3]?.answer);
          setExperience(telephonicData?.interview_question_score[5]?.answer);
          setBudget(telephonicData?.interview_question_score[11]?.answer);
          setReason(telephonicData?.interview_question_score[12]?.answer);
          setAnswers(updatedData);
        }
      });
    }
    console.log(telephonicData);
  };

  useEffect(() => {
    const findIsAnswered = answers.some((info) => info.answer === '')
    setCheckIsAnswered(findIsAnswered)
  }, [answers])

  useEffect(() => {
    handleOldData();
  }, [telephonicData]);

  useEffect(() => {
    getTelephonicData(requisition_id, candidate_id);
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Telephonic Round</h3>
            {isTelLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("rc_update_provision") && (
            <div className="nk-block-head-content pe-2">
              {telephonicData?.interview_question_score?.length !== 0 && (
                <>
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      onClick={toggleEdit}
                    >
                      {isEditable ? "Cancel" : "Edit"}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {isTelLoading ? (
        <div></div>
      ) : (
        <>
          <div className="nk-block">
            <div className="card">
              <div className="card-inner">
                <form
                  onSubmit={handleSubmit(handleFormSubmit)}
                  autoComplete="off"
                >
                  <div className="row g-4">
                    {telephonicData?.interview_details ? (
                      <>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="nk-block-head-content">
                            <div className="h6">Six Days Working</div>
                            <div
                              className="btn-group mt-2 d-flex align-items-center"
                              aria-label="Basic example"
                            >
                              <a
                                href="javascript:void(0)"
                                id="button-forms-good1"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Good", 1);
                                  }
                                }}
                              >
                                {answers[0]?.answer === "Good" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                              <a
                                href="javascript:void(0)"
                                id="button-forms-okay1"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Okay", 1);
                                  }
                                }}
                              >
                                {answers[0]?.answer === "Okay" ? (
                                  <img
                                    src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  // <video
                                  //   // width="30"
                                  //   // height="30"
                                  //   controls
                                  //   autoPlay
                                  //   loop
                                  //   muted
                                  // >
                                  //   <source src={NeutralColorfillVideo} type="video/mp4" />
                                  //   Your browser does not support the video tag.
                                  // </video>
                                  <img
                                    src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>

                              <a
                                href="javascript:void(0)"
                                id="button-forms-bad1"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Bad", 1);
                                  }
                                }}
                              >
                                {answers[0]?.answer === "Bad" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="nk-block-head-content">
                            <div className="h6">
                              Saturday & Sunday Rotational
                            </div>
                            <div
                              className="btn-group mt-2 d-flex align-items-center"
                              aria-label="Basic example"
                            >
                              <a
                                href="javascript:void(0)"
                                id="button-forms-good2"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Good", 2);
                                  }
                                }}
                              >
                                {answers[1]?.answer === "Good" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                              <a
                                href="javascript:void(0)"
                                id="button-forms-okay2"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Okay", 2);
                                  }
                                }}
                              >
                                {answers[1]?.answer === "Okay" ? (
                                  <img
                                    src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                              <a
                                href="javascript:void(0)"
                                id="button-forms-bad2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Bad", 2);
                                  }
                                }}
                              >
                                {answers[1]?.answer === "Bad" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="nk-block-head-content">
                            <div className="h6">Location Suitability</div>
                            <div
                              className="btn-group mt-2 d-flex align-items-center"
                              aria-label="Basic example"
                            >
                              <a
                                href="javascript:void(0)"
                                id="button-forms-good3"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Good", 3);
                                  }
                                }}
                              >
                                {answers[2]?.answer === "Good" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>

                              <a
                                href="javascript:void(0)"
                                id="button-forms-okay3"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Okay", 3);
                                  }
                                }}
                              >
                                {answers[2]?.answer === "Okay" ? (
                                  <img
                                    src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                              <a
                                href="javascript:void(0)"
                                id="button-forms-bad3"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Bad", 3);
                                  }
                                }}
                              >
                                {answers[2]?.answer === "Bad" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="nk-block-head-content">
                            <div className="h6">Notice Period</div>
                            <Select
                              id="select-forms-notice"
                              options={options}
                              value={
                                noticePeriod !== null
                                  ? options.find(
                                    (option) => option.value === Number(noticePeriod)
                                  )
                                  : null
                              }
                              isDisabled={
                                !isEditable || isFieldsDisabled
                              }
                              onChange={(selectedOptions) => {
                                handleSelectAnswer(selectedOptions, 4);
                                setNoticePeriod(selectedOptions.value);
                              }}
                              placeholder="Select notice period"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="nk-block-head-content">
                            <div className="h6">Flexible Notice Period</div>
                            <div
                              className="btn-group mt-2 d-flex align-items-center"
                              aria-label="Basic example"
                            >
                              <a
                                href="javascript:void(0)"
                                id="button-forms-good5"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Good", 5);
                                  }
                                }}
                              >
                                {answers[4]?.answer === "Good" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>

                              <a
                                href="javascript:void(0)"
                                id="button-forms-okay5"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Okay", 5);
                                  }
                                }}
                              >
                                {answers[4]?.answer === "Okay" ? (
                                  <img
                                    src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                              <a
                                href="javascript:void(0)"
                                id="button-forms-bad5"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Bad", 5);
                                  }
                                }}
                              >
                                {answers[4]?.answer === "Bad" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="nk-block-head-content">
                            <div className="h6">
                              Relevant vs Required Experience
                            </div>
                            <div className="d-flex">
                              <input
                                className="form-control me-1"
                                type="text"
                                id="text-forms-relevantexp"
                                placeholder="Enter relevant experience"
                                value={experience}
                                onChange={(event) => {
                                  handleSelectAnswer(event, 6);
                                }}
                                disabled={
                                  !isEditable || isFieldsDisabled
                                }
                              />
                              <input
                                className="form-control"
                                type="text"
                                id="text-forms-requiredexp"
                                placeholder="Enter required experience"
                                value={
                                  telephonicData?.interview_details?.requisition
                                    ?.required_experience
                                }
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="nk-block-head-content">
                            <div className="h6">Relevant Exp. Score</div>
                            <div
                              className="btn-group mt-2 d-flex align-items-center"
                              aria-label="Basic example"
                            >
                              <a
                                href="javascript:void(0)"
                                id="button-forms-good7"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Good", 7);
                                  }
                                }}
                              >
                                {answers[6]?.answer === "Good" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>

                              <a
                                href="javascript:void(0)"
                                id="button-forms-okay7"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Okay", 7);
                                  }
                                }}
                              >
                                {answers[6]?.answer === "Okay" ? (
                                  <img
                                    src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                              <a
                                href="javascript:void(0)"
                                id="button-forms-bad7"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Bad", 7);
                                  }
                                }}
                              >
                                {answers[6]?.answer === "Bad" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="nk-block-head-content">
                            <div className="h6">
                              Technical Profile vs Skillsets
                            </div>
                            <div
                              className="btn-group mt-2 d-flex align-items-center"
                              aria-label="Basic example"
                            >
                              <a
                                href="javascript:void(0)"
                                id="button-forms-good8"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Good", 8);
                                  }
                                }}
                              >
                                {answers[7]?.answer === "Good" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>

                              <a
                                href="javascript:void(0)"
                                id="button-forms-okay8"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Okay", 8);
                                  }
                                }}
                              >
                                {answers[7]?.answer === "Okay" ? (
                                  <img
                                    src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                              <a
                                href="javascript:void(0)"
                                id="button-forms-bad8"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Bad", 8);
                                  }
                                }}
                              >
                                {answers[7]?.answer === "Bad" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="nk-block-head-content">
                            <div className="h6">
                              Relevant Academic Validation Score
                            </div>
                            <div
                              className="btn-group mt-2 d-flex align-items-center"
                              aria-label="Basic example"
                            >
                              <a
                                href="javascript:void(0)"
                                id="button-forms-good9"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Good", 9);
                                  }
                                }}
                              >
                                {answers[8]?.answer === "Good" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                              <a
                                href="javascript:void(0)"
                                id="button-forms-okay9"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Okay", 9);
                                  }
                                }}
                              >
                                {answers[8]?.answer === "Okay" ? (
                                  <img
                                    src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>

                              <a
                                href="javascript:void(0)"
                                id="button-forms-bad9"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Bad", 9);
                                  }
                                }}
                              >
                                {answers[8]?.answer === "Bad" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="nk-block-head-content">
                            <div className="h6">
                              Skillset to Profile Appropriation score
                            </div>
                            <div
                              className="btn-group mt-2 d-flex align-items-center"
                              aria-label="Basic example"
                            >
                              <a
                                href="javascript:void(0)"
                                id="button-forms-good10"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Good", 10);
                                  }
                                }}
                              >
                                {answers[9]?.answer === "Good" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>

                              <a
                                href="javascript:void(0)"
                                id="button-forms-okay10"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Okay", 10);
                                  }
                                }}
                              >
                                {answers[9]?.answer === "Okay" ? (
                                  <img
                                    src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                              <a
                                href="javascript:void(0)"
                                id="button-forms-bad10"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Bad", 10);
                                  }
                                }}
                              >
                                {answers[9]?.answer === "Bad" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="nk-block-head-content">
                            <div className="h6">Stability Score</div>
                            <div
                              className="btn-group mt-2 d-flex align-items-center"
                              aria-label="Basic example"
                            >
                              <a
                                href="javascript:void(0)"
                                id="button-forms-good11"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Good", 11);
                                  }
                                }}
                              >
                                {answers[10]?.answer === "Good" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-up.png")}
                                    alt="good"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                              <a
                                href="javascript:void(0)"
                                id="button-forms-okay11"
                                className="me-2"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Okay", 11);
                                  }
                                }}
                              >
                                {answers[10]?.answer === "Okay" ? (
                                  <img
                                    src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                    alt="okay"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                              <a
                                href="javascript:void(0)"
                                id="button-forms-bad11"
                                onClick={() => {
                                  if (!isDisabled) {
                                    handleSelectAnswer("Bad", 11);
                                  }
                                }}
                              >
                                {answers[10]?.answer === "Bad" ? (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../Resources/images/thumbs-down.png")}
                                    alt="Bad"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="nk-block-head-content">
                            <div className="h6">
                              Candidate CTC vs Budget CTC
                            </div>
                            <div className="d-flex">
                              <input
                                className="form-control me-1"
                                type="text"
                                id="text-forms-cordidates"
                                placeholder="Enter cordidates"
                                value={budget}
                                onChange={(event) => {
                                  handleSelectAnswer(event, 12);
                                }}
                                disabled={
                                  !isEditable || isFieldsDisabled
                                }
                              />
                              <input
                                className="form-control"
                                type="text"
                                id="text-forms-budget"
                                placeholder="Enter budget"
                                value={
                                  // (telephonicData?.interview_details
                                  //   ?.requisition?.min_salary || "-") +
                                  // "-" +
                                  (telephonicData?.interview_details
                                    ?.requisition?.max_salary || "-")
                                }
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="nk-block-head-content">
                            <div className="h6">Reason to change the job</div>
                            <textarea
                              className="form-control"
                              id="textarea-forms-reason"
                              value={reason}
                              placeholder="Reason to change the job"
                              {...register("reason", {
                                maxLength: {
                                  value: 3000,
                                  message:
                                    "Answer should not exceed 3000 characters",
                                },
                              })}
                              onChange={(event) => {
                                handleSelectAnswer(event, 13);
                              }}
                              disabled={
                                !isEditable || isFieldsDisabled
                              }
                            />
                            {errors.reason && (
                              <span className="fs-8 text-danger">
                                {errors.reason.message}
                              </span>
                            )}
                          </div>
                        </div>

                        {checkIsAnswered &&
                          <div className="col-12">
                            <span className="fs-6 text-danger">Please answer all questions.</span>
                          </div>
                        }

                        <div className="col-12 d-flex">
                          <div className="form-group">
                            {level !== 0 && (
                              <button
                                type="button"
                                className="btn btn-lg btn-primary me-3"
                                id="button-button-prevlevel"
                                onClick={() => prevLevel()}
                              >
                                Prev
                              </button>
                            )}
                          </div>
                          <div className="form-group">
                            {submitLoading ? (
                              <button className="btn text-dark" disabled>
                                Loading...
                              </button>
                            ) : (
                              <>
                                {sequenceList.length - 2 > level ? (
                                  telephonicData?.interview_details
                                    ?.interview_action ? (
                                    <button
                                      type="submit"
                                      className="btn btn-lg btn-primary"
                                      id="submit-button-telephonicround"
                                    // onClick={() => nextLevel()}
                                    >
                                      Next
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-lg btn-primary"
                                      id="submit-button-telephonicround"
                                    >
                                      Next
                                    </button>
                                  )
                                ) : telephonicData?.interview_details
                                  ?.interview_action ? (
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-primary"
                                    id="submit-button-telephonicround"
                                  // onClick={() => nextLevel()}
                                  >
                                    Submit
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-primary"
                                    id="submit-button-telephonicround"
                                  >
                                    Submit
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        HR telephonic interview is not scheduled for this
                        candidate.
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      <Snackbar />
    </>
  );
};

export default TelephonicRound;
