import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { MDApprovalContext } from "../../../Context/MDApprovalContext";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";
import { useLocation } from "react-router-dom";
import Message from "../Message/Message";

const MettleTestReview = ({ formId }) => {
  const location = useLocation();
  const candidate_id = location?.state?.candidate_id;
  const requisition_id = location?.state?.requisition_id;
  const job_opening_id = location?.state?.job_opening_id;

  console.log(formId); //data of the tab in which this form is present

  //message after submitting data
  const { setSubmitMessage } = useContext(SnackbarContext);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [filePreview, setFilePreview] = useState(null);

  // Secure local storage
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const privileges = secureLocalStorage.getItem("privileges");
  // const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  // console.log(candidate_job_id);
  // const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");

  // Candidate Exam / Interview data of particular candidates stores in "candidateExam"
  const {
    candidateExam,
    isCandidateExamLoading, getCandidateExamData } =
    useContext(MDApprovalContext);
  console.log(candidateExam);

  //Stores data for radio button (accept, reject and draft)
  const { interviewActionDropdownData } = useContext(TypemasterDropdownContext);
  console.log(interviewActionDropdownData);

  // Stepper (Prev/Next)
  const { level, sequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

  // To call workflow session after the POST/ PATCH request
  const { getWorkflowSession, workflowSession } = useContext(WorkflowContext);

  // const isFormFilled = workflowSession.find((api) => api.tab_id == formId?.id);
  const isFieldsDisabled =
    workflowSession.find((api) => api.tab_id === formId?.id) ||
    !privileges.includes("Test Review Create");

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const handleResumeChange = (files) => {
    const fileType = files[0]?.type;

    // Generate a preview URL for PDF files only
    if (fileType === "application/pdf") {
      setFilePreview(URL.createObjectURL(files[0]));
    } else if (
      fileType === "image/png" ||
      fileType === "image/jpeg") {
      // You can show an icon or message for Word documents as Word files cannot be directly previewed in the browser
      setFilePreview("png/jpeg document preview is not supported.");
    } else {
      setFilePreview(null);
    }
  };

  //Submission of Mettle test form
  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);

    let workflow_session = {
      tab_id: formId?.id,
      workflow_id: formId?.WF,
      user_id: userID,
    };

    let user = new FormData();

    user.append("candidate_job_id", candidate_id);
    user.append("requisition_id", requisition_id);
    user.append("upload_test", data.test_sheet);
    user.append("comments", data.comments);
    user.append("test_conducted_user_id", userID);
    user.append(
      "selection_status",
      data?.is_selected === "Draft"
        ? 0
        : data?.is_selected === "Accept"
          ? 1
          : data?.is_selected === "Reject"
            ? 2
            : null
    );
    user.append("updated_by_id", userID);
    user.append("workflow_session", JSON.stringify(workflow_session));

    var config = {
      method: "patch",
      url: VARIABLES.url + `/api/candidateexam/${candidateExam[0].id}/`,
      headers: {
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data) {
          getCandidateExamData(candidate_id, requisition_id);
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: data?.is_selected === "Draft" ? "Candidate drafted successfully" : data?.is_selected === "Reject" ? "Candidate rejected successfully" : "Psychometric test reviewed successfully",
          });
          getWorkflowSession(candidate_id, requisition_id);
          nextLevel();
          reset();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message:
            error?.response?.data?.message ||
            "Failed to review Psychometric test",
        });
      });
  };

  //Pre-populting data
  useEffect(() => {
    const editedData = candidateExam[0];
    console.log(editedData);
    if (editedData) {
      var defaultValues = {};
      defaultValues.comments = editedData.comments;
      defaultValues.is_selected =
        editedData?.selection_status === 0
          ? "Draft"
          : editedData?.selection_status === 1
            ? "Accept"
            : editedData?.selection_status === 2
              ? "Reject"
              : null;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    getCandidateExamData(candidate_id, requisition_id);
  }, []);

  const [workflowId, setWorkflowId] = useState(null);
  console.log(workflowSession);

  useEffect(() => {
    const filter_workflow_session = workflowSession.find(
      (api) => api.tab_id == formId?.id
    );
    console.log(filter_workflow_session);
    setWorkflowId(filter_workflow_session);
  }, [workflowSession]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Psychometric Test Review </h4>
            {isCandidateExamLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {formId?.is_iterable && formId?.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        {candidateExam[0]?.selection_status === 2 ?
          <Message icon={require("../../../../Resources/images/rejected.png")}
            message={"The candidate has been disqualified at this stage of the recruitment process."} />
          :
          <div className="card">
            <div className="card-inner">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row g-4">
                  {candidateExam[0]?.upload_test !== null ? (
                    <div className="profile-ud-item p-0">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">
                          View Test Result :
                        </span>
                        <span className="profile-ud-value align-start">
                          <a
                            className="btn btn-primary"
                            id="button-button-viewresume"
                            href={candidateExam[0]?.upload_test}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <em
                              className="icon ni ni-file-pdf"
                              style={{ color: "white" }}
                            ></em>
                          </a>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label className="form-label">
                        Upload Test Sheet <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name={`test_sheet`}
                        control={control}
                        rules={{
                          required: true,
                          validate: (value) => {
                            if (value) {
                              const allowedTypes = [
                                "application/pdf",
                                "image/png",
                                "image/jpeg",
                              ];
                              return allowedTypes.includes(value.type);
                            }
                            return false;
                          },
                        }}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            className="form-control"
                            id="file-forms-test_sheet"
                            type="file"
                            onChange={(e) => {
                              field.onChange(e.target.files[0]);
                              handleResumeChange(e.target.files);
                            }}
                            disabled={isFieldsDisabled}
                          />
                        )}
                      />
                      <div className="d-flex flex-column">
                        {errors.test_sheet &&
                          errors.test_sheet.type === "required" && (
                            <span className="fs-8 text-danger">
                              Test sheet is required
                            </span>
                          )}
                        {errors.test_sheet &&
                          errors.test_sheet.type === "validate" && (
                            <span className="fs-8 text-danger">
                              Please upload a document in PDF, PNG, JPG, or JPEG
                              format
                            </span>
                          )}
                        {candidateExam[0]?.upload_test && (
                          <span className="fs-8 pt-2">
                            Previously uploaded Test sheet :
                            <a
                              className="btn btn-primary ms-2"
                              id="button-button-viewresume"
                              href={candidateExam[0]?.upload_test}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <em
                                className="icon ni ni-file-pdf"
                                style={{ color: "white" }}
                              ></em>
                            </a>
                          </span>
                        )}
                        {filePreview && (
                          <a
                            className="btn btn-primary mt-2 ms-1"
                            href={filePreview}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Preview Uploaded File
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-lg-6 col-md-6 col-sm-12"></div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label className="form-label">
                      Comments
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="textarea-forms-comments"
                      placeholder="Enter Comments"
                      {...register("comments", { required: true })}
                      disabled={isFieldsDisabled || candidateExam[0]?.selection_status !== null && candidateExam[0]?.selection_status !== 0}
                    />
                    {errors.comments && (
                      <span className="fs-8 text-danger">
                        Comments are required
                      </span>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12"></div>

                  <div className="col-lg-12 col-md-6 col-sm-12">
                    <ul className="row g-3">
                      {interviewActionDropdownData.map((action, index) => (
                        <li className="col-lg-2 col-md-2 col-sm-2" key={index}>
                          <div className="custom-control custom-radio me-3">
                            <input
                              type="radio"
                              id={`checkbox-form-is-selected-mettle-${action.value.toLowerCase()}`}
                              name="resumeupload"
                              className="custom-control-input"
                              value={action.value}
                              {...register("is_selected", { required: true })}
                              disabled={isFieldsDisabled || candidateExam[0]?.selection_status !== null && candidateExam[0]?.selection_status !== 0}
                            />
                            <label
                              className="custom-control-label"
                              for={`checkbox-form-is-selected-mettle-${action.value.toLowerCase()}`}
                            >
                              {action.name}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                    {errors.is_selected && (
                      <span className="fs-8 text-danger">
                        Please select any one
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 mt-4">
                  <div className="form-group">
                    {level !== 0 && (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        id="button-button-prevlevel"
                        onClick={() => prevLevel()}
                      >
                        Prev
                      </button>
                    )}
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <>
                        {sequenceList.length - 2 > level ? (
                          workflowId?.is_complete === 1 ? (
                            <button
                              type="button"
                              className="btn btn-lg btn-primary"
                              id="submit-button-assignassignment"
                              onClick={() => nextLevel()}
                            >
                              Next
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-lg btn-primary"
                              id="submit-button-assignassignment"
                            >
                              Next
                            </button>
                          )
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-assignassignment"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        }
      </div>
      <Snackbar />
    </>
  );
};

export default MettleTestReview;
