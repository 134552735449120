import React, { useEffect, useState, useContext } from "react";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { ScheduleInterviewContext } from "../../Context/ScheduleInterviewContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";

const RCScheduledInterviews = () => {
  const userID = secureLocalStorage.getItem("userID");
  const [searchInterview, setSearchInterview] = useState("");
  console.log(searchInterview);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    RCScheduledInterviewData,
    getRCScheduledInterviewData,
    RCScheduledInterviewEntries,
    RCScheduledInterviewPageCount,
    setRCScheduledInterviewEntries,
    RCScheduledInterviewCount,
    handleRCScheduledInterviewClick,
    isRCScheduledInterviewData,
    isRCScheduledInterviewLoading,
  } = useContext(ScheduleInterviewContext);

  const { requisitionID } = useContext(TypemasterDropdownContext);

  const navigate = useNavigate();

  const handleEntries = (event) => {
    setRCScheduledInterviewEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchInterview(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getRCScheduledInterviewData(1, userID, searchInterview);
    } else {
      getRCScheduledInterviewData(1, userID);
      setSearchInterview("");
    }
  };

  const formatName = (name) => {
    if (!name) {
      return "-";
    } else {
      const parts = name.split(" ");
      console.log(parts);
      const firstName = parts[0]
        ? parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase()
        : "";
      const lastName = parts[1]
        ? parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase()
        : "";
      return `${firstName} ${lastName}`;
    }
  };

  useEffect(() => {
    getRCScheduledInterviewData(1, userID, searchInterview);
  }, [RCScheduledInterviewEntries]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">Scheduled Interview</h3>
            {isRCScheduledInterviewLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchInterview}
                      className="form-control"
                      placeholder="Search by Requisition ID (e.g., 126), Job title,
                      interview date (e.g., Jan 01, 2024),
                      candidate name,
                      interview type,
                      interview mode
                      "
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Requisition ID</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Candidate name</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Interview Type</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Interview Mode</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Interview Date-Time</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Google Link</span>
                      </div>
                    </div>

                    {RCScheduledInterviewData.length > 0 ? (
                      RCScheduledInterviewData.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="title">
                              {(requisitionID &&
                                requisitionID[0]?.name +
                                post?.requisition?.id) ||
                                "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.requisition?.job_opening?.job_title || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {formatName(
                                post?.candidate_job?.candidate_resume
                                  ?.candidate_name
                              )}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              {post?.interview_type || "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.interview_mode || "-"}
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.interview_datetime
                                ? moment(post?.interview_datetime).format(
                                  "MMM DD, YYYY h:mm a"
                                )
                                : "-"}
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.google_meet_link ? (
                                <a
                                  href={post?.google_meet_link}
                                  className=""
                                  target="_blank"
                                >
                                  Google Link
                                </a>
                              ) : (
                                "-"
                              )}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : isRCScheduledInterviewData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading.....</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    key={RCScheduledInterviewPageCount}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={RCScheduledInterviewPageCount}
                    marginPagesDisplayed={2}
                    onPageChange={(data) =>
                      handleRCScheduledInterviewClick(
                        data,
                        userID,
                        searchInterview
                      )
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {RCScheduledInterviewCount === null
                          ? 0
                          : RCScheduledInterviewCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={RCScheduledInterviewEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RCScheduledInterviews;
