import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";

const JobOpeningDetails = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);

  const {
    getRequestedRequisitionData,
  } = useContext(RequestedJobOpeningContext);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  let location = useLocation();
  console.log(location.state.editpost);
  const navigate = useNavigate();
  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");

  const AcceptRejectAlert = (is_accepted, post) => {
    Swal.fire({
      text: `Are you sure you want to ${is_accepted === 1 ? "accept" : "reject"
        } this requisition?`,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        handleAcceptReject(is_accepted, post);
      }
    });
  };

  const handleAcceptReject = (is_accepted, post) => {
    const access = secureLocalStorage.getItem("access");
    let date = new Date();
    console.log(date);
    console.log(post);
    var user = JSON.stringify({
      requisition_acceptance: is_accepted,
      requisition_acceptance_by_id: userID,
      requisition_acceptance_on: date,
      updated_by_id: userID,
    });

    var config = {
      method: "patch",
      url:
        VARIABLES.url +
        `/api/requestrequisition/${post.id}/?type=requisition_approval`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };

    console.log(user);

    setSubmitLoading(true)
    axios(config)
      .then((response) => {
        console.log(response)
        console.log(JSON.stringify(response.data));
        getRequestedRequisitionData(1, "", access);
        setSubmitLoading(false)
        onTATSubmit(response.data)
        Swal.fire({
          title: `Requisition ${is_accepted === 1 ? "accepted" : "rejected"}`,
          text: `${response.data?.message}`,
          icon: "warning",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        console.log(error);
        setSubmitLoading(false)
        if (error?.response?.status === 403) {
          RefreshToken();
        } else {
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: `Failed to ${is_accepted === 1 ? "accept" : "reject"
              } the requisition`,
          });
        }
      });
  };

  const onTATSubmit = (info) => {
    const user = {
      requisition_id: location.state?.editpost?.id,
      stage: "Requisition Approval",
      ...(info === "post"
        ? { created_by_id: userID }
        : { updated_by_id: userID }),
    };

    const userData = JSON.stringify(user);

    var config = {
      method: info === 'post' ? "post" : "patch",
      url: VARIABLES.url + "/api/requisition-activity-log/",
      headers: {
        "Content-Type": "application/json",
      },
      data: userData,
    };

    console.log(userData);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          RefreshToken();
        }
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 7000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-end">
          <div class="nk-block-head-content">
            <a
              className="btn btn-light d-none d-sm-inline-flex"
              id="button-back"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              id="button-back"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-aside-wrap">
              <div className="card-content">
                {/* <!-- .nav-tabs --> */}

                <div className="card-inner">
                  <div className="nk-block">
                    <div className="nk-block-head d-flex align-center">
                      <h5 className="title">
                        {location.state?.editpost?.job_opening?.job_title ||
                          "-"}
                      </h5>

                      {location.state?.status === 'acceptance' &&
                        <>
                          {privileges.includes(
                            "requisition_approval_update"
                          ) ? (
                            <div className="nk-tb-col">
                              {submitLoading ?
                                <div className="d-flex">
                                  <button
                                    className="btn btn-success ms-4"
                                    id="button-button-acceptresume"
                                    disabled
                                  >
                                    ACCEPT
                                    <em className="icon ni ni-check ms-1"></em>
                                  </button>

                                  <button
                                    className="btn btn-danger ms-4"
                                    id="button-button-rejectresume"
                                    disabled
                                  >
                                    REJECT
                                    <em className="icon ni ni-cross ms-1"></em>
                                  </button>
                                </div>
                                :
                                <div className="d-flex">
                                  <button
                                    onClick={(e) => {
                                      AcceptRejectAlert(1, location.state?.editpost);
                                      onTATSubmit("post");
                                    }}
                                    className="btn btn-success ms-4"
                                    id="button-button-acceptresume"
                                  >
                                    ACCEPT
                                    <em className="icon ni ni-check ms-1"></em>
                                  </button>

                                  <button
                                    onClick={(e) => {
                                      AcceptRejectAlert(2, location.state?.editpost);
                                      onTATSubmit("post");
                                    }}
                                    className="btn btn-danger ms-4"
                                    id="button-button-rejectresume"
                                  >
                                    REJECT
                                    <em className="icon ni ni-cross ms-1"></em>
                                  </button>
                                </div>
                              }
                            </div>
                          ) : (
                            " "
                          )}
                        </>
                      }

                      {submitLoading ? (
                        <div class="spinner-border text-primary ms-2" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <!-- .nk-block-head --> */}
                    <div className="profile-ud-list">
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Department</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.department
                              ?.department_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Team</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.team
                              ?.team_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Position</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.position
                              ?.position_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Seniority</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.seniority
                              ?.seniority_display_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Job Type</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_type || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Job Location</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_location || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Priority</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.priority || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            No of Vacancy
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.no_of_vacancy || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Required Experience
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.required_experience && location.state?.editpost?.required_experience + " years"}
                          </span>
                        </div>
                      </div>

                      {/* <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Minimum Salary
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.min_salary && location.state?.editpost?.min_salary}
                          </span>
                        </div>
                      </div> */}
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Maximum Salary
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.max_salary ? location.state?.editpost?.max_salary + " LPA" : '-'}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Deadline</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.deadline ? location.state?.editpost?.deadline + " days" : '-'}
                          </span>
                        </div>
                      </div>
                      {/* <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Depute Status</span>
                          <span className="profile-ud-value">{location.state?.editpost?.depute_status || '-'}</span>
                        </div>
                      </div> */}
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Workflow</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost?.job_opening?.workflow
                              ?.workflow_name || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Status</span>
                          <span className="profile-ud-value">
                            {location.state?.editpost
                              ?.requisition_acceptance !== null ? (
                              location.state.editpost.requisition_acceptance ===
                                0 ? (
                                <span className="badge bg-warning">
                                  Pending
                                </span>
                              ) : location.state.editpost
                                .requisition_acceptance === 1 ? (
                                <span className="badge bg-success">
                                  Accepted
                                </span>
                              ) : location.state.editpost
                                .requisition_acceptance === 2 ? (
                                <span className="badge bg-danger">
                                  Rejected
                                </span>
                              ) : location.state.editpost
                                .requisition_acceptance === 3 ? (
                                <span className="badge bg-secondary">
                                  Closed
                                </span>
                              ) : (
                                "N/A"
                              )
                            ) : (
                              "N/A"
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Reason to raise requisition
                          </span>
                          <span className="profile-ud-value">
                            {location.state?.editpost
                              ?.reason_to_raise_requisition || "-"}
                          </span>
                        </div>
                      </div>

                      {location.state?.editpost?.requisition_acceptance !== null && location.state?.editpost?.requisition_acceptance !== 0 &&
                        (
                          <>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  {location.state?.editpost?.requisition_acceptance === 2 ? "Rejected by" : "Approved by"}
                                </span>
                                <span className="profile-ud-value">
                                  {location.state?.editpost?.approval_by?.first_name[0].toUpperCase() +
                                    location.state?.editpost?.approval_by?.first_name.slice(1) +
                                    " " +
                                    location.state?.editpost?.approval_by?.last_name[0].toUpperCase() +
                                    location.state?.editpost?.approval_by?.last_name.slice(1)}
                                </span>
                              </div>
                            </div>
                            <div className="profile-ud-item">
                              <div className="profile-ud wider">
                                <span className="profile-ud-label">
                                  {location.state?.editpost?.requisition_acceptance === 2 ? "Rejected on" : "Approved on"}
                                </span>
                                <span className="profile-ud-value">
                                  {location.state?.editpost?.approval_on
                                    ? moment(location.state?.editpost?.approval_on).format(
                                      "MMM DD, YYYY h:mm a"
                                    )
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </>
                        )
                      }


                    </div>
                    {/* <!-- .profile-ud-list --> */}
                  </div>

                  {/* <!-- .nk-block --> */}
                  <div className="nk-divider divider md"></div>
                  <div className="nk-block">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                      <h5 className="title">Job Description</h5>
                    </div>
                    <div className="bq-note">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                location.state?.editpost?.job_opening
                                  ?.job_description || "-",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                      <h5 className="title">Job Requirement</h5>
                    </div>
                    {/* <!-- .nk-block-head --> */}
                    <div className="bq-note">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                location.state?.editpost?.job_opening
                                  ?.job_requirement || "-",
                            }}
                          />
                        </div>
                      </div>
                      {/* <!-- .bq-note-item --> */}
                    </div>
                    {/* <!-- .bq-note --> */}
                  </div>
                  <div className="nk-block">
                    <div className="nk-block-head nk-block-head-sm nk-block-between">
                      <h5 className="title">Comments</h5>
                    </div>
                    {/* <!-- .nk-block-head --> */}
                    <div className="bq-note">
                      <div className="bq-note-item">
                        <div className="bq-note-text">
                          <p>{location.state?.editpost?.comments || "-"} </p>
                        </div>
                      </div>
                      {/* <!-- .bq-note-item --> */}
                    </div>
                    {/* <!-- .bq-note --> */}
                  </div>
                </div>
                {/* <!-- .card-inner --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default JobOpeningDetails;
