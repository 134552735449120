import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { VARIABLES } from "../../../Constants";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { GenericDropdownContext } from "../../../Context/GenericDropdownContext";
import { MDApprovalContext } from "../../../Context/MDApprovalContext";
import Snackbar from "../../../Base/Snackbar";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import { JobAssignmentContext } from "../../../Context/JobAssignment";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";
// import { assignment } from '../../WorkflowStepperForm/StepperFormJson'

const AssignAssignment = ({ formId }) => {
  const location = useLocation();
  const candidate_id = location?.state?.candidate_id;
  const requisition_id = location?.state?.requisition_id;
  const job_opening_id = location?.state?.job_opening_id;

  console.log(formId); //data of the tab in which this form is present

  //message after submitting data
  const { setSubmitMessage } = useContext(SnackbarContext);

  // Secure Local Storage
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const privileges = secureLocalStorage.getItem("privileges");
  console.log("privileges: ", privileges)
  const role = secureLocalStorage.getItem("role");
  console.log("role: ", role)
  // const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  // console.log(candidate_job_id);
  // const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");
  // const job_opening_id = secureLocalStorage.getItem("WF_job_opening_id");

  // Stepper (PREV/NEXT)
  const {
    level,
    sequenceList,
    nextLevel,
    prevLevel,
    submitLoading,
    setSubmitLoading,
  } = useContext(SequenceContext);

  // To call workflow session after the POST/ PATCH request
  const { getWorkflowSession, workflowSession } = useContext(WorkflowContext);

  // const isFormFilled = workflowSession.find((api) => api.tab_id == formId?.id);
  const isFieldsDisabled =
    workflowSession.find((api) => api.tab_id === formId?.id) ||
    !privileges.includes("Assign Assignmnent Create");

  // Raw Data of assignment
  const {
    rawAssignment,
    getAssignmentUserDropdownData,
    isAssignmentUserLoading,
    assignmentUserDropdownData,
  } = useContext(JobAssignmentContext);

  console.log(workflowSession);

  // Assignment Dropdown details with value and label save as id and assignment_name
  const { isGenricDropdownDataLoading, jobAssignmentDropdownData } = useContext(
    GenericDropdownContext
  );
  console.log("jobAssignmentDropdownData: ", jobAssignmentDropdownData);

  // assignment details according to candidates
  const {
    assignment,
    isAssignmentDataLoading, getAssignmentData } =
    useContext(MDApprovalContext); //gets the data on behalf of particular candidate
  console.log(assignment);

  // "changeAssignment" stores the selected assigned to preview the link
  const [changedAssignment, setChangedAssignment] = useState(null); //state to manage selected assignment
  console.log(changedAssignment);

  // "filterAssignmentDropdownData" contains the assignments that are for particular requsition
  const [filteredAssignmentDropdownData, setFilteredAssignmentDropdownData] = useState([]);

  const [isEditable, setIsEditable] = useState(!assignment?.start_date);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
    control,
    reset,
    setValue,
    watch,
  } = useForm();

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const today = new Date().toISOString().split("T")[0];
  const startDate = watch("start_date");
  const endDate = watch("end_date");

  const handleFormSubmit = (data) => {
    if (isDirty) {
      handleSubmit(onSubmit)(data);
    } else {
      nextLevel();
    }
  };

  // On "handleAssignmentChange" filters the assignemnet that is selected and stores in "changeAssignment" to preview the link
  const handleAssignmentChange = (newChangedAssignment) => {
    if (newChangedAssignment) {
      console.log(newChangedAssignment);
      const selectedNewAssignment = rawAssignment.find(
        (data) => data.id === newChangedAssignment.value
      );
      setChangedAssignment(selectedNewAssignment);
    } else {
      setValue("choose_assignment", "");
      setChangedAssignment(null);
    }
  };

  const assignmentOptionCondition = () => {
    return "No assignment under the selected requistion";
  };

  //Submission of Assignment form
  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    let workflow_session = {
      tab_id: formId?.id,
      workflow_id: formId?.WF,
      user_id: userID,
    };

    if (assignment?.extend_request) {
      let user = new FormData();
      user.append("candidate_job_id", candidate_id);
      user.append("requisition_id", requisition_id);
      user.append("extend_request", true);
      user.append("extended_end_date", data?.extend_end_date);
      user.append("updated_by_id", userID);
      user.append("workflow_session", JSON.stringify(workflow_session));

      const url = VARIABLES.url + `/api/assignment/${assignment.id}/`;
      console.log(url);

      let config = {
        method: "patch",
        maxBodyLength: Infinity,
        headers: {
          Authorization: `Bearer ${access}`,
        },
        url: url,
        data: user,
      };

      for (var pair of user.entries()) {
        console.log(pair);
      }

      setSubmitLoading(true);
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data) {
            getAssignmentData(candidate_id, requisition_id);
            getWorkflowSession(candidate_id, requisition_id);
            setSubmitLoading(false);
            setSubmitMessage({
              type: "success",
              icon: "check",
              message: "Date extended successfully",
            });
            nextLevel();
            reset();
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message:
              error?.response?.data?.message || "Failed to extend date",
          });
        });
    } else {
      let user = new FormData();
      user.append("candidate_job_id", candidate_id);
      user.append("choose_assignment_id", data?.choose_assignment.value);
      user.append("assignment_conducted_user_id", data?.review_user?.map((info) => info.value));
      user.append("requisition_id", requisition_id);
      user.append("start_date", data?.start_date);
      user.append("end_date", data?.end_date);
      user.append("created_by_id", userID);
      user.append("workflow_session", JSON.stringify(workflow_session));

      const url = VARIABLES.url + "/api/assignment/";
      console.log(url);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        headers: {
          Authorization: `Bearer ${access}`,
        },
        url: url,
        data: user,
      };

      for (var pair of user.entries()) {
        console.log(pair);
      }

      setSubmitLoading(true);
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data) {
            getAssignmentData(candidate_id, requisition_id);
            getWorkflowSession(candidate_id, requisition_id);
            setSubmitLoading(false);
            setSubmitMessage({
              type: "success",
              icon: "check",
              message: "Assignment uploaded successfully",
            });
            nextLevel();
            reset();
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message:
              error?.response?.data?.message || "Failed to upload assignment",
          });
        });
    }
  };

  // Filter the assignemnet according to the job opening
  useEffect(() => {
    const filteringAssignmentDropdownData = jobAssignmentDropdownData?.filter(
      (info) => info?.job_opening_pk == job_opening_id
    );
    setFilteredAssignmentDropdownData(filteringAssignmentDropdownData);
    console.log(filteringAssignmentDropdownData);
  }, [jobAssignmentDropdownData]);

  //Pre-populating Assignment data
  useEffect(() => {
    const editedData = assignment;
    console.log(editedData);
    if (editedData) {
      var defaultValues = {};
      const getAssignment = {
        value: editedData?.choose_assignment?.id,
        label: editedData?.choose_assignment?.assignment_title,
      };
      defaultValues.choose_assignment = getAssignment;

      if (
        editedData?.assignment_conducted_user &&
        editedData?.assignment_conducted_user?.length > 0
      ) {
        const getAssignmentConductedUser =
          editedData?.assignment_conducted_user?.map((user) => ({
            value: user.id,
            label: `${user?.first_name || "-"} ${user?.last_name || "-"}`,
          }));
        defaultValues.review_user = getAssignmentConductedUser;
      }

      if (editedData?.start_date) {
        defaultValues.start_date = editedData.start_date;
      }
      if (editedData?.end_date) {
        defaultValues.end_date = editedData.end_date;
      }
      if (editedData?.extended_end_date) {
        defaultValues.extend_end_date = editedData.extended_end_date;
      }

      reset(defaultValues);
    }
  }, [assignment]);

  useEffect(() => {
    getAssignmentUserDropdownData(candidate_id);
    getAssignmentData(candidate_id, requisition_id);
  }, []);

  const [workflowId, setWorkflowId] = useState(null);
  console.log(workflowSession);

  useEffect(() => {
    const filter_workflow_session = workflowSession.find(
      (api) => api.tab_id == formId?.id
    );
    console.log(filter_workflow_session);
    setWorkflowId(filter_workflow_session);
  }, [workflowSession]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Assign Assignment </h4>
            {isAssignmentDataLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("rc_update_provision") && (
            <div className="nk-block-head-content pe-2">
              {assignment?.start_date && (
                <>
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      onClick={toggleEdit}
                    >
                      {isEditable ? "Cancel" : "Edit"}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {formId?.is_iterable && formId?.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
              <div className="row g-4 ">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="profile-ud-item p-0">
                    <div className="profile-ud wider">
                      <span className="profile-ud-label">
                        View Assignment :
                      </span>
                      <span className="profile-ud-value align-start">
                        {changedAssignment ? (
                          <a
                            className="btn btn-primary"
                            href={
                              changedAssignment?.choose_assignment
                                ?.assignment_document
                            }
                            target="_blank"
                          >
                            <em
                              className="icon ni ni-file-pdf"
                              style={{ color: "white" }}
                            ></em>
                          </a>
                        ) : assignment?.choose_assignment
                          ?.assignment_document ? (
                          <a
                            className="btn btn-primary"
                            href={
                              assignment?.choose_assignment?.assignment_document
                            }
                            target="_blank"
                          >
                            <em
                              className="icon ni ni-file-pdf"
                              style={{ color: "white" }}
                            ></em>
                          </a>
                        ) : (
                          <div>No File Selected</div>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Choose Assignment
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={filteredAssignmentDropdownData?.map(
                          (assignment) => ({
                            label: assignment.assignment_title,
                            value: assignment.id,
                          })
                        )}
                        noOptionsMessage={assignmentOptionCondition}
                        onChange={(selectedOption) => {
                          handleAssignmentChange(selectedOption);
                          field.onChange(selectedOption);
                        }}
                        isLoading={isGenricDropdownDataLoading}
                        isClearable={true}
                        id="select-forms-choose_assignment"
                        isDisabled={isFieldsDisabled}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="choose_assignment"
                    control={control}
                  />
                  {errors.choose_assignment && (
                    <span className="fs-8 text-danger">
                      Assignment is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Access to review assignment
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={assignmentUserDropdownData?.map((info) => ({
                          value: info?.id,
                          label: `${info?.first_name} ${info?.last_name}`,
                        }))}
                        isMulti
                        isLoading={isAssignmentUserLoading}
                        isClearable={true}
                        id="select-forms-review_user"
                        isDisabled={isFieldsDisabled}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="review_user"
                    control={control}
                  />
                  {errors.review_user && (
                    <span className="fs-8 text-danger">
                      Please select user to review assignment
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Start Date</label>
                    <span className="text-danger"> *</span>
                    <input
                      type="date"
                      className="form-control"
                      id="text-forms-start_date"
                      placeholder="Describe your event title"
                      {...register("start_date", { required: "true" })}
                      min={today}
                      disabled={isFieldsDisabled}
                    />
                    {errors.start_date &&
                      errors.start_date.type === "required" && (
                        <span className="fs-8 text-danger">
                          Start date is required
                        </span>
                      )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">End Date</label>
                    <span className="text-danger"> *</span>
                    <input
                      type="date"
                      className="form-control"
                      id="text-forms-end_date"
                      placeholder="Describe your event title"
                      {...register("end_date", {
                        required: "End date is required",
                        validate: (value) => {
                          if (value < startDate) {
                            return "End date cannot be earlier than start date";
                          }
                          return true;
                        },
                      })}
                      min={startDate || today}
                      disabled={isFieldsDisabled}
                    />
                    {errors.end_date && (
                      <span className="fs-8 text-danger">
                        {errors.end_date.message}
                      </span>
                    )}
                  </div>
                </div>

                {assignment?.extended_end_date !== null && (
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="profile-ud-item w-100 p-0">
                      <div className="profile-ud wider">
                        <span className="profile-ud-label">Extended End date :</span>
                        <span className="profile-ud-value">
                          {moment(
                            assignment?.extended_end_date
                          ).format("MMM DD, YYYY h:mm a")}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {assignment?.extend_request && (
                  role.some(role => role.role_name === "RC") && (
                    <>
                      <div
                        class="alert alert-warning d-flex align-items-center"
                        style={{
                          margin: "10px",
                          height: "20px",
                          marginTop: "2rem",
                        }}
                      >
                        <em
                          className="icon ni ni-alert-circle fs-4 me-2"
                          style={{ color: "orange" }}
                        ></em>
                        <div>
                          Candidate missed the deadline and has requested an
                          extension.
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mt-0">
                        <div className="form-group">
                          <label className="form-label">Extend End Date</label>
                          <span className="text-danger"> *</span>
                          <input
                            type="date"
                            className="form-control"
                            id="text-forms-extend_end_date"
                            placeholder="Describe your event title"
                            {...register("extend_end_date", {
                              required: "End date is required",
                              validate: (value) => {
                                if (value < endDate) {
                                  return "Extended end date cannot be earlier than the previous end date";
                                }
                                return true;
                              },
                            })}
                            min={endDate || today}
                            disabled={assignment?.extended_end_date || !privileges.includes("Assign Assignmnent Create")}
                          />
                          {errors.extend_end_date && (
                            <span className="fs-8 text-danger">
                              {errors.extend_end_date.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  )
                )}

                <div className="col-12 d-flex">
                  <div className="form-group">
                    {level !== 0 && (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        id="button-button-prevlevel"
                        onClick={() => prevLevel()}
                      >
                        Prev
                      </button>
                    )}
                  </div>
                  <div className="form-group">
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <>
                        {sequenceList.length - 2 > level ? (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="button-button-nextlevel"
                          >
                            Next
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-assignassignment"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Snackbar />
        {/* Submit message */}
      </div>
    </>
  );
};

export default AssignAssignment;
