import React, { useContext, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";
import { useLocation } from "react-router-dom";
import { CandidateEntriesContext } from "../../../Context/CandidateEntriesContext";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";

const ProfessionalDetails = () => {
  const { isAccess } = useContext(RefreshTokenContext);
  const location = useLocation();
  const { sequenceList, level, prevLevel, nextLevel } =
    useContext(SequenceContext);

  const {
    getCandidateOtherData,
    candidateOtherDetails,
    isDetailsLoading,
    isDetailsData,
  } = useContext(CandidateEntriesContext);

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getCandidateOtherData(location?.state?.candidate_id, location?.state?.requisition_id, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getCandidateOtherData(location?.state?.candidate_id, location?.state?.requisition_id, access);
    }
  }, [location, isAccess]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title">Candidate's Professional Details</h4>
            {isDetailsLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="row g-3">
          {!isDetailsLoading && (
            <>
              {candidateOtherDetails.length !== 0 && candidateOtherDetails?.professional_details?.length > 0 ? (
                candidateOtherDetails?.professional_details[0]?.previously_worked === "No" ?
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    The candidate does not have prior professional experience.
                  </div>
                  :
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="profile-ud-list">
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Current/Previous Company
                          </span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.other_details?.[0]?.current_previous_company || "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Notice Period</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.other_details?.[0]?.notice_period
                              ? candidateOtherDetails?.other_details?.[0]
                                ?.notice_period +
                              " " +
                              "days"
                              : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Last Drawn CTC</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.other_details?.[0]
                              ?.current_salary !== null
                              ? candidateOtherDetails?.other_details?.[0]
                                ?.current_salary +
                              " " +
                              "lakhs per annum"
                              : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">Expected CTC</span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.other_details?.[0]
                              ?.expected_salary !== null
                              ? candidateOtherDetails?.other_details?.[0]
                                ?.expected_salary +
                              " " +
                              "lakhs per annum"
                              : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Total Work Experience
                          </span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.other_details?.[0]?.experience
                              ? candidateOtherDetails?.other_details?.[0]?.experience +
                              " " +
                              "years"
                              : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-ud-item">
                        <div className="profile-ud wider">
                          <span className="profile-ud-label">
                            Total Relevant Experience
                          </span>
                          <span className="profile-ud-value">
                            {candidateOtherDetails?.other_details?.[0]
                              ?.relevant_experience
                              ? candidateOtherDetails?.other_details?.[0]
                                ?.relevant_experience +
                              " " +
                              "years"
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="border-bottom my-4" />
                    {candidateOtherDetails?.professional_details?.length > 0 &&
                      candidateOtherDetails?.professional_details?.map(
                        (prof, index) => (
                          <>
                            <div className="py-4 border-css">
                              <div className="profile-ud-list">
                                {/* <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">
                                      Previously Worked
                                    </span>
                                    <span className="profile-ud-value">
                                      {prof?.previously_worked || "-"}
                                    </span>
                                  </div>
                                </div> */}
                                {prof?.previously_worked !== "No" && (
                                  <>
                                    <div className="profile-ud-item">
                                      <div className="profile-ud wider">
                                        <span className="profile-ud-label">
                                          Designation
                                        </span>
                                        <span className="profile-ud-value">
                                          {prof?.profile_name || "-"}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="profile-ud-item">
                                      <div className="profile-ud wider">
                                        <span className="profile-ud-label">
                                          Organization Name
                                        </span>
                                        <span className="profile-ud-value">
                                          {prof?.organization_name || "-"}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="profile-ud-item">
                                      <div className="profile-ud wider">
                                        <span className="profile-ud-label">
                                          Date of Joining
                                        </span>
                                        <span className="profile-ud-value">
                                          {prof?.start_date || "-"}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="profile-ud-item">
                                      <div className="profile-ud wider">
                                        <span className="profile-ud-label">
                                          Last Working Day
                                        </span>
                                        <span className="profile-ud-value">
                                          {prof?.end_date || "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        )
                      )}
                  </div>
              ) : (
                <div className="nk-tb-item">
                  <div className="nk-tb-col">
                    <span className="tb-product">
                      <span className="title">No Professional Information found.</span>
                    </span>
                  </div>
                </div>
              )}
            </>
          )}

          <div className="col-12">
            <div className="form-group">
              {level !== 0 && (
                <button
                  type="button"
                  className="btn btn-lg btn-primary me-3"
                  id="button-button-prevlevel"
                  onClick={() => prevLevel()}
                >
                  Prev
                </button>
              )}
              {sequenceList.length - 2 > level ? (
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  id="submit-button-nextlevel"
                  onClick={() => nextLevel()}
                >
                  Next
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-lg btn-primary"
                  id="submit-button-nextlevel"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessionalDetails;
