import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { TAMRE1RE2RE3Context } from "../../Context/TAMRE1RE2RE3Context";
import Swal from "sweetalert2";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import moment from "moment";

const RequisitionAssignedRE = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");
  const navigate = useNavigate();
  let location = useLocation();
  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { getTamData } = useContext(TAMRE1RE2RE3Context);
  const {
    RE2PortalsDropdownData,
    isDropdownDataLoading,
    priorityDropdownData,
    RECollegesDropdownData,
  } = useContext(TypemasterDropdownContext);
  const {
    isGenricParamsDropdownDataLoading,
    usersDropdownData,
    agencyNameDropdownData,
  } = useContext(GenericDropdownContext);

  console.log(location.state?.editpost);
  console.log("RE2PortalsDropdownData: ", RE2PortalsDropdownData);
  console.log("agencyNameDropdownData: ", agencyNameDropdownData);
  console.log("RECollegesDropdownData: ", RECollegesDropdownData);
  console.log("usersDropdownData: ", usersDropdownData);

  const RE1UsersData = usersDropdownData?.filter((info) =>
    info.role?.find((role) => role.role_name === "RE1")
  );
  console.log(RE1UsersData);
  const RE2UsersData = usersDropdownData?.filter((info) =>
    info.role?.find((role) => role.role_name === "RE2")
  );
  const RE3UsersData = usersDropdownData?.filter((info) =>
    info.role?.find((role) => role.role_name === "RE3")
  );
  const RCUsersData = usersDropdownData?.filter((info) =>
    info.role?.find((role) => role.role_name?.includes("RC"))
  );
  const SMEUsersData = usersDropdownData?.filter((info) =>
    info.role?.find((role) =>
      role.role_name?.includes("SME") || role.role_name?.includes("Departmental Manager")
    )
  );
  console.log("SMEUsersData: ", SMEUsersData)

  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const jobDescription =
    location?.state?.editpost?.job_opening?.job_description || "";
  const shortDescription =
    jobDescription.length > 325
      ? `${jobDescription.substring(0, 325)} . . .`
      : jobDescription;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  // const handleSubmitAlert = (data) => {
  //   console.log(data)
  //   if (data.re1_users?.length > 0) {
  //     if (!data.re1_manage_internally && (!data.re1_college || data.re1_college.length === 0)) {
  //       Swal.fire({
  //         text: "Please assign action to RE1.",
  //         icon: "warning",
  //         confirmButtonText: "Ok",
  //       });
  //       return;
  //     }
  //   }

  //   if (data.re2_users?.length > 0) {
  //     if (!data.re2_manage_internally && (!data.re2_portals || data.re2_portals.length === 0)) {
  //       Swal.fire({
  //         text: "Please assign action to RE2.",
  //         icon: "warning",
  //         confirmButtonText: "Ok",
  //       });
  //       return;
  //     }
  //   }

  //   if (data.re3_users?.length > 0) {
  //     if (!data.re3_manage_internally && (!data.re3_agency || data.re3_agency.length === 0)) {
  //       Swal.fire({
  //         text: "Please assign action to RE3.",
  //         icon: "warning",
  //         confirmButtonText: "Ok",
  //       });
  //       return;
  //     }
  //   }

  //   if (data.re1_users?.length === 0 && data.re2_users?.length === 0 && data.re3_users?.length === 0) {
  //     Swal.fire({
  //       text: "Please select at least one RE.",
  //       icon: "warning",
  //       confirmButtonText: "Ok",
  //     });
  //     return;
  //   }

  //   if (data.re1_manage_internally || (data.re1_college || data.re1_college.length !== 0)) {
  //     if (data.re1_users?.length === 0) {
  //       Swal.fire({
  //         text: "Please select RE1.",
  //         icon: "warning",
  //         confirmButtonText: "Ok",
  //       });
  //     }
  //   }

  //   if (data.re2_manage_internally || (data.re2_portals || data.re2_portals.length !== 0)) {
  //     if (data.re2_users?.length === 0) {
  //       Swal.fire({
  //         text: "Please select RE2.",
  //         icon: "warning",
  //         confirmButtonText: "Ok",
  //       });
  //     }
  //   }

  //   if (data.re3_manage_internally || (data.re3_agency || data.re3_agency.length !== 0)) {
  //     if (data.re3_users?.length === 0) {
  //       Swal.fire({
  //         text: "Please select RE3.",
  //         icon: "warning",
  //         confirmButtonText: "Ok",
  //       });
  //     }
  //   }
  //   else {
  //     onSubmit(data);
  //   }
  // };

  const handleSubmitAlert = (data) => {
    // Check for each RE to ensure actions are assigned appropriately
    const checkReActions = (reUsers, reManageInternally, reField, reFieldLabel) => {
      if (reUsers?.length > 0) {
        if (!reManageInternally && (!reField || reField.length === 0)) {
          Swal.fire({
            text: `Please assign action for ${reFieldLabel}.`,
            icon: "warning",
            confirmButtonText: "Ok",
          });
          return false;
        }
      }
      return true;
    };

    if (
      !checkReActions(data.re1_users, data.re1_manage_internally, data.re1_college, "college outreach") ||
      !checkReActions(data.re2_users, data.re2_manage_internally, data.re2_portals, "digital outreach") ||
      !checkReActions(data.re3_users, data.re3_manage_internally, data.re3_agency, "agency outreach")
    ) {
      return;
    }

    // Check if at least one RE is selected
    if (data.re1_users?.length === 0 && data.re2_users?.length === 0 && data.re3_users?.length === 0) {
      Swal.fire({
        text: "Please select at least one RE.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }

    // Check each RE to ensure users are selected if actions are assigned or comments are provided
    const checkReUsers = (reManageInternally, reField, reUsers, reLabel, reComments) => {
      if (reManageInternally || (reField && reField.length !== 0) || (reComments && reComments.length !== 0)) {
        if (reUsers?.length === 0) {
          Swal.fire({
            text: `Please select ${reLabel}.`,
            icon: "warning",
            confirmButtonText: "Ok",
          });
          return false;
        }
      }
      return true;
    };

    if (
      !checkReUsers(data.re1_manage_internally, data.re1_college, data.re1_users, "user for college outreach", data.re1_comments) ||
      !checkReUsers(data.re2_manage_internally, data.re2_portals, data.re2_users, "user for digital outreach", data.re2_comments) ||
      !checkReUsers(data.re3_manage_internally, data.re3_agency, data.re3_users, "user for agency outreach", data.re3_comments)
    ) {
      return;
    }

    onSubmit(data);
  };



  const onSubmit = (data) => {
    console.log(data);

    var user = JSON.stringify({
      priority: data.priority.value,
      re1_userID: Array.isArray(data.re1_users)
        ? data.re1_users?.map((info) => info.value)
        : null,
      re1_execute: data.re1_users.length > 0 ? true : false,
      re1_manage_internally: data.re1_manage_internally,
      re1_whatsapp: data.re1_whatsapp,
      re1_comments: data.re1_comments,
      re1_college:
        Array.isArray(data.re1_college) && data.re1_college.length > 0
          ? data.re1_college.map((info) => info.value)
          : null,
      re2_userID: Array.isArray(data.re2_users)
        ? data.re2_users?.map((info) => info.value)
        : null,
      re2_execute: data.re2_users.length > 0 ? true : false,
      re2_manage_internally: data.re2_manage_internally,
      re2_whatsapp: data.re2_whatsapp,
      re2_comments: data.re2_comments,
      re2_portals:
        Array.isArray(data.re2_portals) && data.re2_portals.length > 0
          ? data.re2_portals.map((info) => info.value)
          : null,
      re3_userID: Array.isArray(data.re3_users)
        ? data.re3_users?.map((info) => info.value)
        : null,
      re3_execute: data.re3_users.length > 0 ? true : false,
      re3_manage_internally: data.re3_manage_internally,
      re3_whatsapp: data.re3_whatsapp,
      re3_comments: data.re3_comments,
      re3_agency:
        Array.isArray(data.re3_agency) && data.re3_agency.length > 0
          ? data.re3_agency?.map((info) => info.value)
          : null,
      rc_userID: data.rc_userID?.map((info) => info.value),
      rc_execute: data.rc_userID.length > 0 ? true : false,
      sme_userID: data.sme_userID?.map((info) => info.value),
      sme_execute: data.sme_userID.length > 0 ? true : false,
      updated_by_id: userID,
    });

    var config = {
      method: "patch",
      url:
        VARIABLES.url +
        `/api/requestrequisition/${location.state?.editpost?.id}/?type=assign_re`,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        onTATSubmit();
        Swal.fire({
          title: 'Success',
          text: `${response.data?.message}`,
          icon: "success",
          confirmButtonText: "Okay",
        }).then((result) => {
          if (result.isConfirmed) {
            getTamData();
            navigate(-1);
            reset();
          }
        });
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        if (error.response?.status === 404) {
          Swal.fire({
            text: `${error.response.data?.message}`,
            icon: "error",
            confirmButtonText: "Ok",
          });
        } else {
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed",
          });
        }
      });
  };

  const onTATSubmit = () => {
    var user = JSON.stringify({
      requisition_id: location.state?.editpost?.id,
      stage: "TAM Assignment",
      updated_by_id: userID
    });

    var config = {
      method: "patch",
      url: VARIABLES.url + "/api/requisition-activity-log/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          RefreshToken();
        }
      });
  };

  useEffect(() => {
    console.log(location.state.editpost);
    const editedJobPost = location.state.editpost;
    if (editedJobPost) {
      var defaultValues = {};
      // RE1
      defaultValues.re1_whatsapp = editedJobPost.re1_whatsapp;
      defaultValues.re1_manage_internally = editedJobPost.re1_manage_internally;
      defaultValues.re1_comments = editedJobPost.re1_comments;
      if (
        editedJobPost?.re1_college &&
        editedJobPost?.re1_college?.length > 0
      ) {
        const getRE1College = editedJobPost?.re1_college?.map((info) => ({
          value: info,
          label: info,
        }));
        defaultValues.re1_college = getRE1College;
      }
      if (editedJobPost?.re1_users && editedJobPost?.re1_users?.length > 0) {
        const getRE1Users = editedJobPost?.re1_users?.map((user) => ({
          value: user.id,
          label: `${user?.first_name || "-"} ${user?.last_name || "-"}`,
        }));
        defaultValues.re1_users = getRE1Users;
      }

      //RE2
      defaultValues.re2_whatsapp = editedJobPost.re2_whatsapp;
      defaultValues.re2_manage_internally = editedJobPost.re2_manage_internally;
      defaultValues.re2_comments = editedJobPost.re2_comments;
      if (
        editedJobPost?.re2_portals &&
        editedJobPost?.re2_portals?.length > 0
      ) {
        const getRE2Portals = editedJobPost?.re2_portals?.map((info) => ({
          value: info,
          label: info,
        }));
        defaultValues.re2_portals = getRE2Portals;
      }

      if (editedJobPost?.re2_users && editedJobPost?.re2_users?.length > 0) {
        const getRE2Users = editedJobPost?.re2_users?.map((user) => ({
          value: user.id,
          label: `${user?.first_name || "-"} ${user?.last_name || "-"}`,
        }));
        defaultValues.re2_users = getRE2Users;
      }

      //RE3
      defaultValues.re3_whatsapp = editedJobPost.re3_whatsapp;
      defaultValues.re3_manage_internally = editedJobPost.re3_manage_internally;
      defaultValues.re3_comments = editedJobPost.re3_comments;

      if (
        Array.isArray(editedJobPost?.re3_agency) &&
        editedJobPost?.re3_agency?.length > 0
      ) {
        const getRE3Portals = editedJobPost?.re3_agency?.map((info) => ({
          value: info,
          label: info,
        }));
        defaultValues.re3_agency = getRE3Portals;
      }

      if (editedJobPost?.re3_users && editedJobPost?.re3_users?.length > 0) {
        const getRE3Users = editedJobPost?.re3_users?.map((user) => ({
          value: user?.id,
          label: `${user?.first_name || "-"} ${user?.last_name || "-"}`,
        }));
        defaultValues.re3_users = getRE3Users;
        console.log(getRE3Users);
      }

      if (editedJobPost?.priority) {
        const getPriority = {
          value: editedJobPost.priority,
          label: editedJobPost.priority,
        };
        defaultValues.priority = getPriority;
      }

      // RC User
      if (editedJobPost?.rc_users && editedJobPost?.rc_users?.length > 0) {
        const getRCUsers = editedJobPost?.rc_users?.map((user) => ({
          value: user.id,
          label: `${user?.first_name || "-"} ${user?.last_name || "-"}`,
        }));
        defaultValues.rc_userID = getRCUsers;
      }

      // SME User
      if (editedJobPost?.sme_users && editedJobPost?.sme_users?.length > 0) {
        const getSMEUsers = editedJobPost?.sme_users?.map((user) => ({
          value: user.id,
          label: `${user?.first_name || "-"} ${user?.last_name || "-"}`,
        }));
        defaultValues.sme_userID = getSMEUsers;
      }

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 7000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm ">
        <div className="nk-block-head-content d-flex justify-content-between">
          <h4 className="nk-block-title"> Assign to RE </h4>
          <div>
            <a
              id="button-backbutton"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-backbutton"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner m-2 border-bottom">
            <div className="profile-ud-list w-100">
              <div className="d-flex">
                <div>
                  <div className="profile-ud-item" style={{ width: "100%" }}>
                    <div className="profile-ud wider">
                      <span className="profile-ud-label me-4">
                        Requisition ID
                      </span>
                      <span className="profile-ud-value">
                        FSC-R{location?.state?.editpost?.id}
                      </span>
                    </div>
                  </div>

                  <div className="profile-ud-item" style={{ width: "100%" }}>
                    <div className="profile-ud wider">
                      <span className="profile-ud-label me-4">Job Title</span>
                      <span className="profile-ud-value">
                        {location?.state?.editpost?.job_opening?.job_title ||
                          "-"}
                      </span>
                    </div>
                  </div>

                  <div className="profile-ud-item" style={{ width: "100%" }}>
                    <div className="profile-ud wider">
                      <span className="profile-ud-label me-4">Department</span>
                      <span className="profile-ud-value">
                        <NavLink
                          to={"/departmentrequisitions"}
                          state={{
                            editpost: location?.state?.editpost?.job_opening?.department
                              ?.id
                          }}
                        >
                          {location?.state?.editpost?.job_opening?.department
                            ?.department_display_name || "-"}
                        </NavLink>
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="profile-ud-item" style={{ width: "100%" }}>
                    <div className="profile-ud wider">
                      <span className="profile-ud-label me-4">Job Type</span>
                      <span className="profile-ud-value">
                        {location?.state?.editpost?.job_type}
                      </span>
                    </div>
                  </div>

                  <div className="profile-ud-item" style={{ width: "100%" }}>
                    <div className="profile-ud wider">
                      <span className="profile-ud-label me-4">Vacancies</span>
                      <span className="profile-ud-value">
                        {location?.state?.editpost?.no_of_vacancy}
                      </span>
                    </div>
                  </div>

                  <div className="profile-ud-item" style={{ width: "100%" }}>
                    <div className="profile-ud wider">
                      <span className="profile-ud-label me-4">Raised on</span>
                      <span className="profile-ud-value">
                        {location?.state?.editpost?.created_at
                          ? moment(location?.state?.editpost?.created_at).format(
                            "MMM DD, YYYY"
                          )
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-ud-item" style={{ width: "100%" }}>
                <div className="profile-ud wider">
                  <span className="profile-ud-label me-4">Summary</span>
                  <span className="profile-ud-value">
                    <p
                      className="m-0"
                      dangerouslySetInnerHTML={{
                        __html: showFullDescription
                          ? jobDescription
                          : shortDescription,
                      }}
                    />
                    {jobDescription.length > 325 && (
                      <button
                        className="badge bg-info"
                        onClick={toggleDescription}
                      >
                        {showFullDescription ? "show less" : "show more "}
                      </button>
                    )}
                  </span>
                </div>
              </div>

              <div className="profile-ud-item" style={{ width: "100%" }}>
                <div className="profile-ud wider flex-column">
                  <div className="d-flex">
                    <span className="profile-ud-label me-4">
                      Set Priority
                      <span className="fs-8 text-danger"> &nbsp;*</span>
                      &nbsp; :
                    </span>
                    <span>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={priorityDropdownData?.map((info) => ({
                              value: info.name,
                              label: info.name,
                            }))}
                            isClearable={true}
                            isLoading={isDropdownDataLoading}
                            id="select-forms-priority"
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name="priority"
                        control={control}
                      />
                    </span>
                  </div>
                  {errors.priority && (
                    <span className="fs-8 text-danger">
                      Priority is required
                    </span>
                  )}
                </div>
              </div>
              <div className="profile-ud-item" style={{ width: "100%" }}>
                <div className="profile-ud wider flex-column">
                  <div className="d-flex">
                    <span className="profile-ud-label me-4">
                      Assign RC
                      <span className="fs-8 text-danger">&nbsp;*</span>
                      &nbsp; :
                    </span>
                    <span style={{ width: "50%" }}>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={RCUsersData?.map((info) => ({
                              value: info.user.id,
                              label: `${info.user.first_name} ${info.user.last_name}`,
                            }))}
                            isClearable={true}
                            isLoading={isGenricParamsDropdownDataLoading}
                            isMulti
                            id="select-forms-rc_userID"
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name="rc_userID"
                        control={control}
                      />
                    </span>
                  </div>
                  {errors.rc_userID && (
                    <span className="fs-8 text-danger">
                      RC User is required
                    </span>
                  )}
                </div>
              </div>
              <div className="profile-ud-item" style={{ width: "100%" }}>
                <div className="profile-ud wider flex-column">
                  <div className="d-flex">
                    <span className="profile-ud-label me-4">
                      Assign SME
                      <span className="fs-8 text-danger">&nbsp;*</span>
                      &nbsp; :
                    </span>
                    <span style={{ width: "50%" }}>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={SMEUsersData?.map((info) => ({
                              value: info.user.id,
                              label: `${info.user.first_name} ${info.user.last_name}`,
                            }))}
                            isClearable={true}
                            isLoading={isGenricParamsDropdownDataLoading}
                            isMulti
                            id="select-forms-sme_userID"
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name="sme_userID"
                        control={control}
                      />
                    </span>
                  </div>
                  {errors.sme_userID && (
                    <span className="fs-8 text-danger">
                      RC User is required
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card-inner">
            <form onSubmit={handleSubmit(handleSubmitAlert)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <h5 className="titlesite mb-4">College Outreach</h5>
                  <div className="mb-4">
                    <label className="form-label">Choose User</label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={RE1UsersData?.map((info) => ({
                            value: info.user.id,
                            label: `${info.user.first_name} ${info.user.last_name}`,
                          }))}
                          isMulti
                          isClearable={true}
                          isLoading={isGenricParamsDropdownDataLoading}
                          id="select-forms-re1_users"
                        />
                      )}
                      defaultValue=""
                      name="re1_users"
                      control={control}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Choose College</label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={RECollegesDropdownData?.map((info) => ({
                            value: info.value,
                            label: info.name,
                          }))}
                          isMulti
                          isClearable={true}
                          isLoading={isDropdownDataLoading}
                          id="select-forms-re1_college"
                        />
                      )}
                      defaultValue=""
                      name="re1_college"
                      control={control}
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-6 p-1">
                      <div class="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="checkbox-forms-re1_manage_internally"
                          {...register("re1_manage_internally")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="re1_manage_internally"
                        >
                          Manage Internally
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 p-1">
                      <div class="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkbox-forms-re1_whatsapp"
                          {...register("re1_whatsapp")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="re1_whatsapp"
                        >
                          WhatsApp
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12">
                  <h5 className="titlesite mb-4">Digital Outreach</h5>
                  <div className="mb-4">
                    <label className="form-label">Choose User</label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={RE2UsersData?.map((info) => ({
                            value: info.user.id,
                            label: `${info.user.first_name} ${info.user.last_name}`,
                          }))}
                          isLoading={isGenricParamsDropdownDataLoading}
                          isClearable={true}
                          isMulti
                          id="select-forms-re2_users"
                        />
                      )}
                      defaultValue=""
                      name="re2_users"
                      control={control}
                    />
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Choose Portal</label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={RE2PortalsDropdownData?.map((info) => ({
                            value: info.name,
                            label: info.name,
                          }))}
                          isLoading={isDropdownDataLoading}
                          isClearable={true}
                          isMulti
                          id="select-forms-re2_portals"
                        />
                      )}
                      defaultValue=""
                      name="re2_portals"
                      control={control}
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-6 p-1">
                      <div class="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="1"
                          id="checkbox-forms-re2_manage_internally"
                          {...register("re2_manage_internally")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="re2_manage_internally"
                        >
                          Manage Internally
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 p-1">
                      <div class="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkbox-forms-re2_whatsapp"
                          {...register("re2_whatsapp")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="re2_whatsapp"
                        >
                          WhatsApp
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 col-sm-12">
                  <h5 className="titlesite mb-4">Agency Outreach</h5>
                  <div className="mb-4">
                    <label className="form-label">Choose User</label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={RE3UsersData?.map((info) => ({
                            value: info.user.id,
                            label: `${info.user.first_name} ${info.user.last_name}`,
                          }))}
                          isMulti
                          isClearable={true}
                          isLoading={isGenricParamsDropdownDataLoading}
                          id="select-forms-re3_users"
                        />
                      )}
                      defaultValue=""
                      name="re3_users"
                      control={control}
                    />
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Choose Agency</label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={agencyNameDropdownData?.map((info) => ({
                            value: info.agency_name,
                            label: info.agency_name,
                          }))}
                          isMulti
                          isClearable={true}
                          isLoading={isGenricParamsDropdownDataLoading}
                          id="select-forms-re3_agency"
                        />
                      )}
                      defaultValue=""
                      name="re3_agency"
                      control={control}
                    />
                  </div>

                  <div className="row">
                    <div className="col-lg-6 p-1">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="checkbox-forms-re3_manage_internally"
                          {...register("re3_manage_internally")}
                        />
                        <label
                          class="form-check-label"
                          htmlFor="re3_manage_internally"
                        >
                          Manage Internally
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 p-1">
                      <div class="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkbox-forms-re3_whatsapp"
                          {...register("re3_whatsapp")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="re3_whatsapp"
                        >
                          WhatsApp
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Comment</label>
                    <textarea
                      className="form-control"
                      id="textarea-forms-re1_comments"
                      placeholder="Enter comments"
                      {...register("re1_comments")}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Comment</label>
                    <textarea
                      className="form-control"
                      id="textarea-forms-re2_comments"
                      placeholder="Enter comments"
                      {...register("re2_comments")}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Comment</label>
                    <textarea
                      className="form-control"
                      id="textarea-forms-re3_comments"
                      placeholder="Enter comments"
                      {...register("re3_comments")}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-requisition_assigned"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RequisitionAssignedRE;
