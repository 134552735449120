import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RequisitionDetails = () => {
    const navigate = useNavigate();
    let location = useLocation();

    const requisitionDetails = location.state?.editpost
    console.log(requisitionDetails)

    return (
        <>
            <div className="nk-block-head nk-block-head-sm">
                <div class="nk-block-head-content d-flex justify-content-between">
                    <div></div>
                    <div>
                        <a
                            id="button-backbutton"
                            className="btn btn-light d-none d-sm-inline-flex"
                            onClick={() => navigate(-1)}
                        >
                            <em class="icon ni ni-arrow-left"></em>
                            <span>Back</span>
                        </a>
                        <a
                            id="button-backbutton"
                            className="btn btn-icon btn-light d-inline-flex d-sm-none"
                            onClick={() => navigate(-1)}
                        >
                            <em class="icon ni ni-arrow-left"></em>
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                        <div className="card-aside-wrap">
                            <div className="card-content">

                                <div className="card-inner">
                                    <div className="nk-block">
                                        <div className="nk-block-head">
                                            <h5 className="title">
                                                {requisitionDetails?.job_opening?.job_title ||
                                                    "-"}
                                            </h5>
                                        </div>
                                        <div className="profile-ud-list">
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">Job description</span>
                                                    <span className="profile-ud-value">
                                                        {requisitionDetails?.job_description_exists !== null ? (
                                                            requisitionDetails
                                                                ?.job_description_exists === 0 ? (
                                                                <span className="badge bg-warning">
                                                                    Pending
                                                                </span>
                                                            ) : requisitionDetails
                                                                ?.job_description_exists === 1 ? (
                                                                <span className="badge bg-success">
                                                                    Assigned
                                                                </span>
                                                            ) : (
                                                                "-"
                                                            )
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">Job requirement</span>
                                                    <span className="profile-ud-value">
                                                        {requisitionDetails?.job_requirement_exists !== null ? (
                                                            requisitionDetails
                                                                ?.job_requirement_exists === 0 ? (
                                                                <span className="badge bg-warning">
                                                                    Pending
                                                                </span>
                                                            ) : requisitionDetails
                                                                ?.job_requirement_exists === 1 ? (
                                                                <span className="badge bg-success">
                                                                    Assigned
                                                                </span>
                                                            ) : (
                                                                "-"
                                                            )
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">Job Assignment</span>
                                                    <span className="profile-ud-value">
                                                        {requisitionDetails?.job_assignment_exists !== null ? (
                                                            requisitionDetails
                                                                ?.job_assignment_exists === 0 ? (
                                                                <span className="badge bg-warning">
                                                                    Pending
                                                                </span>
                                                            ) : requisitionDetails
                                                                ?.job_assignment_exists === 1 ? (
                                                                <span className="badge bg-success">
                                                                    Assigned
                                                                </span>
                                                            ) : (
                                                                "-"
                                                            )
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">Workflow</span>
                                                    <span className="profile-ud-value">
                                                        {requisitionDetails?.workflow_exists !== null ? (
                                                            requisitionDetails
                                                                ?.workflow_exists === 0 ? (
                                                                <span className="badge bg-warning">
                                                                    Pending
                                                                </span>
                                                            ) : requisitionDetails
                                                                ?.workflow_exists === 1 ? (
                                                                <span className="badge bg-success">
                                                                    Assigned
                                                                </span>
                                                            ) : (
                                                                "-"
                                                            )
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">SEO</span>
                                                    <span className="profile-ud-value">
                                                        {requisitionDetails?.seo_exists !== null ? (
                                                            requisitionDetails
                                                                ?.seo_exists === 0 ? (
                                                                <span className="badge bg-warning">
                                                                    Pending
                                                                </span>
                                                            ) : requisitionDetails
                                                                ?.seo_exists === 1 ? (
                                                                <span className="badge bg-success">
                                                                    Assigned
                                                                </span>
                                                            ) : (
                                                                "-"
                                                            )
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="profile-ud-item">
                                                <div className="profile-ud wider">
                                                    <span className="profile-ud-label">Interview Questionnaire</span>
                                                    <span className="profile-ud-value">
                                                        {requisitionDetails?.interview_questionnaire_exists !== null ? (
                                                            requisitionDetails
                                                                ?.interview_questionnaire_exists === 0 ? (
                                                                <span className="badge bg-warning">
                                                                    Pending
                                                                </span>
                                                            ) : requisitionDetails
                                                                ?.interview_questionnaire_exists === 1 ? (
                                                                <span className="badge bg-success">
                                                                    Assigned
                                                                </span>
                                                            ) : (
                                                                "-"
                                                            )
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RequisitionDetails;
