import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";

const RE3Create = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);

  const navigate = useNavigate();
  let location = useLocation();
  console.log(location);
  console.log(location?.state?.editpost?.requisition);

  const userID = secureLocalStorage.getItem("userID");

  const agencyName = location?.state?.editpost?.requisition?.re3_agency;
  console.log(agencyName);

  const [selectedActions, setSelectedActions] = useState([]);
  console.log(selectedActions);

  const [submitMessage, setSubmitMessage] = useState(null);

  const confirmToggle = (action) => {
    Swal.fire({
      title: "Are you sure you want to proceed?",
      text: "This action cannot be undone once completed.",
      showDenyButton: true,
      confirmButtonText: "Yes!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        handleToggle(action);
      } else {
        console.log("else block");
      }
    });
  };

  // const handleToggle = (action) => {
  //   const index = selectedActions?.indexOf(action);
  //   console.log(index);
  //   if (index === -1) {
  //     setSelectedActions([...selectedActions, action]);
  //     onSubmit([...selectedActions, action], action);
  //   } else {
  //     const newSelectedActions = [...selectedActions];
  //     newSelectedActions?.splice(index, 1);
  //     setSelectedActions(newSelectedActions);
  //     onSubmit(newSelectedActions, action);
  //   }
  // };

  const handleToggle = (action) => {
    if (!selectedActions.includes(action)) {
      setSelectedActions([...selectedActions, action]);
      onSubmit([...selectedActions, action], action);
    }
  };

  const handleCatchBlock = (data, action) => {
    console.log(data);
    console.log(action);
    const index = data?.indexOf(action);
    if (index === -1) {
      setSelectedActions([...data, action]);
    } else {
      const newSelectedActions = [...data];
      newSelectedActions?.splice(index, 1);
      setSelectedActions(newSelectedActions);
    }
  };

  const isActionSelected = (action) => {
    return selectedActions?.includes(action);
  };

  const onSubmit = (data, action) => {
    console.log(data);
    console.log(selectedActions);
    var user = JSON.stringify({
      re3_posted: data,
      updated_by_id: userID,
    });

    var config = {
      method: "patch",
      url:
        VARIABLES.url +
        `/api/requestrequisition/${location.state?.editpost?.requisition?.id}/?type=re_outreach`,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        onTATSubmit(action)
        Swal.fire({
          text: `${response.data?.message || 'Success'}`,
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((error) => {
        console.log(error);
        handleCatchBlock(data, action);
        if (error.response?.status === 404) {
          Swal.fire({
            text: `${error.response.data?.message}`,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
        else {
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed",
          });
        }
      });
  };

  const onTATSubmit = (data) => {
    console.log(data)
    var user = JSON.stringify({
      requisition_id: location?.state?.editpost?.requisition?.id,
      stage: "RE3 Outreach",
      platform: data,
      updated_by_id: userID
    });

    var config = {
      method: "patch",
      url: VARIABLES.url + "/api/requisition-activity-log/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          RefreshToken();
        }
      });
  };

  const handleNavigate = (action) => {
    console.log(action);
    navigate("/createcandidateresume", {
      state: {
        agency_name: action,
        editpost: location?.state?.editpost,
        selectedActions: selectedActions,
      },
    });
  };

  const handleManageInternallyNavigate = () => {
    navigate("/resumepooladvancefilter", {
      state: {
        from: "internally",
        type: "RE3",
      },
    });
  };

  useEffect(() => {
    if (location?.state?.selectedActions) {
      setSelectedActions(location?.state?.selectedActions);
    } else if (location?.state?.editpost?.requisition?.re3_posted) {
      setSelectedActions(location?.state?.editpost?.requisition?.re3_posted);
    } else {
      setSelectedActions([]);
    }
  }, [location.state]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Agency Outreach </h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner m-2 border-bottom">
            <div className="profile-ud-list">
              <div className="profile-ud-item" style={{ width: "100%" }}>
                <div className="profile-ud wider">
                  <span className="profile-ud-label me-4">Comments by TAM</span>
                  <span className="profile-ud-value">
                    {location?.state?.editpost?.requisition?.re3_comments ||
                      "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="card-inner">
            <div className="row g-4">
              {agencyName ? (
                <>
                  {agencyName &&
                    agencyName?.map((action, index) => (
                      <div
                        className="col-lg-4 col-md-4 col-sm-12 p-5"
                        key={index}
                      >
                        <h5 className="titlesite d-flex justify-center mb-4">
                          {action}
                        </h5>
                        {location.state?.action === 'Outreach' &&
                          <div className="custom-control custom-switch d-flex justify-center mb-4">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`customSwitch${action?.toLowerCase()}`}
                              // checked={selectedActions?.includes(action)}
                              checked={isActionSelected(action)}
                              onChange={() => {
                                if (isActionSelected(action)) {
                                  setSubmitMessage({
                                    type: "warning",
                                    icon: "cross",
                                    message: "This action is irreversible. Once selected, it cannot be deselected.",
                                  });
                                  return;
                                } else {
                                  confirmToggle(action)
                                }
                              }} />
                            <label
                              className="custom-control-label"
                              id="button-button-status"
                              htmlFor={`customSwitch${action?.toLowerCase()}`}
                            ></label>
                          </div>
                        }
                        {location.state?.action === 'Upload' &&
                          <div className="row">
                            <button
                              type="submit"
                              className="btn btn-primary d-flex justify-center"
                              id={`button-button-re3_candidate_resume_${index}`}
                              onClick={() => {
                                onTATSubmit(action)
                                handleNavigate(action)
                              }}
                              disabled={!isActionSelected(action)}
                            >
                              Upload Resume
                            </button>
                          </div>
                        }
                      </div>
                    ))}
                </>
              ) : (
                "Agencies are not assigned to this requisition"
              )}
              {location.state?.editpost?.requisition
                ?.re3_manage_internally && (
                  <div className="col-lg-4 col-md-4 col-sm-12 p-5">
                    <h5 className="titlesite d-flex justify-center mb-4">
                      Manage Internally
                    </h5>
                    {location.state?.action === 'Outreach' &&
                      <div className="custom-control custom-switch d-flex justify-center mb-4">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="button-button-re3_manage_internally"
                          checked={selectedActions?.includes(
                            "Manage Internally"
                          )}
                          onChange={() => confirmToggle("Manage Internally")}
                        />
                        <label
                          className="custom-control-label"
                          id="button-button-status"
                          htmlFor="button-button-re3_manage_internally"
                        ></label>
                      </div>
                    }
                    {location.state?.action === 'Upload' &&
                      <div className="row">
                        <button
                          type="submit"
                          className="btn btn-primary d-flex justify-center"
                          onClick={() => {
                            onTATSubmit("Manage Internally")
                            handleManageInternallyNavigate()
                          }}
                          disabled={!isActionSelected("Manage Internally")}
                        >
                          Map Requisition
                        </button>
                      </div>
                    }
                  </div>
                )}
              {/* {location.state?.editpost?.requisition?.re3_whatsapp && (
                <div className="col-lg-4 col-md-4 col-sm-12 p-5">
                  <h5 className="titlesite d-flex justify-center mb-4">
                    Whatsapp
                  </h5>
                  {location.state?.action === "Outreach" && (
                    <div className="custom-control custom-switch d-flex justify-center mb-4">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="button-button-re3_whatsapp"
                        checked={selectedActions?.includes("Whatsapp")}
                        onChange={() => confirmToggle("Whatsapp")}
                      />
                      <label
                        className="custom-control-label"
                        id="button-button-status"
                        htmlFor="button-button-re3_whatsapp"
                      ></label>
                    </div>
                  )}
                  {location.state?.action === "Upload" && (
                    <div className="row">
                      <button
                        type="submit"
                        className="btn btn-primary d-flex justify-center"
                        onClick={() => handleManageInternallyNavigate()}
                        disabled={!isActionSelected("Whatsapp")}
                      >
                        Map Requisition
                      </button>
                    </div>
                  )}
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RE3Create;
