import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { MDApprovalContext } from "../../../Context/MDApprovalContext";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import Snackbar from "../../../Base/Snackbar";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { useLocation } from "react-router-dom";
import Message from "../Message/Message";
// import { assignment } from '../../WorkflowStepperForm/StepperFormJson'

const AssignmentReview = ({ formId }) => {
  const location = useLocation();
  const candidate_id = location?.state?.candidate_id;
  console.log(candidate_id);
  const requisition_id = location?.state?.requisition_id;
  console.log(requisition_id);

  console.log(formId); //data of the tab in which this form is present

  //message after submitting data
  const { setSubmitMessage } = useContext(SnackbarContext);
  const [submitLoading, setSubmitLoading] = useState(false);

  // Secure Local Storage
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const privileges = secureLocalStorage.getItem("privileges");

  // const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  // console.log(candidate_job_id);
  // const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");

  //stores data of assignment of the particular candidate
  const {
    assignment,
    isAssignmentDataLoading, getAssignmentData } =
    useContext(MDApprovalContext);

  const AssignmentData = assignment;
  console.log("AssignmentData: ", AssignmentData);

  //Stores data for radio button (accept, reject, other, draft)
  const { interviewActionDropdownData } = useContext(TypemasterDropdownContext);
  console.log(interviewActionDropdownData);

  // Stepper (NEXT/PREV)
  const { level, sequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

  // To call workflow session after the POST/ PATCH request
  const { getWorkflowSession, workflowSession } = useContext(WorkflowContext);

  // const isFormFilled = workflowSession.find((api) => api.tab_id == formId?.id);
  const isFieldsDisabled =
    workflowSession.find((api) => api.tab_id === formId?.id) ||
    !privileges.includes("Assign Assignmnent Review Create");

  const userExists = AssignmentData?.assignment_conducted_user?.find(
    (review) => review?.id === userID
  );
  console.log("userExists", userExists)

  const reviewFound = AssignmentData?.assignment_review_by?.find((review) => review?.user?.id === userID);
  console.log("reviewFound: ", reviewFound)

  // const showForm = reviewFound && (reviewFound?.selection_status === 0 || reviewFound?.selection_status === null) ? reviewFound : false;
  const showForm =
    (userExists && !reviewFound) ||
    (reviewFound && (reviewFound?.selection_status === 0 || reviewFound?.selection_status === null));
  console.log("showForm: ", showForm)

  // const showRCForm = AssignmentData?.assignment_conducted_user?.every(
  //   (conductedUser) =>
  //     AssignmentData?.assignment_review_by?.some(
  //       (review) => review?.user?.id === conductedUser?.id
  //     )
  // );

  const showRCForm = AssignmentData?.assignment_conducted_user
    ?.filter((conductedUser) => conductedUser.id !== userID)
    ?.every((conductedUser) =>
      AssignmentData?.assignment_review_by?.some(
        (review) => review?.user?.id === conductedUser?.id
      )
    );
  console.log("showRCForm: ", showRCForm)

  const shouldRenderForm =
    AssignmentData?.assignment_review_by?.length > 0 &&
    AssignmentData.assignment_review_by.every(
      (info) => info.selection_status !== null && info.selection_status !== 0
    );
  console.log("shouldRenderForm:", shouldRenderForm)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleRCSubmit = (data) => {
    onSubmit(data, "RC");
  };

  //Submission of Assignmnet Review form
  const onSubmit = (data, data_from) => {
    console.log(data);
    const access = secureLocalStorage.getItem("access");
    let workflow_session = {
      tab_id: formId?.id,
      workflow_id: formId?.WF,
      user_id: userID,
    };
    let user = new FormData();
    user.append("candidate_job_id", candidate_id);
    user.append("requisition_id", requisition_id);
    user.append("updated_by_id", userID);
    user.append("workflow_session", JSON.stringify(workflow_session));
    if (data_from === "RC") {
      user.append("comments", data.comments_by_rc);
      user.append(
        "selection_status",
        data?.is_selected_rc === "Draft"
          ? 0
          : data?.is_selected_rc === "Accept"
            ? 1
            : data?.is_selected_rc === "Reject"
              ? 2
              : null
      );
    } else {
      user.append("comments", data.comments);
      user.append("feedback", data.feedback);
      user.append("assignment_review_by_id", userID);
      user.append("total_score", data.assignment_score);
      user.append(
        "selection_status",
        data?.is_selected === "Draft"
          ? 0
          : data?.is_selected === "Accept"
            ? 1
            : data?.is_selected === "Reject"
              ? 2
              : null
      );
    }

    var config = {
      method: "patch",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${access}`,
      },
      url: VARIABLES.url + `/api/assignment/${AssignmentData.id}/`,
      data: user,
    };

    for (var pair of user.entries()) {
      console.log(pair);
    }

    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data) {
          getAssignmentData(candidate_id, requisition_id);
          getWorkflowSession(candidate_id, requisition_id);
          setSubmitLoading(false);
          if (data_from === "RC") {
            setSubmitMessage({
              type: "success",
              icon: "check",
              message: data?.is_selected_rc === "Draft" ? "Assignment drafted successfully" : data?.is_selected_rc === "Reject" ? "Candidate rejected successfully" : "Assignment reviewed successfully",
            });
          } else {
            setSubmitMessage({
              type: "success",
              icon: "check",
              message: data?.is_selected === "Draft" ? "Candidate drafted successfully" : data?.is_selected === "Reject" ? "Candidate rejected successfully" : "Assignment reviewed successfully",
            });
          }
          nextLevel();
          reset();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message:
            error?.response?.data?.message || "Failed to review assignment",
        });
      });
  };

  useEffect(() => {
    if (AssignmentData) {
      console.log(AssignmentData);
      const defaultValues = {
        assignment_score_rc: AssignmentData.total_score,
        is_selected_rc:
          AssignmentData.selection_status === 0
            ? "Draft"
            : AssignmentData.selection_status === 1
              ? "Accept"
              : AssignmentData.selection_status === 2
                ? "Reject"
                : null,
        comments_by_rc: AssignmentData?.comments,
      };
      if (reviewFound) {
        defaultValues.comments = reviewFound?.comments;
        defaultValues.feedback = reviewFound?.feedback;
        defaultValues.assignment_score = reviewFound?.total_score;
        defaultValues.is_selected =
          reviewFound.selection_status === 0
            ? "Draft"
            : reviewFound.selection_status === 1
              ? "Accept"
              : reviewFound.selection_status === 2
                ? "Reject"
                : null;
      }
      reset(defaultValues);
    }
  }, [AssignmentData]);

  useEffect(() => {
    getAssignmentData(candidate_id, requisition_id);
  }, []);

  const [workflowId, setWorkflowId] = useState(null);
  console.log(workflowSession);

  useEffect(() => {
    const filter_workflow_session = workflowSession.find(
      (api) => api.tab_id == formId?.id
    );
    console.log(filter_workflow_session);
    setWorkflowId(filter_workflow_session);
  }, [workflowSession]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Assignment Review </h4>
            {isAssignmentDataLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {formId?.is_iterable && formId?.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )}
        </div>
      </div>
      {isAssignmentDataLoading ? (
        <div></div>
      ) : (
        <>
          <div className="col-lg-12 col-md-12 col-sm-12">
            {AssignmentData.selection_status === 2 ?
              <Message icon={require("../../../../Resources/images/rejected.png")}
                message={"The candidate has been disqualified at this stage of the recruitment process."} />
              :
              <div className="card">
                <div className="card-inner">
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="row g-4">
                      <div className="profile-ud-list">
                        <div className="profile-ud-item w-100">
                          <div className="profile-ud wider">
                            <span
                              className="profile-ud-label"
                              style={{ width: "200px" }}
                            >
                              View candidate's assignment :
                            </span>
                            <span className="profile-ud-value align-start">
                              {AssignmentData?.upload_assignment !== null ? (
                                <a
                                  className="btn btn-primary"
                                  href={AssignmentData?.upload_assignment}
                                  target="_blank"
                                  id="view-assignmnent-link"
                                >
                                  <em
                                    className="icon ni ni-file-pdf"
                                    style={{ color: "white" }}
                                  ></em>
                                </a>
                              ) : (
                                <p className="text-start text-danger">
                                  Candidate has not submitted the assignment yet
                                </p>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>SME/Departmental Manager</th>
                              <th>Comments</th>
                              <th>Feedback</th>
                              <th>Score</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          {AssignmentData?.assignment_review_by?.length > 0 ? (
                            <tbody>
                              {AssignmentData?.assignment_review_by?.map(
                                (info) => (
                                  <tr>
                                    <td>
                                      {info?.user.first_name +
                                        " " +
                                        info?.user.last_name}
                                    </td>
                                    <td>{info?.comments || "-"}</td>
                                    <td>{info?.feedback || "-"}</td>
                                    <td>{info?.total_score || "-"}</td>
                                    <td>
                                      {info.selection_status === 0 ? (
                                        <span className="badge bg-warning">
                                          Draft
                                        </span>
                                      ) : info.selection_status === 1 ? (
                                        <span className="badge bg-success">
                                          Accepted
                                        </span>
                                      ) : info.selection_status === 2 ? (
                                        <span className="badge bg-danger">
                                          Rejected
                                        </span>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan={4} className="text-danger">
                                  SME review for this assignment is currently pending.
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>

                      {privileges.includes("Assign Assignment Review By Panelist") && AssignmentData?.upload_assignment !== null && (
                        <>
                          {showForm && (
                            <>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="form-label">
                                  Comments
                                  <span className="text-danger">*</span>
                                </label>
                                <textarea
                                  className="form-control"
                                  id="textarea-forms-comments"
                                  placeholder="Enter Comments"
                                  {...register("comments", { required: true })}
                                  disabled={isFieldsDisabled}
                                />
                                {errors.comments && (
                                  <span className="fs-8 text-danger">
                                    Comments are required
                                  </span>
                                )}
                              </div>

                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="form-label">
                                  Feedback
                                  <span className="text-danger">*</span>
                                </label>
                                <textarea
                                  className="form-control"
                                  id="textarea-form-feedback"
                                  placeholder="Enter Feedback"
                                  {...register("feedback", { required: true })}
                                  disabled={isFieldsDisabled}
                                />
                                {errors.feedback && (
                                  <span className="fs-8 text-danger">
                                    Feedback is required
                                  </span>
                                )}
                              </div>

                              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-center">
                                <label
                                  className="form-label mb-0"
                                  style={{ width: "9rem" }}
                                >
                                  Score (out of 5) :
                                </label>
                                <input
                                  type="number"
                                  className="form-control ms-2"
                                  id="number-form-assignment_score"
                                  placeholder="Enter score"
                                  {...register("assignment_score")}
                                  min="0"
                                  max="5"
                                  onWheel={(e) => e.target.blur()}
                                  disabled={isFieldsDisabled}
                                />
                                {errors.assignment_score &&
                                  errors.assignment_score.type == "min" && (
                                    <span className="fs-8 text-danger">
                                      Score should be out of 5
                                    </span>
                                  )}
                                {errors.assignment_score &&
                                  errors.assignment_score.type == "max" && (
                                    <span className="fs-8 text-danger">
                                      Score should be out of 5
                                    </span>
                                  )}
                              </div>

                              <div className="col-lg-12 col-md-6 col-sm-12">
                                <ul className="row g-3">
                                  {interviewActionDropdownData.map(
                                    (action, index) => (
                                      <li
                                        className="col-lg-2 col-md-2 col-sm-2"
                                        key={index}
                                      >
                                        <div className="custom-control custom-radio me-3">
                                          <input
                                            type="radio"
                                            id={`checkbox-form-is-selected-assignment-${action.value.toLowerCase()}`}
                                            name="resumeupload"
                                            className="custom-control-input"
                                            value={action.value}
                                            {...register("is_selected", {
                                              required: true,
                                            })}
                                            disabled={isFieldsDisabled}
                                          />
                                          <label
                                            className="custom-control-label"
                                            for={`checkbox-form-is-selected-assignment-${action.value.toLowerCase()}`}
                                          >
                                            {action.name}
                                          </label>
                                        </div>
                                      </li>
                                    )
                                  )}
                                </ul>
                                {errors.is_selected && (
                                  <span className="fs-8 text-danger">
                                    Please select any one
                                  </span>
                                )}
                              </div>

                              <div className="col-12">
                                <div className="form-group">
                                  {level !== 0 && (
                                    <button
                                      type="button"
                                      className="btn btn-lg btn-primary me-3"
                                      id="button-button-prevlevel"
                                      onClick={() => prevLevel()}
                                    >
                                      Prev
                                    </button>
                                  )}
                                  {submitLoading ? (
                                    <button
                                      id="button-loading"
                                      className="btn text-dark"
                                      disabled
                                    >
                                      Loading...
                                    </button>
                                  ) : (
                                    <>
                                      {sequenceList.length - 2 > level ? (
                                        workflowId?.is_complete === 1 ? (
                                          <button
                                            type="submit"
                                            className="btn btn-lg btn-primary"
                                            id="submit-button-assignassignment"
                                          // onClick={() => nextLevel()}
                                          >
                                            Next
                                          </button>
                                        ) : (
                                          <button
                                            type="submit"
                                            className="btn btn-lg btn-primary"
                                            id="submit-button-assignassignment"
                                          >
                                            Next
                                          </button>
                                        )
                                      ) : (
                                        <button
                                          type="submit"
                                          className="btn btn-lg btn-primary"
                                          id="submit-button-assignassignment"
                                        >
                                          Submit
                                        </button>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}

                    </div>
                  </form>

                  {privileges.includes("Assign Assignment Review All") && (
                    <>
                      {showRCForm && shouldRenderForm ?
                        <form
                          onSubmit={handleSubmit(handleRCSubmit)}
                          autoComplete="off"
                          className="mt-4"
                        >
                          <div className="row g-4">
                            {/* <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-center">
                      <label
                        className="form-label mb-0"
                        style={{ width: "8rem" }}
                      >
                        Score out of 5 :
                      </label>
                      <input
                        type="number"
                        className="form-control ms-2"
                        id="number-form-assignment_score"
                        placeholder="Enter score"
                        {...register("assignment_score_rc")}
                        min="0"
                        max="5"
                        onWheel={(e) => e.target.blur()}
                      />
                      {errors.assignment_score_rc &&
                        errors.assignment_score_rc.type == "min" && (
                          <span className="fs-8 text-danger">
                            Score should be out of 5
                          </span>
                        )}
                      {errors.assignment_score_rc &&
                        errors.assignment_score_rc.type == "max" && (
                          <span className="fs-8 text-danger">
                            Score should be out of 5
                          </span>
                        )}
                    </div> */}

                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <label className="form-label">
                                Comments by RC
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="textarea-forms-comments"
                                placeholder="Enter Comments"
                                {...register("comments_by_rc", { required: true })}
                                disabled={isFieldsDisabled && assignment?.selection_status !== null && assignment?.selection_status !== 0}
                              />
                              {errors.comments_by_rc && (
                                <span className="fs-8 text-danger">
                                  Comments are required
                                </span>
                              )}
                            </div>

                            <div className="col-lg-12 col-md-6 col-sm-12">
                              <ul className="row g-3">
                                {interviewActionDropdownData.map((action, index) => (
                                  <li
                                    className="col-lg-2 col-md-2 col-sm-2"
                                    key={index}
                                  >
                                    <div className="custom-control custom-radio me-3">
                                      <input
                                        type="radio"
                                        id={`checkbox-form-is-selected-assignment-${action.value.toLowerCase()}`}
                                        name="resumeupload"
                                        className="custom-control-input"
                                        value={action.value}
                                        {...register("is_selected_rc", {
                                          required: true,
                                        })}
                                        disabled={isFieldsDisabled && assignment?.selection_status !== null && assignment?.selection_status !== 0}
                                      />
                                      <label
                                        className="custom-control-label"
                                        for={`checkbox-form-is-selected-assignment-${action.value.toLowerCase()}`}
                                      >
                                        {action.name}
                                      </label>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                              {errors.is_selected_rc && (
                                <span className="fs-8 text-danger">
                                  Please select any one
                                </span>
                              )}
                            </div>

                            <div className="col-12">
                              <div className="form-group">
                                {level !== 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary me-3"
                                    id="button-button-prevlevel"
                                    onClick={() => prevLevel()}
                                  >
                                    Prev
                                  </button>
                                )}
                                {submitLoading ? (
                                  <button
                                    id="button-loading"
                                    className="btn text-dark"
                                    disabled
                                  >
                                    Loading...
                                  </button>
                                ) : (
                                  <>
                                    {sequenceList.length - 2 > level ? (
                                      workflowId?.is_complete === 1 ? (
                                        <button
                                          type="button"
                                          className="btn btn-lg btn-primary"
                                          id="submit-button-assignassignment"
                                          onClick={() => nextLevel()}
                                        >
                                          Next
                                        </button>
                                      ) : (
                                        <button
                                          type="submit"
                                          className="btn btn-lg btn-primary"
                                          id="submit-button-assignassignment"
                                        >
                                          Next
                                        </button>
                                      )
                                    ) : (
                                      <button
                                        type="submit"
                                        className="btn btn-lg btn-primary"
                                        id="submit-button-assignassignment"
                                      >
                                        Submit
                                      </button>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                        :
                        <span className="fs-6 text-danger">SME review for this assignment is currently pending.</span>
                      }
                    </>
                  )}
                </div>
              </div>
            }
          </div>
        </>
      )}
      <Snackbar />
    </>
  );
};

export default AssignmentReview;
