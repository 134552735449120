import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { CandidateResumeContext } from "../../Context/CandidateResumeContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import ConfirmCandidateResume from "./ConfirmCandidateResume";
import Select from "react-select";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";

const CandidateResumeCreate = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");
  const navigate = useNavigate();
  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();
  let location = useLocation();
  console.log(location.state);
  console.log(location.state?.editpost?.requisition?.id);
  console.log(location.state?.digital_marketing_platform);

  const { getCandidateResume } = useContext(CandidateResumeContext);

  const AgencyNameDropdown = location.state?.editpost?.requisition?.re3_agency;
  console.log(AgencyNameDropdown);

  const { requisitionID } = useContext(TypemasterDropdownContext);

  const {
    jobOpeningDropdownData,
    jobTitleDropdownData,
    isGenricDropdownDataLoading,
  } = useContext(GenericDropdownContext);
  console.log(jobOpeningDropdownData);

  const CollegeNameDropdown =
    location.state?.editpost?.requisition?.re1_college;
  console.log(CollegeNameDropdown);

  const [uploadType, setUploadType] = useState("singleupload");
  console.log(uploadType);

  const [selectedFiles, setSelectedFiles] = useState([]);
  console.log(selectedFiles);

  const [filePreview, setFilePreview] = useState(null);

  const [responseData, setResponseData] = useState([]);
  console.log(responseData);

  // watch("mobile");

  const handleResumeUploadType = (e) => {
    setUploadType(e.target.id);
  };

  const openDocument = () => {
    if (filePreview) {
      window.open(filePreview, "_blank");
    }
  };

  const handleResumeChange = (files, type) => {
    console.log(files);
    if (type === "single") {
      setValue("resume", files);
    } else {
      setValue("bulk_resume", files);
    }
    setSelectedFiles(files);
    const fileType = files[0]?.type;

    // Generate a preview URL for PDF files only
    if (fileType === "application/pdf") {
      setFilePreview(URL.createObjectURL(files[0]));
    } else if (
      fileType === "application/msword" ||
      fileType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      // You can show an icon or message for Word documents as Word files cannot be directly previewed in the browser
      setFilePreview("Word document preview is not supported.");
    } else {
      setFilePreview(null);
    }
  };

  const onBulkSubmit = (data) => {
    onSubmit(data);
  }

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    let user = new FormData();
    // user.append(
    //   "requisition_id",
    //   location?.state?.editpost?.requisition?.id || null
    // );
    // user.append("college_name", location.state?.college_name || null);
    // user.append("agency_name", location.state?.agency_name || null);
    // user.append(
    //   "digital_marketing_platform",
    //   location.state?.digitalMarketingPlatform || null
    // );
    if (location?.state?.editpost?.requisition?.id != null) {
      user.append("requisition_id", location?.state?.editpost?.requisition?.id);
    }
    if (location.state?.college_name != null) {
      user.append("college_name", location.state?.college_name);
    }
    if (location.state?.digitalMarketingPlatform != null) {
      user.append(
        "digital_marketing_platform",
        location.state?.digitalMarketingPlatform
      );
    }
    if (location.state?.agency_name != null) {
      user.append("agency_name", location.state?.agency_name);
    }
    user.append("candidate_name", data.candidate_name || null);
    user.append("email", data.email || null);
    user.append("mobile", data.mobile || null);
    for (let i = 0; i < selectedFiles.length; i++) {
      console.log(selectedFiles);
      console.log(selectedFiles[i]);
      user.append("resume", selectedFiles[i]);
    }
    // console.log(data,"===========")
    if (location.state?.data === "referral") {
      user.append("is_referral", "True");
      user.append("job_id", data.job_title.map((val) => val.value));
    }
    user.append("created_by_id", userID);

    for (var pair of user.entries()) {
      console.log(pair);
    }

    const url = VARIABLES.url + "/api/candidateresume/";
    console.log(url);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };

    console.log(user);

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        getCandidateResume();
        console.log(JSON.stringify(response.data));
        setResponseData(response.data?.data);
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Candidate resume successfully created",
        });
        reset();
        if (uploadType === "singleupload") {
          setTimeout(() => {
            if (location.state?.college_name) {
              navigate("/re1create", {
                state: {
                  ...location.state,
                  action: "Upload",
                  selectedActions: location.state?.selectedActions,
                },
              });
            } else if (location.state?.digitalMarketingPlatform) {
              navigate("/re2create", {
                state: {
                  ...location.state,
                  action: "Upload",
                  selectedActions: location.state?.selectedActions,
                },
              });
            } else if (location.state?.agency_name) {
              navigate("/re3create", {
                state: {
                  ...location.state,
                  action: "Upload",
                  selectedActions: location.state?.selectedActions,
                },
              });
            } else {
              navigate(-1);
            }
          }, 2000);
        } else {
          document.getElementById("button-button-signinmodal").click();
          const fileInput = document.getElementById("file-forms-resume");
          if (fileInput) {
            fileInput.value = "";
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: error.response?.data?.message || "Failed to create candidate resume",
          });
        }
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Add New Resume </h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <div className="mb-4">
              {location.state?.data !== "referral" && (
                <>
                  {privileges.includes("resume_single_upload") ? (
                    <div className="custom-control custom-radio me-3">
                      <input
                        type="radio"
                        id="singleupload"
                        name="resumeupload"
                        className="custom-control-input"
                        checked={uploadType === "singleupload"}
                        onChange={(e) => handleResumeUploadType(e)}
                      />
                      <label
                        className="custom-control-label"
                        for="singleupload"
                      >
                        Single Upload
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                  {privileges.includes("resume_bulk_upload") ? (
                    <div className="custom-control custom-control custom-radio">
                      <input
                        type="radio"
                        id="bulkupload"
                        name="resumeupload"
                        className="custom-control-input"
                        checked={uploadType === "bulkupload"}
                        onChange={(e) => handleResumeUploadType(e)}
                      />
                      <label
                        className="custom-control-label"
                        for="bulkupload"
                      >
                        Bulk Upload
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
            {uploadType === "singleupload" ? (
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row g-4">

                  {location.state?.data === "referral" && (
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Job Title <span className="text-danger">*</span>
                        </label>
                        <Controller
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={jobOpeningDropdownData?.map(
                                (info) => ({
                                  value: info.id,
                                  label:
                                    info.job_title +
                                    " " +
                                    info.department_name +
                                    " " +
                                    info.team_name,
                                })
                              )}
                              isLoading={isGenricDropdownDataLoading}
                              isClearable={true}
                              id="select-forms-job_title"
                              isMulti
                            />
                          )}
                          defaultValue=""
                          rules={{ required: true }}
                          name="job_title"
                          control={control}
                        />
                        {errors.job_title && (
                          <span className="fs-8 text-danger">
                            Job title is required
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label className="form-label">
                      Candidate Name
                      <span className="fs-8 text-danger"> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-candidatename"
                      placeholder="Enter candidate name"
                      {...register("candidate_name", { required: true })}
                    />
                    {errors.candidate_name && (
                      <span className="fs-8 text-danger">
                        Candidate Name is required
                      </span>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label className="form-label">
                      Email
                      <span className="fs-8 text-danger"> *</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="text-forms-email"
                      placeholder="Enter email"
                      {...register("email", {
                        required: "Email is required",
                        validate: (value) =>
                          value === value.toLowerCase() ||
                          "Email should only contain lowercase letters",
                      })}
                    />
                    {errors.email && (
                      <span className="fs-8 text-danger">
                        {errors?.email?.message}
                      </span>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label className="form-label">
                      Mobile
                      <span className="fs-8 text-danger"> *</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="text-forms-mobile"
                      placeholder="Enter mobile"
                      {...register("mobile", {
                        required: true,
                        minLength: 10,
                        maxLength: 10,
                        validate: {
                          startsWithValidDigit: (value) =>
                            ["9", "8", "7", "6"].includes(
                              value.toString().charAt(0)
                            ) || "First digit should be 9, 8, 7, or 6",
                        },
                      })}
                    />
                    {errors.mobile && errors.mobile.type == "required" && (
                      <span className="fs-8 text-danger">
                        Mobile is required
                      </span>
                    )}
                    {errors.mobile && errors.mobile.type == "minLength" && (
                      <span className="fs-8 text-danger">
                        Number should be minimum 10 digits
                      </span>
                    )}
                    {errors.mobile && errors.mobile.type == "maxLength" && (
                      <span className="fs-8 text-danger">
                        Number should be maximum 10 digits
                      </span>
                    )}
                    {errors.mobile &&
                      errors.mobile.type === "startsWithValidDigit" && (
                        <span className="fs-8 text-danger">
                          {errors.mobile.message}
                        </span>
                      )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label className="form-label">
                      Resume <span className="fs-8 text-danger">*</span>
                    </label>
                    <Controller
                      name="resume"
                      control={control}
                      rules={{
                        required: true,
                        validate: (value) => {
                          const fileType = value?.[0]?.type;
                          return (
                            fileType === "application/pdf" ||
                            fileType === "application/msword" ||
                            fileType ===
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                            "Please upload your document in pdf/doc/docx format"
                          );
                        },
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          className="form-control"
                          id="file-forms-resume"
                          type="file"
                          onChange={(e) => {
                            field.onChange(e.target.files);
                            handleResumeChange(e.target.files, "single");
                          }}
                        />
                      )}
                    />
                    {errors.resume && errors.resume.type === "required" && (
                      <span className="fs-8 text-danger">
                        Resume is required
                      </span>
                    )}
                    {errors.resume && errors.resume.type === "validate" && (
                      <span className="fs-8 text-danger">
                        Please upload your document in pdf/doc/docx format
                      </span>
                    )}
                    {filePreview && (
                      <div className="mt-3">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={openDocument}
                        >
                          Preview
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-lg btn-light me-3"
                        id="submit-button-backbutton"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                      {submitLoading ? (
                        <button
                          id="button-loading"
                          className="btn text-dark"
                          disabled
                        >
                          Loading...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                          id="submit-button-createcandidateresume"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <form onSubmit={handleSubmit(onBulkSubmit)} autoComplete="off">
                <div className="row g-4">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label className="form-label">
                      Resume <span className="fs-8 text-danger"> *</span>
                    </label>
                    <Controller
                      name="bulk_resume"
                      control={control}
                      rules={{
                        required: true,
                        validate: (value) => {
                          const filesArray = Array.from(value);
                          const allowedTypes = [
                            "application/pdf",
                            "application/msword",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          ];

                          return filesArray.every((file) =>
                            allowedTypes.includes(file.type)
                          );
                        },
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          className="form-control"
                          id="file-forms-resume"
                          type="file"
                          multiple
                          onChange={(e) => {
                            field.onChange(e.target.files);
                            handleResumeChange(e.target.files, "bulk");
                          }}
                        />
                      )}
                    />
                    {errors.bulk_resume && errors.bulk_resume.type === "required" && (
                      <span className="fs-8 text-danger">
                        Resume is required
                      </span>
                    )}
                    {errors.bulk_resume && errors.bulk_resume.type === "validate" && (
                      <span className="fs-8 text-danger">
                        Please upload your document in pdf/doc/docx format
                      </span>
                    )}
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-lg btn-light me-3"
                        id="submit-button-backbutton"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                      {submitLoading ? (
                        <button
                          id="button-loading"
                          className="btn text-dark"
                          disabled
                        >
                          Loading...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                          id="submit-button-createcandidateresume"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        hidden
        href="javascript:void(0)"
        data-bs-target="#confirmcandidate"
        data-bs-toggle="modal"
        id="button-button-signinmodal"
      ></div>
      <ConfirmCandidateResume
        responseData={responseData}
        college_name={location.state?.college_name}
        digitalMarketingPlatform={location.state?.digitalMarketingPlatform}
        agency_name={location.state?.agency_name}
        selectedActions={location.state?.selectedActions}
      />
    </>
  );
};

export default CandidateResumeCreate;
