import axios from "axios";
import React, { useState, useContext } from "react";
import { createContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const InterviewQuestionContext = createContext(null);

function InterviewQuestionContextProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);

  const [selectedJobTitle, setSelectedJobTitle] = useState(null);
  const [selectedInterviewType, setSelectedInterviewType] = useState(null);

  // To store Interview Question Data.
  const [isQuestionData, setIsQuestionData] = useState(false);
  const [rawInterviewQuestionData, setRawInterviewQuestionData] = useState([]);
  const [questionEntries, setQuestionEntries] = useState(5);
  const [questionCount, setQuestionCount] = useState(null);
  const [questionPageCount, setQuestionPageCount] = useState(0);
  const [isQuestionLoading, setIsQuestionLoading] = useState(false);

  // To store all technical Interview Questions.
  const [technicalInterviewQuestion, setTechnicalInterviewQuestion] = useState([]);

  // To store all hr Interview Questions.
  const [HRInterviewQuestion, setHRInterviewQuestion] = useState([]);

  // To store all hr telephonic Interview Questions.
  const [HRTelephonicInterviewQuestion, setHRTelephonicInterviewQuestion] = useState([]);

  // To store all SHF Interview Questions.
  const [SHFInterviewQuestion, setSHFInterviewQuestion] = useState([]);

  // "getInterviewQuestionnair" API call to get all interview data
  const getInterviewQuestionnair = async (page, access) => {
    setIsQuestionLoading(true);
    let url;
    if (selectedJobTitle !== null && selectedInterviewType !== null) {
      url =
        VARIABLES.url +
        `/api/interviewquestionnaire/?entries=${questionEntries}&page=${page}&job_opening_id=${selectedJobTitle.value}&interview_type=${selectedInterviewType.value}`;
    } else if (selectedJobTitle === null && selectedInterviewType !== null) {
      url =
        VARIABLES.url +
        `/api/interviewquestionnaire/?entries=${questionEntries}&page=${page}&interview_type=${selectedInterviewType.value}`;
    } else if (selectedJobTitle !== null && selectedInterviewType === null) {
      url =
        VARIABLES.url +
        `/api/interviewquestionnaire/?entries=${questionEntries}&page=${page}&job_opening_id=${selectedJobTitle.value}`;
    } else {
      url =
        VARIABLES.url +
        `/api/interviewquestionnaire/?entries=${questionEntries}&page=${page}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsQuestionLoading(false);
      if (response.length !== 0) {
        setIsQuestionData(false);
        setRawInterviewQuestionData(res.data.data);
      } else {
        setIsQuestionData(true);
        setRawInterviewQuestionData(res.data.data);
      }
      const total = res.data.count;
      setQuestionCount(total);
      setQuestionPageCount(Math.ceil(total / questionEntries));
    } catch (error) {
      console.log(error);
      setIsQuestionLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsQuestionData(false);
      } else {
        setIsQuestionData(true);
      }
    }
  };

  const handleQuestionClick = async (data) => {
    let access = secureLocalStorage.getItem("access");
    console.log(data);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getInterviewQuestionnair(
      currentPage,
      access
    );
  };

  //Get Technical interview questions
  const getTechnicalInterviewQuestions = async (req_id) => {
    setIsQuestionLoading(true);
    const url =
      VARIABLES.url +
      `/api/interviewquestionnaire/?job_opening_id=${req_id}&interview_type=Technical`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setIsQuestionLoading(false);
      setTechnicalInterviewQuestion(res.data.data);
      console.log(res.data.data);
    } catch (error) {
      console.log(error);
      setIsQuestionLoading(false);
    }
  };

  //Get HR interview questions
  const getHRInterviewQuestions = async (req_id) => {
    setIsQuestionLoading(true);
    const url =
      VARIABLES.url +
      `/api/interviewquestionnaire/?job_opening_id=${req_id}&interview_type=HR`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setIsQuestionLoading(false);
      setHRInterviewQuestion(res.data.data);
    } catch (error) {
      console.log(error);
      setIsQuestionLoading(false);
    }
  };

  //Get Telephonic interview questions
  const getHRTelephonicInterviewQuestions = async (req_id) => {
    setIsQuestionLoading(true);
    const url =
      VARIABLES.url +
      `/api/interviewquestionnaire/?job_opening_id=${req_id}&interview_type=HR Telephonic`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setIsQuestionLoading(false);
      setHRTelephonicInterviewQuestion(res.data.data);
    } catch (error) {
      console.log(error);
      setIsQuestionLoading(false);
    }
  };

  //Get SHF interview questions
  const getSHFInterviewQuestions = async (req_id) => {
    setIsQuestionLoading(true);
    const url =
      VARIABLES.url +
      `/api/interviewquestionnaire/?job_opening_id=${req_id}&interview_type=SHF`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setIsQuestionLoading(false);
      setSHFInterviewQuestion(res.data.data);
    } catch (error) {
      console.log(error);
      setIsQuestionLoading(false);
    }
  };

  const value = {
    rawInterviewQuestionData,
    getInterviewQuestionnair,
    questionEntries,
    questionPageCount,
    setQuestionEntries,
    questionCount,
    handleQuestionClick,
    isQuestionData,
    isQuestionLoading,
    setSelectedJobTitle,
    setSelectedInterviewType,
    selectedJobTitle,
    selectedInterviewType,

    technicalInterviewQuestion,
    getTechnicalInterviewQuestions,

    HRInterviewQuestion,
    getHRInterviewQuestions,

    HRTelephonicInterviewQuestion,
    getHRTelephonicInterviewQuestions,

    SHFInterviewQuestion,
    getSHFInterviewQuestions,
  };
  return (
    <div>
      <InterviewQuestionContext.Provider value={value}>
        {children}
      </InterviewQuestionContext.Provider>
    </div>
  );
}

export default InterviewQuestionContextProvider;
