import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";

const ConfirmCandidateResume = (props) => {
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    setValue,
  } = useForm();

  const userID = secureLocalStorage.getItem("userID");

  const [submitLoading, setSubmitLoading] = useState(null);
  const [submitMessage, setSubmitMessage] = useState(null);

  const navigate = useNavigate();
  let location = useLocation();

  const onSubmit = (data) => {
    console.log(data);
    let formData = new FormData();
    formData.append("count", props?.responseData?.length);

    props?.responseData?.map((candidate, index) => {
      formData.append(
        `candidate_details-${index + 1}`,
        JSON.stringify({
          id: candidate.id,
          candidate_name: data.candidate_name[index],
          email: data.email[index],
          mobile: data.mobile[index],
        })
      );
      formData.append(`attachment-${index + 1}`, candidate.resume);
    });
    formData.append("updated_by_id", userID);

    for (var pair of formData.entries()) {
      console.log(pair);
    }

    const url = VARIABLES.url + `/api/candidateresume/?object=many`;

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      data: formData,
    };

    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        document.getElementById("close-modal").click();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully updated candidate details",
        });
        // setTimeout(() => {
        //   // document.getElementById("close-modal").click();
        // }, 2000);
        setTimeout(() => {
          if (location.state?.college_name) {
            navigate("/re1create", {
              state: {
                ...location.state,
                action: "Upload",
                selectedActions: location.state?.selectedActions,
              },
            });
          } else if (location.state?.digitalMarketingPlatform) {
            navigate("/re2create", {
              state: {
                ...location.state,
                action: "Upload",
                selectedActions: location.state?.selectedActions,
              },
            });
          } else if (location.state?.agency_name) {
            navigate("/re3create", {
              state: {
                ...location.state,
                action: "Upload",
                selectedActions: location.state?.selectedActions,
              },
            });
          } else {
            navigate(-1);
          }
        }, 2000);
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        if (error.response?.status === 400) {
          Swal.fire({
            text: `${error.response.data?.message}`,
            icon: "warning",
            confirmButtonText: "Ok",
          });
        } else {
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to update candidate details",
          });
        }
      });
  };

  useEffect(() => {
    if (props?.responseData?.length > 0) {
      props?.responseData?.map((candidate, index) => {
        setValue(`candidate_name.[${index}]`, candidate.name);
        setValue(`email.[${index}]`, candidate.email);
        setValue(`mobile.[${index}]`, candidate.phone);
      });
    }
  }, [props?.responseData, setValue]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div
        className="modal fade"
        id="confirmcandidate"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog justify-center">
          <div className="modal-content w-auto">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Confirm candidate details
              </h5>

              <button
                type="button"
                id="close-modal"
                className="btn-close d-none"
                data-bs-dismiss="modal"
                aria-label="Close"
              // onClick={() => {
              //   reset();
              // }}
              ></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="modal-body">
                <div className="card-inner">
                  <div className="row g-4">
                    <div className="card-inner">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Sr.no</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Mobile</th>
                              <th>Resume</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props?.responseData &&
                              props?.responseData?.length !== 0 ? (
                              props?.responseData?.map((data, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td
                                    style={{
                                      textWrap: "nowrap",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <input
                                      style={{ outline: "none" }}
                                      type="text"
                                      className="border-0 "
                                      placeholder="Enter candidate name"
                                      {...register(`candidate_name.${index}`, {
                                        required: "Candidate name is required",
                                      })}
                                    />
                                    {errors.candidate_name?.[index] && (
                                      <span className="fs-8 text-danger">
                                        {errors.candidate_name[index].message}
                                      </span>
                                    )}
                                  </td>
                                  <td style={{ textWrap: "nowrap" }}>
                                    <input
                                      style={{ outline: "none" }}
                                      type="email"
                                      className="border-0 "
                                      placeholder="Enter Email"
                                      {...register(`email.${index}`, {
                                        required: "Email is required",
                                        validate: (value) =>
                                          value === value.toLowerCase() ||
                                          "Email should only contain lowercase letters",
                                      })}
                                    />
                                    {errors.email?.[index] && (
                                      <span className="fs-8 text-danger">
                                        {errors.email[index].message}
                                      </span>
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      textWrap: "nowrap",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <input
                                      style={{ outline: "none" }}
                                      type="number"
                                      className="border-0 "
                                      placeholder="Enter mobile"
                                      {...register(`mobile.${index}`, {
                                        required: "Mobile number is required",
                                        minLength: {
                                          value: 10,
                                          message:
                                            "Number should be minimum 10 digits",
                                        },
                                        maxLength: {
                                          value: 10,
                                          message:
                                            "Number should be maximum 10 digits",
                                        },
                                        validate: {
                                          startsWithValidDigit: (value) =>
                                            ["9", "8", "7", "6"].includes(
                                              value.toString().charAt(0)
                                            ) ||
                                            "First digit should be 9, 8, 7, or 6",
                                        },
                                      })}
                                    />
                                    {errors.mobile?.[index] && (
                                      <span className="fs-8 text-danger">
                                        {errors.mobile[index].message}
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {data?.file ? (
                                      <a
                                        className="btn btn-primary"
                                        id="button-button-viewresume"
                                        href={data?.file}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <em
                                          className="icon ni ni-file-docs"
                                          style={{ color: "white" }}
                                        ></em>
                                      </a>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5">
                                  Good job! You are all caught up....
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="col-12">
                  <div className="form-group">
                    {/* <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button> */}
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-rejectResume"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ConfirmCandidateResume;
